<template>
   <div v-loading='loading'>
      <el-tabs v-model="activeName" @tab-click="handleClick" type="card" style="margin-bottom: 20px; margin-top: 30px">
        <el-tab-pane label="全部" name="60">
           <el-select v-model="pageDataAll.storageId" placeholder="采购仓库" class="el_select" clearable @change="SearchComplete">
              <el-option
                 v-for="item in storageList"
                 :key="item.id"
                 :label="item.value"
                 :value="item.id"
               >
              </el-option>
           </el-select>
           <el-select v-model="pageDataAll.supplierId" placeholder="供应商" class="el_select" clearable @change="SearchComplete">
             <el-option
               v-for="item in supplierList"
               :key="item.id"
               :label="item.value"
               :value="item.id"
             >
             </el-option>
           </el-select>
           <el-select v-model="pageDataAll.putInStatus" placeholder="入库状态" class="el_select" clearable @change="SearchComplete">
             <el-option
               v-for="item in putInStatusTypeList"
               :key="item.id"
               :label="item.value"
               :value="item.id"
              >
             </el-option>
           </el-select>
           <el-select v-model="pageDataAll.purchaseStatus" placeholder="采购状态" class="el_select" clearable @change="SearchComplete">
             <el-option
               v-for="item in purchaseStatusList"
               :key="item.id"
               :label="item.value"
               :value="item.id"
              >
             </el-option>
           </el-select>
           <el-select v-model="pageDataAll.payStatus" placeholder="付款状态" class="el_select" clearable @change="SearchComplete">
             <el-option
                v-for="item in payStatusTypeList"
                :key="item.id"
                :label="item.value"
                :value="item.id"
               >
             </el-option>
           </el-select>
           <el-input v-model="pageDataAll.keywords" clearable placeholder="请输入搜索内容" style="width: 200px;margin-right:10px" @keyup.enter.native = "SearchComplete"/>
           <el-button type="primary" icon="el-icon-search" size="small" @click="tableComplete1">查询</el-button>
            <!-- 列表区域 -->
            <el-table 
              border
              :data="purchaseOrderList"
              :header-cell-style="{background:'#EFEFEF'}" 
              style="margin-top: 30px; width: 100%"
              cell-style="background:#EAF9FF"
              default-expand-all
             >
               <el-table-column type="selection" align="center" width="60px"></el-table-column>
               <el-table-column type="expand" align="center" width="60px">
                  <template slot-scope="props">
                     <div v-for="item in props.row.purchaseProductInfoResList" :key="item.id" class="outSide">
                        <div class="FirstOne"></div>
                        <!-- 图片 -->
                        <div class="SecondOne">
                           <img :src="item.productImagePath" alt="" style="width: 100px; height: 50px">
                        </div>
                        <!-- SKU -->
                        <div class="ThirdOne">
                           <span>{{ item.commoditySku }}</span>
                        </div>
                        <!-- 采购单价 -->
                        <div class="FourthOne">
                           <span>{{ item.purchaseUnitPrice }}</span>
                        </div>
                        <!-- 采购数量 -->
                        <div class="FifthOne">
                           <span>{{ item.actualPurchaseNumber }}</span>
                        </div>
                        <!-- 贷款总计 -->
                        <div class="SixthOne">
                           <span>{{ item.productPriceTotal }}</span>
                        </div>
                        <!-- 已入库量 -->
                        <div class="SeventhOne">
                           <span>{{ item.putNumber }}</span>
                        </div>
                        <!-- 未入库量 -->
                        <div class="EighthOne" style="width: 300px">
                           <span>{{ item.toPutNumber }}</span>
                        </div>
                        <!-- 预计到货时间 -->
                        <div class="NinthOne" style="width: 300px">
                           <span>{{ item.expectArrivalTime }}</span>
                        </div>
                        <!-- 操作 -->
                        <div class="TenthOne"></div>
                     </div>
                  </template>
               </el-table-column>
               <el-table-column label="图片" align="center" width="200px"></el-table-column>
               <el-table-column label="SKU/属性" align="center" :show-overflow-tooltip="true" width="260px">
                 <template slot-scope="scope">
                    <span>采购单号：{{ scope.row.purchaseNo }}</span>
                 </template>
               </el-table-column>
               <el-table-column label="采购单价" align="center" width="260px">
                  <template slot-scope="scope">
                     <span>采购仓库：{{ scope.row.storageName }}</span>
                  </template>
               </el-table-column>
               <el-table-column label="采购数量" align="center" width="300px">
                  <template slot-scope="scope">
                     <span>供应商：{{ scope.row.supplierName }}</span>
                  </template>
               </el-table-column>
               <el-table-column label="贷款总计" align="center" width="260px">
                  <template slot-scope="scope">
                     <span>费用总计：{{ scope.row.productCostTotal }} -- 采购总额：{{ scope.row.purchaseTotal }}</span>
                  </template>
               </el-table-column>
               <el-table-column label="已入库量" align="center" width="300px" :show-overflow-tooltip="true">
                  <template slot-scope="scope">
                     <span>采购状态：{{ scope.row.statusName }} -- 入库状态：{{ scope.row.warehousingStatusName }}</span>
                  </template>
               </el-table-column>
               <el-table-column label="未入库量" align="center" width="300px">
                  <template slot-scope="scope">
                     <span>付款状态：{{ scope.row.paymentStatusName }}</span>
                  </template>
               </el-table-column>
               <el-table-column label="预计到货时间" align="center" width="300px">
                  <template slot-scope="scope">
                     <span>备注：{{ scope.row.tips }}</span>
                  </template>
               </el-table-column>
               <el-table-column label="操作" align="center">
                  <template slot-scope="scope">
                     <el-link :underline="false" type="info" @click="ShowFullDetail(scope.row)">详情<i class="el-icon-view el-icon--right"></i></el-link>
                  </template>
               </el-table-column>
            </el-table>
            <!-- 分页区域 -->
            <el-pagination 
              @size-change="handleSizeChange1" 
              @current-change="handleCurrentChange1"
              layout="total, sizes, prev, pager, next, jumper" 
              :page-sizes="[10, 30, 50, 100]" 
              :total="totalData"
              :page-size="pageDataAll.pageSize" 
              :current-page="pageDataAll.current" 
            >
           </el-pagination>
           <!-- 查看详情 -->
           <el-dialog
              title="采购单详情"
              :visible.sync="toFullOrderVisible"
              width="63%"
              :close-on-click-modal="false"
            >
            <el-row style="margin-bottom: 40px; font-size: 16px'; font-weight: bold">
               <span>采购计划编号: {{ fullPurchaseOrderObj.purchasePlanNo }}</span>
            </el-row>
            <el-row style="margin-bottom: 50px" v-show="showCreate">
               <el-steps :active="FullOrderActive" align-center finish-status="success">
                <el-step title="创建" :description="fullCreateDescription"></el-step>
                <el-step title="审核" :description="fullAuditDescription"></el-step>
                <el-step title="入库" :description="fullInStorageDescription"></el-step>
                <el-step title="完成" :description="fullCompleteDescription"></el-step>
               </el-steps>
            </el-row>
            <el-row style="margin-bottom: 50px" v-show="showInvalid">
              <el-steps :active="FullOrderActive" align-center finish-status="success"> 
                 <el-step title="创建" :description="fullCreateDescription"></el-step>
                 <el-step title="审核" :description="fullAuditDescription"></el-step>
                 <el-step title="作废" :description="fullInvalidDescription"></el-step>
              </el-steps>
            </el-row>
            <el-row style="margin-bottom: 50px" v-show="showRejected"> 
               <el-steps :active="FullOrderActive" align-center finish-status="success">
                  <el-step title="创建" :description="fullCreateDescription"></el-step>
                  <el-step title="驳回" :description="fullRejectedDescription"></el-step>
               </el-steps>
            </el-row>

            <div>
              <div>
                <el-divider content-position="center">基本信息</el-divider>
                 <el-row type="flex" justify="flex-start" style="margin-top: 30px">
                   <div class="lable_div" style="margin-right: 60px">
                     采购仓库: {{ fullPurchaseOrderObj.storageName }}
                   </div>
                   <div class="lable_div" style="margin-right: 60px">
                     供应商: {{ fullPurchaseOrderObj.supplierName }}
                   </div>
                   <div class="lable_div" style="margin-right: 60px">
                      联系人/联系电话: {{ fullPurchaseOrderObj.contactAndContactTelephone }}
                   </div>
                   <div class="lable_div">
                      结算方式: {{ fullPurchaseOrderObj.settlementTypeName }}
                   </div>
                 </el-row>

                <el-row type="flex" justify="flex-start" style="margin: 30px 0">
                  <div class="lable_div">
                    <p style="width: 65px; margin-top: 7px">运费: </p>
                    <el-input v-model="fullPurchaseOrderObj.freight" placeholder="请填写运费"></el-input>
                  </div>
                  <div class="lable_div">
                    <p style="width:80px; margin-top: 7px; margin-left: 25px">运费分摊:</p>
                    <el-select v-model="fullPurchaseOrderObj.freightShareType" placeholder="运费分摊" clearable>
                      <el-option
                        v-for="item in shareTypeList"
                        :key="item.id"
                        :label="item.value"
                        :value="item.id"
                      >
                      </el-option>
                    </el-select>
                  </div>
                  <div class="lable_div">
                    <p style="width: 115px; margin-top: 7px; margin-left: 20px;">其他费用:</p>
                    <el-input v-model="fullPurchaseOrderObj.otherFee" placeholder="请填写其他费用"></el-input>
                  </div>
                </el-row>

                <el-row type="flex" justify="flex-start" style="margin: 30px 0">
                  <div class="lable_div" style="margin-right: 100px">
                     采购总额： {{ fullPurchaseOrderObj.purchaseTotal }}
                  </div>
                  <div class="lable_div" style="margin-right: 100px">
                     贷款总计： {{ fullPurchaseOrderObj.loanTotal }}
                  </div>
                  <div class="lable_div" style="margin-right: 100px">
                     费用总计： {{ fullPurchaseOrderObj.costTotal }}
                  </div>
                </el-row>

                <el-row type="flex" justify="flex-start" style="margin: 30px 0">
                  <div class="lable_div">
                    <p style="width: 85px; margin-top: 7px;">物流: </p>
                    <el-select v-model="fullPurchaseOrderObj.logisticsId" placeholder="物流" clearable>
                      <el-option
                        v-for="item in transferTypeList"
                        :key="item.id"
                        :label="item.value"
                        :value="item.id"
                      >
                      </el-option>
                    </el-select>
                    <el-input style="margin-left: 15px" v-model="fullPurchaseOrderObj.logisticsNo" placeholder="请填写物流单号"></el-input>
                  </div>
                  <div class="lable_div">
                    <p style="width: 110px; margin-top: 7px; margin-left: 20px">预计到货时间: </p>
                     <el-date-picker
                      v-model="fullPurchaseOrderObj.expectArrivalTime"
                      type="date"
                      placeholder="请选择日期"
                      format="yyyy 年 MM 月 dd 日"
                      value-format="yyyy-MM-dd"
                      :picker-options="pickerOptions">
                     </el-date-picker>
                  </div>
               </el-row>

               <el-row type="flex" justify="flex-start">
                  <div class="lable_div">
                    <p style="width: 65px; margin-top: 7px">备注: </p>
                    <el-input v-model="fullPurchaseOrderObj.tips" placeholder="请输入信息进行备注" clearable></el-input>
                  </div>
               </el-row>
               <div>
                  <el-divider content-position="center">采购商品记录</el-divider>
               </div>
               <el-tabs v-model="activeFullTabs" @tab-click="handleClickFull">
                  <el-tab-pane label="采购商品" name="11">
                     <el-table
                       border
                       :data="FullpurchaseOrderlist"
                       :header-cell-style="{background:'#EFEFEF'}"
                       style="margin-top: 30px; width: 100%"
                      >
                        <el-table-column label="图片" align="center">
                           <template slot-scope="scope">
                              <img :src="scope.row.productImagePath" alt="" style="width: 80px; height: 60px">
                           </template>
                        </el-table-column>
                        <el-table-column label="SKU信息" prop="commoditySku" align="center" :show-overflow-tooltip="true"></el-table-column>
                        <el-table-column label="商品名称" prop="productName" align="center"></el-table-column>
                        <el-table-column label="已采购" prop="actualPurchaseNumber" align="center"></el-table-column>
                        <el-table-column label="已入库" align="center">
                           <el-table-column label="良品量" prop="goodNumber" align="center"></el-table-column>
                           <el-table-column label="次品量" prop="defectiveNumber" align="center"></el-table-column>
                        </el-table-column>
                        <el-table-column label="未入库" prop="toPutNumber" align="center"></el-table-column>
                        <el-table-column label="采购单价(￥)" prop="purchaseUnitPrice" align="center"></el-table-column>
                        <el-table-column label="单位费用(￥)" prop="unitCost" align="center"></el-table-column>
                        <el-table-column label="单位入库成本(￥)" prop="unitPutCost" align="center"></el-table-column>
                        <el-table-column label="贷款总计(￥)" prop="productPriceTotal" align="center"></el-table-column>
                        <el-table-column label="费用总计(￥)" prop="productCostTotal" align="center"></el-table-column>
                        <el-table-column label="采购总额(￥)" prop="purchaseTotal" align="center"></el-table-column>
                        <el-table-column label="关联计划编号" prop="purchasePlanNo" align="center" :show-overflow-tooltip="true"></el-table-column>
                     </el-table>
                  </el-tab-pane>
                  <el-tab-pane label="付款记录" name="12">
                     <el-table
                       border
                       :data="fullPaymentRecord"
                       :header-cell-style="{background:'#EFEFEF'}"
                       style="margin-top: 30px; width: 100%"
                      >
                        <el-table-column label="付款单号" prop="payNo" align="center"></el-table-column>
                        <el-table-column label="申请付款金额(￥)" prop="payableAmount" align="center"></el-table-column>
                        <el-table-column label="预计付款时间" prop="estimatePayDate" align="center"></el-table-column>
                        <el-table-column label="付款单状态" prop="statusName" align="center"></el-table-column>
                        <el-table-column label="付款人" prop="payBy" align="center"></el-table-column>
                        <el-table-column label="付款时间" prop="payTime" align="center"></el-table-column>
                        <el-table-column label="备注" prop="tips" align="center"></el-table-column>
                     </el-table>
                  </el-tab-pane>
                  <el-tab-pane label="退款记录" name="13">
                    <el-table
                      border
                      :data="fullRefundRecordList"
                      :header-cell-style="{background:'#EFEFEF'}"
                      style="margin-top: 30px; width: 100%"
                     >
                        <el-table-column label="收款单号" prop="collectionNo" align="center"></el-table-column>
                        <el-table-column label="申请收款金额(￥)" prop="receivableAmount" align="center"></el-table-column>
                        <el-table-column label="预计收款时间" prop="estimateCollectionDate" align="center"></el-table-column>
                        <el-table-column label="收款单状态" prop="statusName" align="center"></el-table-column>
                        <el-table-column label="收款人" prop="collectBy" align="center"></el-table-column>
                        <el-table-column label="收款时间" prop="collectTime" align="center"></el-table-column>
                        <el-table-column label="备注" prop="tips" align="center"></el-table-column>
                    </el-table>
                  </el-tab-pane>
               </el-tabs>
               <span slot="footer" class="dialog-footer-footer" style="margin-top: 30px">
                  <el-button @click="toFullOrderVisible = false">取 消</el-button>
                  <el-button type="primary" @click="toFullOrderVisible = false">保 存</el-button>
               </span>
              </div>
            </div>
           </el-dialog>
        </el-tab-pane>

        <el-tab-pane label="待提交" name="61">
           <el-select v-model="pageDataSubmit.storageId" placeholder="采购仓库" class="el_select" clearable>
              <el-option
                 v-for="item in storageList"
                 :key="item.id"
                 :label="item.value"
                 :value="item.id"
               >
              </el-option>
           </el-select>
           <el-select v-model="pageDataSubmit.supplierId" placeholder="供应商" class="el_select" clearable>
             <el-option
               v-for="item in supplierList"
               :key="item.id"
               :label="item.value"
               :value="item.id"
             ></el-option>
           </el-select>
           <el-input v-model="pageDataSubmit.keywords" clearable placeholder="请输入搜索内容" style="width: 200px;margin-right:10px"/>
           <el-button type="primary" icon="el-icon-search" size="small" @click="tableSearch2()">查询</el-button>
           <el-button type="info" icon="el-icon-plus" size="small" @click="addPurchaseOrder">创建采购单</el-button>
           <el-button type="warning" icon="el-icon-warning" size="small" @click="batchSubmitAudit">批量提交审核</el-button>
           <el-button type="danger" icon="el-icon-close" size="small" @click="batchDelete">批量删除</el-button>

           <!-- 列表区域 -->
           <el-table
             border
             :data="purchaseOrderList2"
             :header-cell-style="{background:'#EFEFEF'}"
             cell-style="background:#EAF9FF"
             style="margin-top: 30px"
             default-expand-all
             @selection-change="handleSelectionChange"
            >
             <el-table-column type="selection" align="center" width="60px"></el-table-column>
             <el-table-column type="expand" align="center" width="60px">
                <template slot-scope="props">
                  <div v-for="item in props.row.purchaseProductInfoResList" :key="item.id" class="outSide">
                     <div class="FirstOne"></div>
                     <!-- 图片 -->
                     <div class="productImagePath">
                       <img :src="item.productImagePath" alt="" style="width: 100px; height: 50px">
                     </div>
                     <!-- sku 信息 -->
                     <div class="commoditySku">
                        <span>{{ item.commoditySku }}</span>
                     </div> 
                     <!-- 采购单价 -->
                     <div class="purchaseUnitPrice">
                       <span>{{ item.purchaseUnitPrice }}</span>
                     </div> 
                     <!-- 采购数量 -->
                     <div class="planPurchaseNumber">
                       <span>{{ item.actualPurchaseNumber }}</span>
                     </div> 
                     <!-- 贷款总计 -->
                     <div class="productPriceTotal">
                       <span>{{ item.productPriceTotal }}</span>
                     </div> 
                     <!-- 预计到货时间 -->
                     <div class="expectArrivalTime">
                        <span>{{ item.expectArrivalTime }}</span>
                     </div>
                     <!-- 操作 -->
                    <div class="TenthOne"></div>
                  </div>
                </template>
             </el-table-column>
             <el-table-column label="图片" prop="" align="center" width="200px"></el-table-column>
             <el-table-column label="SKU" align="center" width="400px">
               <template slot-scope="scope">
                 <span>采购单号：{{ scope.row.purchaseNo }}</span>
               </template>
             </el-table-column>
             <el-table-column label="采购单价" align="center" width="400px" :show-overflow-tooltip="true">
               <template slot-scope="scope">
                 <span>采购仓库：{{ scope.row.storageName }}--供应商：{{ scope.row.supplierName }}</span>
               </template>
             </el-table-column>
             <el-table-column label="采购数量" prop="actualPurchaseNumber" align="center" :show-overflow-tooltip="true" width="400px">
                <template slot-scope="scope">
                  <span>费用总计：{{ scope.row.productCostTotal }}--采购总额：{{ scope.row.purchaseTotal }} </span>
                </template>
             </el-table-column>
             <el-table-column label="贷款总计" prop="productPriceTotal" align="center" :show-overflow-tooltip="true" width="300px">
                <template slot-scope="scope">
                   <span>采购员{{ scope.row.createByName }}--创建时间：{{ scope.row.createTime }}</span>
                </template>
             </el-table-column>
             <el-table-column label="预计到货时间" prop="expectArrivalTime" align="center" width="300px">
                <template slot-scope="scope">
                   <span>备注：{{scope.row.tips}}</span>
                </template>
             </el-table-column>
             <el-table-column label="操作" align="center">
                <template slot-scope="scope">
                   <el-link :underline="false" type="success" @click="submitAudit(scope.row)">提交审核</el-link>
                   <el-link :underline="false" type="primary" icon="el-icon-edit" @click="submitEdit(scope.row)" style="margin: 0 5px">编辑</el-link>
                   <el-link :underline="false" type="danger" icon="el-icon-delete" @click="submitDele(scope.row)" style="margin: 0 5px">删除</el-link>
                   <el-link :underline="false" @click="submitShowDetail(scope.row)">详情<i class="el-icon-view el-icon--right"></i></el-link>
                </template>
             </el-table-column>
           </el-table>
           <!-- 分页区域 -->
           <el-pagination 
             @size-change="handleSizeChange2" 
             @current-change="handleCurrentChange2"
             layout="total, sizes, prev, pager, next, jumper" 
             :page-sizes="[10, 30, 50, 100]" 
             :total="totalSubmit"
             :page-size="pageDataSubmit.pageSize" 
             :current-page="pageDataSubmit.current" 
           >
           </el-pagination>
           <!-- 新增采购单 -->
           <el-dialog
            title="新增采购单"
            :visible.sync="PurchaseOrderVisible"
            width="1200px"
            :before-close="handleClose"
           >
            <el-row type="flex" class="row-bg">
               <el-col :span="4">
                 采购类型： 供应商采购
               </el-col>
            </el-row>
            <el-row style="margin-top: 25px">
              <el-button type="primary" icon="el-icon-circle-plus" @click="chooseGoods">选择商品</el-button>
            </el-row>
            <!-- 列表区域 -->
            <el-table
              border
              :data="purchaseOrderData"
              :header-cell-style="{background:'#EFEFEF'}"
              style="margin-top: 30px"
             >
                <el-table-column label="SKU信息" prop="commoditySku" align="center"></el-table-column>
                <el-table-column prop="purchaseUnitPrice" align="center">
                  <template #header>
                     <span style="color: red">*</span>采购单价(￥)
                  </template>
                  <template  slot-scope="scope">
                    <el-input v-model="scope.row.purchaseUnitPrice" placeholder="采购单价" clearable></el-input>
                  </template>
                </el-table-column>
                <el-table-column align="center">
                  <template #header>
                     <span style="color: red">*</span>采购数量
                  </template>
                  <template slot-scope="scope">
                    <el-input v-model="scope.row.actualPurchaseNumber" placeholder="采购数量" clearable></el-input>
                  </template>
                </el-table-column>
                <el-table-column label="采购总价(￥)" align="center">
                   <template slot-scope="scope">
                      <span>{{scope.row.purchaseUnitPrice * scope.row.actualPurchaseNumber ? scope.row.purchaseUnitPrice * scope.row.actualPurchaseNumber : 0}}</span>
                   </template>
                </el-table-column>
                <el-table-column align="center">
                  <template slot="header">
                    <span style="color: red">*</span>采购仓库
                  </template>
                  <template slot-scope="scope">
                    <el-select 
                     v-model="scope.row.storageId" 
                     placeholder="请选择采购仓库"
                    >
                     <el-option
                       v-for="item in storageList"
                       :key="item.id"
                       :label="item.value"
                       :value="item.id"
                       @click.native="changePurchStore(scope.row, scope.$index)"
                     ></el-option>
                    </el-select>
                  </template>
                </el-table-column>
                <el-table-column align="center">
                  <template #header>
                     <span style="color: red">*</span>供应商
                  </template>
                   <template slot-scope="scope">
                     <el-select v-model="scope.row.supplierId" placeholder="供应商" clearable>
                       <el-option
                         v-for="item in supplierList"
                         :key="item.id"
                         :label="item.value"
                         :value="item.id"
                         @click.native="onClick_select(scope.row)"
                        ></el-option>
                     </el-select>
                   </template>
                </el-table-column>
                <el-table-column label="操作" prop="" align="center">
                   <template slot-scope="scope">
                      <el-link type="danger" @click="handleDelete(scope.$index)">
                        <div class="iconfont iconshanchu"></div>删除
                      </el-link>
                   </template>
                </el-table-column>
            </el-table>
            <span slot="footer" class="dialog-footer">
              <el-button @click="PurchaseOrderVisible = false">取 消</el-button>
              <el-button type="primary" @click="asyncNext">下一步</el-button>
            </span>
           </el-dialog>
           <!-- 生成采购单 -->
           <el-dialog
            title="生成采购单"
            :visible.sync="createPurchaseVisible"
            width="1200px"
            :before-close="handleClose"
           >
            <div v-for="(item, index) in createPurchaseOrderList" :key="index">
               <div>
                  <el-divider content-position="left">采购单{{index+1}}</el-divider>
               </div>
               <el-row type="flex" justify="flex-start">
                 <div class="lable_div" style="margin-right: 50px">
                   采购仓库: {{item.storageName}}
                 </div>
                 <div class="lable_div">
                   供应商: {{item.supplierName}}
                 </div>
               </el-row>

               <el-row type="flex" justify="flex-start" style="margin: 30px 0">
                 <div class="lable_div">
                   <p style="width: 65px; margin-top: 7px">运费: </p>
                   <el-input v-model="item.freight" placeholder="请填写运费"></el-input>
                 </div>
                 <div class="lable_div">
                   <p style="width:80px; margin-top: 7px; margin-left: 25px">运费分摊:</p>
                   <el-select v-model="item.freightShareType" placeholder="运费分摊" clearable>
                     <el-option
                       v-for="item in shareTypeList"
                       :key="item.id"
                       :label="item.value"
                       :value="item.id"
                     >
                     </el-option>
                   </el-select>
                 </div>
                 <div class="lable_div">
                   <p style="width: 115px; margin-top: 7px; margin-left: 20px;">其他费用:</p>
                   <el-input v-model="item.otherFee" placeholder="请填写其他费用"></el-input>
                 </div>
               </el-row>

               <el-row type="flex" justify="flex-start" style="margin: 30px 0">
                 <div class="lable_div">
                   <p style="width: 85px; margin-top: 7px;">物流: </p>
                   <el-select v-model="item.logisticsId" placeholder="物流" clearable>
                     <el-option
                       v-for="item in transferTypeList"
                       :key="item.id"
                       :label="item.value"
                       :value="item.id"
                     >
                     </el-option>
                   </el-select>
                   <el-input style="margin-left: 15px" v-model="item.logisticsNo" placeholder="请填写物流单号"></el-input>
                 </div>
                 <div class="lable_div">
                   <p style="width: 110px; margin-top: 7px; margin-left: 20px">预计到货时间: </p>
                    <el-date-picker
                     v-model="item.expectArrivalTime"
                     type="date"
                     placeholder="请选择日期"
                     format="yyyy 年 MM 月 dd 日"
                     value-format="yyyy-MM-dd"
                     :picker-options="pickerOptions">
                    </el-date-picker>
                 </div>
               </el-row>

               <el-row type="flex" justify="flex-start">
                 <div class="lable_div">
                   <p style="width: 65px; margin-top: 7px">备注: </p>
                   <el-input v-model="item.tips" placeholder="请输入信息进行备注" clearable></el-input>
                 </div>
               </el-row>

               <div>
                 <el-divider content-position="left">商品信息</el-divider>
               </div>
               <el-table
                 border
                 :data="item.purchaseProductInfoRes"
                 :header-cell-style="{background:'#EFEFEF'}"
                 style="margin-top: 30px;"
                >
                  <el-table-column label="图片" align="center">
                     <template slot-scope="scope">
                        <img :src="scope.row.productImagePath" alt="" style="width: 80px; height: 50px">
                     </template>
                  </el-table-column>
                  <el-table-column label="SKU" prop="commoditySku" align="center"></el-table-column>
                  <el-table-column label="采购量" prop="actualPurchaseNumber" align="center"></el-table-column>
                  <el-table-column label="采购单价" align="center">
                     <template slot-scope="scope">
                      <el-input v-model="scope.row.purchaseUnitPrice" placeholder="采购单价" clearable></el-input>
                     </template>
                  </el-table-column>
                  <el-table-column label="贷款总计" prop="productPriceTotal" align="center"></el-table-column>
               </el-table>
            </div>
            <span slot="footer" class="dialog-footer">
               <el-button @click="createPurchaseVisible = false">取 消</el-button>
               <el-button type="primary" @click="createPurchaseBtn">确 定</el-button>
            </span>
           </el-dialog>
           <!-- 编辑采购单 -->
           <el-dialog
            title="编辑采购单"
            :visible.sync="editPurchaseOrderVisible"
            width="62%"
            :close-on-click-modal="false"
           >
             <el-row style="margin-bottom: 40px; font-size: 16px'; font-weight: bold">
               <span>采购单编号: {{ purchaseOrderDetails.purchaseNo }}</span>
             </el-row>
             <el-row style="margin-bottom: 50px"> 
               <el-steps :active="editSubmitActive" align-center finish-status="success">
                 <el-step title="创建" :description="editSubmitDescription"></el-step>
                 <el-step title="审核" description="暂无"></el-step>
                 <el-step title="入库" description="暂无"></el-step>
                 <el-step title="完成" description="暂无"></el-step>
               </el-steps>
             </el-row>

             <div>
               <div>
                 <el-divider content-position="center">基本信息</el-divider>
                 <el-row type="flex" justify="flex-start" style="margin-top: 30px">
                   <div class="lable_div" style="margin-right: 60px">
                     采购仓库: {{ purchaseOrderDetails.storageName }}
                   </div>
                   <div class="lable_div" style="margin-right: 60px">
                     供应商: {{ purchaseOrderDetails.supplierName }}
                   </div>
                   <div class="lable_div" style="margin-right: 60px">
                      联系人/联系电话: {{ purchaseOrderDetails.contactAndContactTelephone }}
                   </div>
                   <div class="lable_div">
                      结算方式: {{ purchaseOrderDetails.settlementTypeName }}
                   </div>
                 </el-row>
                 <el-row type="flex" justify="flex-start" style="margin: 30px 0">
                   <div class="lable_div">
                     <p style="width: 65px; margin-top: 7px">运费: </p>
                     <el-input v-model="purchaseOrderDetails.freight" placeholder="请填写运费"></el-input>
                   </div>
                   <div class="lable_div">
                     <p style="width:80px; margin-top: 7px; margin-left: 25px">运费分摊:</p>
                     <el-select v-model="purchaseOrderDetails.freightShareType" placeholder="运费分摊" clearable>
                       <el-option
                         v-for="item in shareTypeList"
                         :key="item.id"
                         :label="item.value"
                         :value="item.id"
                       >
                       </el-option>
                     </el-select>
                   </div>
                   <div class="lable_div">
                     <p style="width: 115px; margin-top: 7px; margin-left: 20px;">其他费用:</p>
                     <el-input v-model="purchaseOrderDetails.otherFee" placeholder="请填写其他费用"></el-input>
                   </div>
                 </el-row>

                 <el-row type="flex" justify="flex-start" style="margin: 30px 0">
                   <div class="lable_div" style="margin-right: 100px">
                      采购总额： {{purchaseOrderDetails.purchaseTotal}}
                   </div>
                   <div class="lable_div" style="margin-right: 100px">
                      贷款总计： {{purchaseOrderDetails.loanTotal}}
                   </div>
                   <div class="lable_div" style="margin-right: 100px">
                      费用总计： {{purchaseOrderDetails.costTotal}}
                   </div>
                 </el-row>
                 <el-row type="flex" justify="flex-start" style="margin: 30px 0">
                   <div class="lable_div">
                     <p style="width: 85px; margin-top: 7px;">物流: </p>
                     <el-select v-model="purchaseOrderDetails.logisticsId" placeholder="物流" clearable>
                       <el-option
                         v-for="item in transferTypeList"
                         :key="item.id"
                         :label="item.value"
                         :value="item.id"
                       >
                       </el-option>
                     </el-select>
                     <el-input style="margin-left: 15px" v-model="purchaseOrderDetails.logisticsNo" placeholder="请填写物流单号"></el-input>
                   </div>
                   <div class="lable_div">
                     <p style="width: 110px; margin-top: 7px; margin-left: 20px">预计到货时间: </p>
                      <el-date-picker
                       v-model="purchaseOrderDetails.expectArrivalTime"
                       type="date"
                       placeholder="请选择日期"
                       format="yyyy 年 MM 月 dd 日"
                       value-format="yyyy-MM-dd"
                       :picker-options="pickerOptions">
                      </el-date-picker>
                   </div>
                 </el-row>
                 <el-row type="flex" justify="flex-start">
                   <div class="lable_div">
                     <p style="width: 65px; margin-top: 7px">备注: </p>
                     <el-input v-model="purchaseOrderDetails.tips" placeholder="请输入信息进行备注" clearable></el-input>
                   </div>
                 </el-row>
                 <div>
                   <el-divider content-position="center">采购商品记录</el-divider>
                 </div>

                 <el-button size="small" type="primary" icon="el-icon-circle-plus" class="add_purchasGoods" @click.native="add_purchas_goods">新增采购商品</el-button>
                 <el-tabs v-model="activeTabs" @tab-click="handleClickCard"> 
                   <el-tab-pane label="采购商品" name="21">
                       <el-table
                         border
                         :data="goodsDataList"
                         :header-cell-style="{background:'#EFEFEF'}"
                         style="margin-top: 30px; width: 100%"
                        >
                          <el-table-column label="图片" align="center">
                             <template slot-scope="scope">
                                <img :src="scope.row.productImagePath" alt="" style="width: 80px; height: 60px">
                             </template>
                          </el-table-column>
                          <el-table-column label="SKU信息" prop="commoditySku" align="center" :show-overflow-tooltip="true"></el-table-column>
                          <el-table-column label="商品名称" prop="productName" align="center" :show-overflow-tooltip="true"></el-table-column>
                          <el-table-column label="已采购" align="center">
                             <template slot-scope="scope">
                                <el-input v-model="scope.row.actualPurchaseNumber"></el-input>
                             </template>
                          </el-table-column>
                          <el-table-column label="已入库" align="center">
                             <el-table-column label="良品量" prop="goodNumber" align="center"></el-table-column>
                             <el-table-column label="次品量" prop="defectiveNumber" align="center"></el-table-column>
                          </el-table-column>
                          <el-table-column label="未入库" prop="toPutNumber" align="center"></el-table-column>
                          <el-table-column label="采购单价(￥)" align="center">
                             <template slot-scope="scope">
                                <el-input v-model="scope.row.purchaseUnitPrice"></el-input>
                             </template>
                          </el-table-column>
                          <el-table-column label="单位费用(￥)" prop="unitCost" align="center"></el-table-column>
                          <el-table-column label="单位入库成本(￥)" prop="unitPutCost" align="center"></el-table-column>
                          <el-table-column label="贷款总计(￥)" prop="productPriceTotal" align="center"></el-table-column>
                          <el-table-column label="费用总计(￥)" prop="productCostTotal" align="center"></el-table-column>
                          <el-table-column label="采购总额(￥)" prop="purchaseTotal" align="center"></el-table-column>
                          <el-table-column label="关联计划编号" prop="purchasePlanNo" align="center" :show-overflow-tooltip="true"></el-table-column>
                          <el-table-column label="操作" align="center">
                            <template slot-scope="scope">
                              <el-link type="danger" icon="el-icon-delete" @click="del_procureGoods(scope.row, scope.$index)">删除</el-link>
                            </template>
                          </el-table-column>
                       </el-table>
                   </el-tab-pane>
                   <el-tab-pane label="付款记录" name="22">
                       <el-table
                          border
                          :data="PurchasePayList"
                          :header-cell-style="{background:'#EFEFEF'}"
                          style="margin-top: 30px; width: 100%"
                        >
                         <el-table-column label="付款单号" prop="payNo" align="center"></el-table-column>
                         <el-table-column label="申请付款金额(￥)" prop="payableAmount" align="center"></el-table-column>
                         <el-table-column label="预计付款时间" prop="estimatePayDate" align="center"></el-table-column>
                         <el-table-column label="付款单状态" prop="statusName" align="center"></el-table-column>
                         <el-table-column label="付款人" prop="payBy" align="center"></el-table-column>
                         <el-table-column label="付款时间" prop="payTime" align="center"></el-table-column>
                         <el-table-column label="备注" prop="tips" align="center"></el-table-column>
                       </el-table>
                   </el-tab-pane>
                   <el-tab-pane label="退款记录" name="23">
                      <el-table
                        border
                        :data="PurchaseCollectList"
                        :header-cell-style="{background:'#EFEFEF'}"
                        style="margin-top: 30px; width: 100%"
                      >
                         <el-table-column label="收款单号" prop="collectionNo" align="center"></el-table-column>
                         <el-table-column label="申请收款金额(￥)" prop="receivableAmount" align="center"></el-table-column>
                         <el-table-column label="预计收款时间" prop="estimateCollectionDate" align="center"></el-table-column>
                         <el-table-column label="收款单状态" prop="statusName" align="center"></el-table-column>
                         <el-table-column label="收款人" prop="collectBy" align="center"></el-table-column>
                         <el-table-column label="收款时间" prop="collectTime" align="center"></el-table-column>
                         <el-table-column label="备注" prop="tips" align="center"></el-table-column>
                       </el-table>
                   </el-tab-pane>
                 </el-tabs>

                 <span slot="footer" class="dialog-footer-footer" style="margin-top: 30px">
                    <el-button @click="editPurchaseOrderVisible = false">取 消</el-button>
                    <el-button type="primary" @click="asyncEditPurchaseOrder">保 存</el-button>
                 </span>
               </div> 
             </div>
           </el-dialog>
           <!-- 添加商品 -->
           <el-dialog
            title="添加商品"
            width="40%"
            :visible.sync="newGoodsVisible"
           >
            <el-row class="searchStyle">
              <el-input clearable placeholder="请输入SKU搜索" v-model="pageInfoGoods.keywords" style="width: 240px; margin-right: 20px"></el-input>
              <el-button type="primary" icon="el-icon-search" @click="newGoodsSearch()">查询</el-button>
            </el-row>
            <!-- 列表区域 -->
            <el-table
              border
              height="445"
              ref="multipleTableClinic"
              :data="newGoodsLists"
              :header-cell-style="{background:'#EFEFEF'}"
              style="margin-top: 30px"
              @selection-change="handleSelectionNewGoods"
             >
               <el-table-column type="selection" align="center" :selectable="checkBoxDisable" width="60px"></el-table-column>
               <el-table-column label="图片" align="center">
                  <template slot-scope="scope">
                     <img :src="scope.row.productImagePath" alt="" style="width:80px; height: 60px">
                  </template>
               </el-table-column>
               <el-table-column label="名称" prop="commodityName" align="center" :show-overflow-tooltip="true"></el-table-column>
               <el-table-column label="SKU信息" prop="commoditySku" align="center"></el-table-column>
               <el-table-column label="采购单价(¥)" prop="purchasePrice" align="center"></el-table-column>
               <el-table-column label="良品量" prop="goodNumber" align="center">
                  <template slot-scope="scope">
                      <el-tooltip placement="top">
                        <div slot="content" v-for="item in scope.row.productStockInfoRes" :key="item.storageId" style="margin-top: 3px">
                           仓库名称：{{item.storageName}}<br/>
                           良品量：{{item.goodNumber}}<br/>
                           次品量：{{item.defectiveNumber}}<br/>
                        </div>
                        <span>{{ scope.row.goodNumber }}</span>
                      </el-tooltip>
                  </template>
               </el-table-column>
               <el-table-column label="次品量" align="center">
                  <template slot-scope="scope">
                    <el-tooltip placement="top">
                       <div slot="content" v-for="item in scope.row.productStockInfoRes" :key="item.storageId">
                          仓库名称：{{item.storageName}}<br/>
                          良品量：{{item.goodNumber}}<br/>
                          次品量：{{item.defectiveNumber}}<br/>
                       </div>
                       <span>{{ scope.row.defectiveNumber }}</span>
                    </el-tooltip>
                  </template>
               </el-table-column>
            </el-table>
            <!-- 分页区域 -->
            <el-pagination 
              @size-change="handleSizeChangeNewGoods" 
              @current-change="handleCurrentChangeNewGoods"
              layout="total, sizes, prev, pager, next, jumper" 
              :page-sizes="[10, 20, 30, 50]" 
              :total="totalNewGoods"
              :page-size="pageInfoGoods.pageSize" 
              :current-page="pageInfoGoods.current" 
            >
            </el-pagination>
            <span slot="footer" class="dialog-footer">
               <el-button @click="newGoodsVisible = false">取 消</el-button>
               <el-button type="primary" @click="addNewGoods">添 加</el-button>
            </span>
           </el-dialog>

           <!-- 新增采购商品 -->
           <el-dialog
              title="添加商品"
              width="1050px"
              :visible.sync="addGoodsVisible"
            >
              <el-row class="searchStyle">
                <el-input clearable placeholder="请输入SKU搜索" v-model="pageInfoAddGoods.keywords" style="width: 240px; margin-right: 20px"></el-input>
                <el-button type="primary" icon="el-icon-search" @click="addGoodsSearch()">查询</el-button>
              </el-row>
              <!-- 列表区域 -->
              <el-table
                border
                height="445"
                :data="addGoodsLists"
                :header-cell-style="{background:'#EFEFEF'}"
                style="margin-top: 30px"
                @selection-change="handleSelectionAddGoods"
               >
                 <el-table-column type="selection" align="center" :selectable="checkBoxDisable_t" width="60px"></el-table-column>
                 <el-table-column label="图片" align="center">
                    <template slot-scope="scope">
                       <img :src="scope.row.productImagePath" alt="" style="width:80px; height: 60px">
                    </template>
                 </el-table-column>
                 <el-table-column label="SKU信息" prop="commoditySku" align="center"></el-table-column>
                 <el-table-column label="良品量" prop="goodNumber" align="center">
                    <template slot-scope="scope">
                        <el-tooltip placement="top">
                          <div slot="content" v-for="item in scope.row.productStockInfoRes" :key="item.storageId" style="margin-top: 3px">
                             仓库名称：{{item.storageName}}<br/>
                             良品量：{{item.goodNumber}}<br/>
                             次品量：{{item.defectiveNumber}}<br/>
                          </div>
                          <span>{{ scope.row.goodNumber }}</span>
                        </el-tooltip>
                    </template>
                 </el-table-column>
                 <el-table-column label="次品量" align="center">
                    <template slot-scope="scope">
                      <el-tooltip placement="top">
                         <div slot="content" v-for="item in scope.row.productStockInfoRes" :key="item.storageId">
                            仓库名称：{{item.storageName}}<br/>
                            良品量：{{item.goodNumber}}<br/>
                            次品量：{{item.defectiveNumber}}<br/>
                         </div>
                         <span>{{ scope.row.defectiveNumber }}</span>
                      </el-tooltip>
                    </template>
                 </el-table-column>
              </el-table>
              <!-- 分页区域 -->
              <el-pagination 
                @size-change="handleSizeChangeAddGoods" 
                @current-change="handleCurrentChangeAddGoods"
                layout="total, sizes, prev, pager, next, jumper" 
                :page-sizes="[10, 20, 30, 50]" 
                :total="totalAddGoods"
                :page-size="pageInfoAddGoods.pageSize" 
                :current-page="pageInfoAddGoods.current" 
              >
              </el-pagination>
              <span slot="footer" class="dialog-footer">
                <el-button @click="addGoodsVisible = false">取 消</el-button>
                <el-button type="primary" @click="add_NewGoods">添 加</el-button>
              </span>
           </el-dialog>
           <!-- 待提交详情 -->
           <el-dialog
              width="63%"
              title="采购单详情"
              :visible.sync="toSubmitOrderVisible"
              :close-on-click-modal="false"
             >
               <el-row style="margin-bottom: 40px; font-size: 16px'; font-weight: bold">
                   <span>采购单编号: {{ submitPurchaseOrderObj.purchaseNo }}</span>
               </el-row>
               <el-row style="margin-bottom: 50px">
                   <el-steps :active="submitOrderActive" align-center finish-status="success">
                    <el-step title="创建" :description="submitOrderDescription"></el-step>
                    <el-step title="审核" description="暂无"></el-step>
                    <el-step title="入库" description="暂无"></el-step>
                    <el-step title="完成" description="暂无"></el-step>
                   </el-steps>
               </el-row>

               <div>
                 <div>
                    <el-divider content-position="center">基本信息</el-divider>
                    <el-row type="flex" justify="flex-start" style="margin-top: 30px">
                      <div class="lable_div" style="margin-right: 60px">
                        采购仓库: {{ submitPurchaseOrderObj.storageName }}
                      </div>
                      <div class="lable_div" style="margin-right: 60px">
                        供应商: {{ submitPurchaseOrderObj.supplierName }}
                      </div>
                      <div class="lable_div" style="margin-right: 60px">
                         联系人/联系电话: {{ submitPurchaseOrderObj.contactAndContactTelephone }}
                      </div>
                      <div class="lable_div">
                         结算方式: {{ submitPurchaseOrderObj.settlementTypeName }}
                      </div>
                    </el-row>

                    <el-row type="flex" justify="flex-start" style="margin: 30px 0">
                      <div class="lable_div">
                        <p style="width: 65px; margin-top: 7px">运费: </p>
                        <el-input v-model="submitPurchaseOrderObj.freight" placeholder="请填写运费"></el-input>
                      </div>
                      <div class="lable_div">
                        <p style="width:80px; margin-top: 7px; margin-left: 25px">运费分摊:</p>
                        <el-select v-model="submitPurchaseOrderObj.freightShareType" placeholder="运费分摊" clearable>
                          <el-option
                            v-for="item in shareTypeList"
                            :key="item.id"
                            :label="item.value"
                            :value="item.id"
                          >
                          </el-option>
                        </el-select>
                      </div>
                      <div class="lable_div">
                        <p style="width: 115px; margin-top: 7px; margin-left: 20px;">其他费用:</p>
                        <el-input v-model="submitPurchaseOrderObj.otherFee" placeholder="请填写其他费用"></el-input>
                      </div>
                    </el-row>

                    <el-row type="flex" justify="flex-start" style="margin: 30px 0">
                       <div class="lable_div" style="margin-right: 100px">
                          采购总额： {{ submitPurchaseOrderObj.purchaseTotal }}
                       </div>
                       <div class="lable_div" style="margin-right: 100px">
                          贷款总计： {{ submitPurchaseOrderObj.loanTotal }}
                       </div>
                       <div class="lable_div" style="margin-right: 100px">
                          费用总计： {{ submitPurchaseOrderObj.costTotal }}
                       </div>
                    </el-row>

                    <el-row type="flex" justify="flex-start" style="margin: 30px 0">
                       <div class="lable_div">
                         <p style="width: 85px; margin-top: 7px;">物流: </p>
                         <el-select v-model="submitPurchaseOrderObj.logisticsId" placeholder="物流" clearable>
                           <el-option
                             v-for="item in transferTypeList"
                             :key="item.id"
                             :label="item.value"
                             :value="item.id"
                           >
                           </el-option>
                         </el-select>
                         <el-input style="margin-left: 15px" v-model="submitPurchaseOrderObj.logisticsNo" placeholder="请填写物流单号"></el-input>
                       </div>
                       <div class="lable_div">
                         <p style="width: 110px; margin-top: 7px; margin-left: 20px">预计到货时间: </p>
                          <el-date-picker
                           v-model="submitPurchaseOrderObj.expectArrivalTime"
                           type="date"
                           placeholder="请选择日期"
                           format="yyyy 年 MM 月 dd 日"
                           value-format="yyyy-MM-dd"
                           :picker-options="pickerOptions">
                          </el-date-picker>
                       </div>
                    </el-row>

                    <el-row type="flex" justify="flex-start">
                       <div class="lable_div">
                         <p style="width: 65px; margin-top: 7px">备注: </p>
                         <el-input v-model="submitPurchaseOrderObj.tips" placeholder="请输入信息进行备注" clearable></el-input>
                       </div>
                    </el-row>
                    <div>
                       <el-divider content-position="center">采购商品记录</el-divider>
                    </div>
                    <el-tabs v-model="activeSubmitTabs" @tab-click="handleClickSubmit">
                       <el-tab-pane label="采购商品" name="101">
                          <el-table
                             border
                             :data="submitPurchaseOrderlist"
                             :header-cell-style="{background:'#EFEFEF'}"
                             style="margin-top: 30px; width: 100%"
                           >
                              <el-table-column label="图片" align="center">
                                 <template slot-scope="scope">
                                    <img :src="scope.row.productImagePath" alt="" style="width: 80px; height: 60px">  
                                 </template>
                              </el-table-column>
                              <el-table-column label="SKU信息" prop="commoditySku" align="center" :show-overflow-tooltip="true"></el-table-column>
                              <el-table-column label="商品名称" prop="productName" align="center"></el-table-column>
                              <el-table-column label="已采购" prop="actualPurchaseNumber" align="center"></el-table-column>
                              <el-table-column label="已入库" align="center">
                                 <el-table-column label="良品量" prop="goodNumber" align="center"></el-table-column>
                                 <el-table-column label="次品量" prop="defectiveNumber" align="center"></el-table-column>
                              </el-table-column>
                              <el-table-column label="未入库" prop="toPutNumber" align="center"></el-table-column>
                              <el-table-column label="采购单价(￥)" prop="purchaseUnitPrice" align="center"></el-table-column>
                              <el-table-column label="单位费用(￥)" prop="unitCost" align="center"></el-table-column>
                              <el-table-column label="单位入库成本(￥)" prop="unitPutCost" align="center"></el-table-column>
                              <el-table-column label="贷款总计(￥)" prop="productPriceTotal" align="center"></el-table-column>
                              <el-table-column label="费用总计(￥)" prop="productCostTotal" align="center"></el-table-column>
                              <el-table-column label="采购总额(￥)" prop="purchaseTotal" align="center"></el-table-column>
                              <el-table-column label="关联计划编号" prop="purchasePlanNo" align="center" :show-overflow-tooltip="true"></el-table-column>
                          </el-table>
                       </el-tab-pane>
                       <el-tab-pane label="付款记录" name="102">
                          <el-table
                            border
                            :data="submitPaymentRecord"
                            :header-cell-style="{background:'#EFEFEF'}"
                            style="margin-top: 30px; width: 100%"
                           >
                             <el-table-column label="付款单号" prop="payNo" align="center"></el-table-column>
                             <el-table-column label="申请付款金额(￥)" prop="payableAmount" align="center"></el-table-column>
                             <el-table-column label="预计付款时间" prop="estimatePayDate" align="center"></el-table-column>
                             <el-table-column label="付款单状态" prop="statusName" align="center"></el-table-column>
                             <el-table-column label="付款人" prop="payBy" align="center"></el-table-column>
                             <el-table-column label="付款时间" prop="payTime" align="center"></el-table-column>
                             <el-table-column label="备注" prop="tips" align="center"></el-table-column>
                          </el-table>
                       </el-tab-pane>
                       <el-tab-pane label="退款记录" name="103">
                          <el-table
                            border
                            :data="submitRefundRecordList"
                            :header-cell-style="{background:'#EFEFEF'}"
                            style="margin-top: 30px; width: 100%"
                           >
                              <el-table-column label="收款单号" prop="collectionNo" align="center"></el-table-column>
                              <el-table-column label="申请收款金额(￥)" prop="receivableAmount" align="center"></el-table-column>
                              <el-table-column label="预计收款时间" prop="estimateCollectionDate" align="center"></el-table-column>
                              <el-table-column label="收款单状态" prop="statusName" align="center"></el-table-column>
                              <el-table-column label="收款人" prop="collectBy" align="center"></el-table-column>
                              <el-table-column label="收款时间" prop="collectTime" align="center"></el-table-column>
                              <el-table-column label="备注" prop="tips" align="center"></el-table-column>
                          </el-table>
                       </el-tab-pane>
                    </el-tabs>
                 </div>
               </div>
           </el-dialog>
        </el-tab-pane>

        <el-tab-pane label="待审核" name="62">
            <el-select v-model="pageDataAudit.storageId" placeholder="采购仓库" class="el_select" clearable>
              <el-option
                 v-for="item in storageList"
                 :key="item.id"
                 :label="item.value"
                 :value="item.id"
               >
              </el-option>
            </el-select>
            <el-select v-model="pageDataAudit.supplierId" placeholder="供应商" class="el_select" clearable>
              <el-option
                v-for="item in supplierList"
                :key="item.id"
                :label="item.value"
                :value="item.id"
              >
              </el-option>
            </el-select>
            <el-input v-model="pageDataAudit.keywords" clearable placeholder="请输入搜索内容" style="width: 200px;margin-right:10px"/>
            <el-button type="primary" icon="el-icon-search" size="small" @click="tableAudit3">查询</el-button>
            <el-button type="primary" icon="el-icon-success" size="small" @click="batchBeApproved">批量审核通过</el-button>
            <el-button type="danger" icon="el-icon-error" size="small" @click="batchAuditDisagree">批量审核不通过</el-button>

            <!-- 列表区域 -->
            <el-table
              border
              :data="purchaseOrderList3"
              :header-cell-style="{background:'#EFEFEF'}"
              style="margin-top: 30px"
              cell-style="background:#EAF9FF"
              default-expand-all
              @selection-change="handleSelectionChangeAudit"
             >
               <el-table-column type="selection" align="center" width="60px"></el-table-column>
               <el-table-column type="expand" align="center" width="60px">
                  <template slot-scope="props">
                     <div v-for="item in props.row.purchaseProductInfoResList" :key="item.id" class="outSide">
                       <div class="FirstOne"></div>
                       <!-- 图片 -->
                       <div class="productImagePath">
                         <img :src="item.productImagePath" alt="" style="width: 100px; height: 50px">
                       </div>
                       <!-- sku 信息 -->
                       <div class="commoditySku">
                          <span>{{ item.commoditySku }}</span>
                       </div> 
                       <!-- 采购单价 -->
                       <div class="purchaseUnitPrice">
                         <span>{{ item.purchaseUnitPrice }}</span>
                       </div> 
                       <!-- 采购数量 -->
                       <div class="planPurchaseNumber">
                         <span>{{ item.actualPurchaseNumber }}</span>
                       </div> 
                       <!-- 贷款总计 -->
                       <div class="productPriceTotal">
                         <span>{{ item.productPriceTotal }}</span>
                       </div> 
                       <!-- 预计到货时间 -->
                       <div class="expectArrivalTime">
                          <span>{{ item.expectArrivalTime }}</span>
                       </div>
                       <!-- 操作 -->
                       <div class="TenthOne"></div>
                     </div>
                  </template>
               </el-table-column>
               <el-table-column label="图片" prop="" align="center" width="200px"></el-table-column>
               <el-table-column label="SKU" align="center" width="400px">
                  <template slot-scope="scope">
                     <span>采购单号：{{ scope.row.purchaseNo }}</span>
                  </template>
               </el-table-column>
               <el-table-column label="采购单价" align="center" width="400px">
                 <template slot-scope="scope">
                   <span>采购仓库：{{scope.row.storageName}}--供应商：{{scope.row.supplierName}}</span>
                 </template>
               </el-table-column>
               <el-table-column label="采购数量" prop="actualPurchaseNumber" align="center" width="400px">
                  <template slot-scope="scope">
                    <span>费用总计：{{scope.row.productCostTotal}}--采购总额：{{scope.row.purchaseTotal}} </span>
                  </template>
               </el-table-column>
               <el-table-column label="贷款总计" prop="productPriceTotal" align="center" :show-overflow-tooltip="true" width="300px">
                  <template slot-scope="scope">
                     <span>采购员{{scope.row.createByName}}--创建时间：{{scope.row.createTime}}</span>
                  </template>
               </el-table-column>
               <el-table-column label="预计到货时间" prop="expectArrivalTime" align="center" width="300px">
                  <template slot-scope="scope">
                     <span>备注：{{scope.row.tips}}</span>
                  </template>
               </el-table-column>
               <el-table-column label="操作" align="center">
                  <template slot-scope="scope">
                     <el-link :underline="false" type="success" icon="el-icon-success" @click="auditApproved(scope.row)">审核通过</el-link>
                     <el-link :underline="false" style="margin: 0 8px" icon="el-icon-error" type="danger" @click="auditDisagree(scope.row)">审核不通过</el-link>
                     <el-link :underline="false" type="info" @click="auditShowDetail(scope.row)">详 情<i class="el-icon-view el-icon--right"></i></el-link>
                  </template>
               </el-table-column>
            </el-table>
            <!-- 分页区域 -->
            <el-pagination 
               @size-change="handleSizeChange3" 
               @current-change="handleCurrentChange3"
               layout="total, sizes, prev, pager, next, jumper" 
               :page-sizes="[10, 30, 50, 100]" 
               :total="totalAudit"
               :page-size="pageDataAudit.pageSize" 
               :current-page="pageDataAudit.current" 
             >
            </el-pagination>
            <!-- 审核不通过 -->
            <el-dialog
              title="审核不通过"
              :visible.sync="failureDialogVisible"
              width="22%"
             >
              <el-form :rules="rules" ref="formRef" :model="form" label-width="105px">
                <el-form-item label="不通过原因" prop="reason">
                 <el-input type="textarea" v-model="form.reason"></el-input>
                </el-form-item>
              </el-form>
              <span slot="footer" class="dialog-footer">
                <el-button @click="failureDialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="asyncFail">确 定</el-button>
              </span>
            </el-dialog>
            <!-- 批量审核不通过 -->
            <el-dialog
              title="批量审核不通过"
              :visible.sync="batchFailureVisible"
              width="22%"
            >
              <el-form :rules="batchRules" ref="batchFormRef" :model="batchForm" label-width="105px">
                <el-form-item label="不通过原因" prop="reason">
                 <el-input type="textarea" v-model="batchForm.reason"></el-input>
                </el-form-item>
              </el-form>
              <span slot="footer" class="dialog-footer">
                <el-button @click="batchFailureVisible = false">取 消</el-button>
                <el-button type="primary" @click="asyncBatchFail">确 定</el-button>
              </span>
            </el-dialog>
            <!-- 待审核详情 -->
            <el-dialog
               width="63%"
               title="待审核详情"
               :visible.sync="toAuditOrderVisible"
               :close-on-click-modal="false"
             >
               <el-row style="margin-bottom: 40px; font-size: 16px'; font-weight: bold">
                 <span>采购单编号: {{toAuditDetailObj.purchaseNo}}</span>
               </el-row>
               <el-row style="margin-bottom: 50px"> 
                 <el-steps :active="toAuditActive" align-center finish-status="success">
                   <el-step title="创建" :description="toAuditDescription"></el-step>
                   <el-step title="审核" description="暂无"></el-step>
                   <el-step title="入库" description="暂无"></el-step>
                   <el-step title="完成" description="暂无"></el-step>
                 </el-steps>
               </el-row>

               <div>
                 <div>
                 <el-divider content-position="center">基本信息</el-divider>
                   <el-row type="flex" justify="flex-start" style="margin-top: 30px">
                     <div class="lable_div" style="margin-right: 60px">
                       采购仓库: {{ toAuditDetailObj.storageName }}
                     </div>
                     <div class="lable_div" style="margin-right: 60px">
                       供应商: {{ toAuditDetailObj.supplierName }}
                     </div>
                     <div class="lable_div" style="margin-right: 60px">
                        联系人/联系电话: {{ toAuditDetailObj.contactAndContactTelephone }}
                     </div>
                     <div class="lable_div">
                        结算方式: {{ toAuditDetailObj.settlementTypeName }}
                     </div>
                   </el-row>

                   <el-row type="flex" justify="flex-start" style="margin: 30px 0">
                      <div class="lable_div">
                        <p style="width: 65px; margin-top: 7px">运费: </p>
                        <el-input v-model="toAuditDetailObj.freight" placeholder="请填写运费"></el-input>
                      </div>
                      <div class="lable_div">
                        <p style="width:80px; margin-top: 7px; margin-left: 25px">运费分摊:</p>
                        <el-select v-model="toAuditDetailObj.freightShareType" placeholder="运费分摊" clearable>
                          <el-option
                            v-for="item in shareTypeList"
                            :key="item.id"
                            :label="item.value"
                            :value="item.id"
                          >
                          </el-option>
                        </el-select>
                      </div>
                      <div class="lable_div">
                        <p style="width: 115px; margin-top: 7px; margin-left: 20px;">其他费用:</p>
                        <el-input v-model="toAuditDetailObj.otherFee" placeholder="请填写其他费用"></el-input>
                      </div>
                   </el-row>

                  <el-row type="flex" justify="flex-start" style="margin: 30px 0">
                     <div class="lable_div" style="margin-right: 100px">
                        采购总额： {{ toAuditDetailObj.purchaseTotal }}
                     </div>
                     <div class="lable_div" style="margin-right: 100px">
                        贷款总计： {{ toAuditDetailObj.loanTotal }}
                     </div>
                     <div class="lable_div" style="margin-right: 100px">
                        费用总计： {{ toAuditDetailObj.costTotal }}
                     </div>
                  </el-row>

                  <el-row type="flex" justify="flex-start" style="margin: 30px 0">
                    <div class="lable_div">
                      <p style="width: 85px; margin-top: 7px;">物流: </p>
                      <el-select v-model="toAuditDetailObj.logisticsId" placeholder="物流" clearable>
                        <el-option
                          v-for="item in transferTypeList"
                          :key="item.id"
                          :label="item.value"
                          :value="item.id"
                        >
                        </el-option>
                      </el-select>
                      <el-input style="margin-left: 15px" v-model="toAuditDetailObj.logisticsNo" placeholder="请填写物流单号"></el-input>
                    </div>
                    <div class="lable_div">
                      <p style="width: 110px; margin-top: 7px; margin-left: 20px">预计到货时间: </p>
                       <el-date-picker
                        v-model="toAuditDetailObj.expectArrivalTime"
                        type="date"
                        placeholder="请选择日期"
                        format="yyyy 年 MM 月 dd 日"
                        value-format="yyyy-MM-dd"
                        :picker-options="pickerOptions">
                       </el-date-picker>
                    </div>
                  </el-row>

                  <el-row type="flex" justify="flex-start">
                    <div class="lable_div">
                      <p style="width: 65px; margin-top: 7px">备注: </p>
                      <el-input v-model="toAuditDetailObj.tips" placeholder="请输入信息进行备注" clearable></el-input>
                    </div>
                  </el-row>
                  <div>
                    <el-divider content-position="center">采购商品记录</el-divider>
                  </div>
                 <el-tabs v-model="activeAuditTabs" @tab-click="handleClickAudit">
                    <el-tab-pane label="采购商品" name="31">
                       <el-table
                         border
                         :data="purchaseGoodsDetail"
                         :header-cell-style="{background:'#EFEFEF'}"
                         style="margin-top: 30px; width: 100%"
                        >
                          <el-table-column label="图片" align="center">
                             <template slot-scope="scope">
                                <img :src="scope.row.productImagePath" alt="" style="width: 80px; height: 60px">
                             </template>
                          </el-table-column>
                          <el-table-column label="SKU信息" prop="commoditySku" align="center" :show-overflow-tooltip="true"></el-table-column>
                          <el-table-column label="商品名称" prop="productName" align="center" :show-overflow-tooltip="true"></el-table-column>
                          <el-table-column label="已采购" prop="actualPurchaseNumber" align="center"></el-table-column>
                          <el-table-column label="已入库" align="center">
                             <el-table-column label="良品量" prop="goodNumber" align="center"></el-table-column>
                             <el-table-column label="次品量" prop="defectiveNumber" align="center"></el-table-column>
                          </el-table-column>
                          <el-table-column label="未入库" prop="toPutNumber" align="center"></el-table-column>
                          <el-table-column label="采购单价(￥)" prop="purchaseUnitPrice" align="center"></el-table-column>
                          <el-table-column label="单位费用(￥)" prop="unitCost" align="center"></el-table-column>
                          <el-table-column label="单位入库成本(￥)" prop="unitPutCost" align="center"></el-table-column>
                          <el-table-column label="贷款总计(￥)" prop="productPriceTotal" align="center"></el-table-column>
                          <el-table-column label="费用总计(￥)" prop="productCostTotal" align="center"></el-table-column>
                          <el-table-column label="采购总额(￥)" prop="purchaseTotal" align="center"></el-table-column>
                          <el-table-column label="关联计划编号" prop="purchasePlanNo" align="center" :show-overflow-tooltip="true"></el-table-column>
                       </el-table>
                     </el-tab-pane>
                    <el-tab-pane label="付款记录" name="32">
                       <el-table
                          border
                          :data="PaymentRecordData"
                          :header-cell-style="{background:'#EFEFEF'}"
                          style="margin-top: 30px; width: 100%"
                        >
                         <el-table-column label="付款单号" prop="payNo" align="center"></el-table-column>
                         <el-table-column label="申请付款金额(￥)" prop="payableAmount" align="center"></el-table-column>
                         <el-table-column label="预计付款时间" prop="estimatePayDate" align="center"></el-table-column>
                         <el-table-column label="付款单状态" prop="statusName" align="center"></el-table-column>
                         <el-table-column label="付款人" prop="payBy" align="center"></el-table-column>
                         <el-table-column label="付款时间" prop="payTime" align="center"></el-table-column>
                         <el-table-column label="备注" prop="tips" align="center"></el-table-column>
                       </el-table>
                     </el-tab-pane>
                    <el-tab-pane label="退款记录" name="33">
                      <el-table
                        border
                        :data="CollectionRecordData"
                        :header-cell-style="{background:'#EFEFEF'}"
                        style="margin-top: 30px; width: 100%"
                      >
                        <el-table-column label="收款单号" prop="collectionNo" align="center"></el-table-column>
                        <el-table-column label="申请收款金额(￥)" prop="receivableAmount" align="center"></el-table-column>
                        <el-table-column label="预计收款时间" prop="estimateCollectionDate" align="center"></el-table-column>
                        <el-table-column label="收款单状态" prop="statusName" align="center"></el-table-column>
                        <el-table-column label="收款人" prop="collectBy" align="center"></el-table-column>
                        <el-table-column label="收款时间" prop="collectTime" align="center"></el-table-column>
                        <el-table-column label="备注" prop="tips" align="center"></el-table-column>
                       </el-table>
                    </el-tab-pane>
                 </el-tabs>
                 <span slot="footer" class="dialog-footer-footer" style="margin-top: 30px">
                    <el-button @click="toAuditOrderVisible = false">取 消</el-button>
                    <el-button type="primary" @click="toAuditOrderVisible = false">保 存</el-button>
                 </span>
                 </div> 
               </div>
            </el-dialog>
        </el-tab-pane>

        <el-tab-pane label="待到货" name="63">
          <el-select v-model="pageDataArrival.storageId" placeholder="采购仓库" class="el_select" clearable>
            <el-option
               v-for="item in storageList"
               :key="item.id"
               :label="item.value"
               :value="item.id"
             ></el-option>
          </el-select>
          <el-select v-model="pageDataArrival.supplierId" placeholder="供应商" class="el_select" clearable>
            <el-option
              v-for="item in supplierList"
              :key="item.id"
              :label="item.value"
              :value="item.id"
            ></el-option>
          </el-select>
          <el-select v-model="pageDataArrival.putInStatus" placeholder="入库状态" class="el_select" clearable>
            <el-option
              v-for="item in putInStatusTypeList"
              :key="item.id"
              :label="item.value"
              :value="item.id"
             ></el-option>
          </el-select>
          <el-select v-model="pageDataArrival.payStatus" placeholder="付款状态" class="el_select" clearable>
            <el-option
              v-for="item in payStatusTypeList"
              :key="item.id"
              :label="item.value"
              :value="item.id"
            ></el-option>
          </el-select>
          <el-input v-model="pageDataArrival.keywords" clearable placeholder="请输入搜索内容" style="width: 200px;margin-right:10px"/>
          <el-button type="primary" icon="el-icon-search" size="small" @click="tableArrival4">查询</el-button>
          <el-button type="primary" icon="el-icon-check" size="small" @click="getApplyPayment">批量申请付款</el-button>
          <el-button type="danger" icon="el-icon-error" size="small" @click="getEndPutInStorage">批量结束入库</el-button>
          <el-alert
              title="在操作采购单入库前，请确认采购单费用已填写完毕；已入库数量＞0，不允许修改采购单费用。"
              center
              type="warning"
              show-icon
              :closable="false"
              style="margin-top: 20px">
          </el-alert>
          <!-- 列表区域 -->
          <el-table
            border
            :data="purchaseOrderList4"
            :header-cell-style="{background:'#EFEFEF'}"
            style="margin-top: 30px"
            cell-style="background:#EAF9FF"
            default-expand-all
            @selection-change="handleSelectionChangeArrival"
          >
            <el-table-column type="selection" align="center" width="60px"></el-table-column>
            <el-table-column type="expand" align="center" width="60px">
                <template slot-scope="props">
                  <div v-for="item in props.row.purchaseProductInfoResList" :key="item.id" class="outSide">
                     <div class="FirstOne"></div>
                     <!-- 图片 -->
                     <div class="SecondOne">
                        <img :src="item.productImagePath" alt="" style="width: 100px; height: 50px">
                     </div>
                     <!-- SKU -->
                     <div class="ThirdOne">
                        <span>{{ item.commoditySku }}</span>
                     </div>
                     <!-- 采购单价 -->
                     <div class="FourthOne">
                        <span>{{ item.purchaseUnitPrice }}</span>
                     </div>
                     <!-- 采购数量 -->
                     <div class="FifthOne">
                        <span>{{ item.actualPurchaseNumber }}</span>
                     </div>
                     <!-- 贷款总计 -->
                     <div class="SixthOne">
                        <span>{{ item.productPriceTotal }}</span>
                     </div>
                     <!-- 已入库量 -->
                     <div class="SeventhOne">
                        

                        <el-dropdown>
                           <span>{{ item.putNumber }}<i class="el-icon-arrow-down el-icon--right"></i></span>
                           <el-dropdown-menu slot="dropdown">
                              <el-dropdown-item>未质检：{{item.notCheckQuantity}}</el-dropdown-item>
                              <el-dropdown-item>质检中: {{item.checkingQuantity}}</el-dropdown-item>
                              <el-dropdown-item>可用量: {{item.goodNumber}}</el-dropdown-item>
                              <el-dropdown-item>次品量: {{item.defectiveNumber}}</el-dropdown-item>
                           </el-dropdown-menu>
                        </el-dropdown>

                        
                     </div>
                     <!-- 未入库量 -->
                     <div class="EighthOne" style="width: 200px">
                        <el-dropdown>
                           <span>{{ item.toPutNumber }}<i class="el-icon-arrow-down el-icon--right"></i></span>
                           <el-dropdown-menu slot="dropdown">
                              <el-dropdown-item>已通知：{{item.hadNoticeNumber}}</el-dropdown-item>
                              <el-dropdown-item>未通知: {{item.toNoticeNumber}}</el-dropdown-item>
                           </el-dropdown-menu>
                        </el-dropdown>
                     </div>
                     <!-- 预计到货时间 -->
                     <div class="NinthOne" style="width: 200px">
                        <span>{{ item.expectArrivalTime }}</span>
                     </div>
                     <!-- 操作 -->
                     <div class="TenthOne"></div>
                  </div>
                </template>
            </el-table-column>
            <el-table-column label="图片" align="center" width="200px"></el-table-column>
            <el-table-column label="SKU" align="center" width="260px">
              <template slot-scope="scope">
                <span>采购单号：{{scope.row.purchaseNo}}</span>
              </template>
            </el-table-column>
            <el-table-column label="采购单价" align="center" width="260px">
              <template slot-scope="scope">
                <span>采购仓库：{{scope.row.storageName}}</span>
              </template>
            </el-table-column>
            <el-table-column label="采购数量" align="center" width="300px">
               <template slot-scope="scope">
                  <span>供应商：{{scope.row.supplierName}}</span>
               </template>
            </el-table-column>
            <el-table-column label="贷款总计" prop="productPriceTotal" align="center" width="260px" :show-overflow-tooltip="true">
              <template slot-scope="scope">
                 <span>费用总计：{{scope.row.costTotal}}--采购总额：{{scope.row.purchaseTotal}}</span>
              </template>
            </el-table-column>
            <el-table-column label="已入库量" align="center" width="300px">
               <template slot-scope="scope">
                  <span>入库状态：{{scope.row.warehousingStatusName}}</span>
               </template>
            </el-table-column>
            <el-table-column label="未入库量" align="center" width="200px">
               <template slot-scope="scope">
                  <span>付款状态：{{scope.row.paymentStatusName}}</span>
               </template>
            </el-table-column>
            <el-table-column label="预计到货时间" prop="expectArrivalTime" align="center" width="200px">
              <template slot-scope="scope">
                 <span>备注：{{scope.row.tips}}</span>
              </template>
            </el-table-column>
            <el-table-column label="操作" align="center" >
              <template slot-scope="scope">
                 <el-popover
                  placement="bottom"
                  width="100"
                  trigger="click">
                     <div style="display:flex;flex-direction:column">
                        
                     </div>
                  </el-popover>
                  <el-dropdown trigger="click" placement='bottom'>
                     <el-button type="text">操作<i class="el-icon-arrow-down"></i></el-button>
                     <el-dropdown-menu slot="dropdown">
                           <!-- <el-dropdown-item :underline="false" type="primary" @click.native="putInStorage(scope.row)">入库</el-dropdown-item> -->
                           <el-dropdown-item @click.native="purchaseCreateReceiveInit(scope.row.id)">生成收货单</el-dropdown-item>
                           <el-dropdown-item @click.native="toArrival([scope.row.id],'到货')">到货</el-dropdown-item>
                           <el-dropdown-item @click.native="toArrival([scope.row.id],'快捷入库')">快捷入库</el-dropdown-item>
                           <el-dropdown-item v-if="scope.row.warehousingStatus == 245 && scope.row.paymentStatus == 241" @click.native="asyncInvalid(scope.row)">作废</el-dropdown-item>
                           <el-dropdown-item v-if="scope.row.purchasePayDetailsRes.canApplyPayAmount > 0"  @click.native="ApplyPaymentBtn(scope.row)">申请付款</el-dropdown-item>
                           <el-dropdown-item v-if="scope.row.purchasePayDetailsRes.canApplyRefundAmount > 0" @click.native="applyRefund(scope.row)">申请退款</el-dropdown-item>
                           <el-dropdown-item @click.native="EndPutInStorage(scope.row)">结束入库</el-dropdown-item>
                           <el-dropdown-item @click.native="showArrivalDetail(scope.row)">详情</el-dropdown-item>
                           <el-dropdown-item @click.native="printContract(scope.row.id)">打印</el-dropdown-item>
                     </el-dropdown-menu>
                  </el-dropdown>
              </template> 
            </el-table-column>
          </el-table> 
          <!-- 分页区域 -->
          <el-pagination 
            @size-change="handleSizeChange4" 
            @current-change="handleCurrentChange4"
            layout="total, sizes, prev, pager, next, jumper" 
            :page-sizes="[10, 30, 50, 100]" 
            :total="totalArrival"
            :page-size="pageDataArrival.pageSize" 
            :current-page="pageDataArrival.current" 
          >
          </el-pagination>
          <!-- 入库页面 -->
          <el-dialog
             width="40%"
             title="入库"
             :visible.sync="putInStorageVisible"
           >
             <el-row style="margin-bottom: 40px; font-size: 16px'; font-weight: bold">
                <span>采购仓库: {{ InventoryDetail.storageName }}</span>
             </el-row>
             <el-table
               border
               :data="putInStorageList"
               :header-cell-style="{background:'#EFEFEF'}"
               style="margin-top: 30px; width: 100%"
             >
               <el-table-column label="采购商品" align="center">
                 <template slot-scope="scope">
                   <el-popover
                     :transition='false'
                     close-delay='0'
                     placement="right"
                     width="280"
                     trigger="hover">
                     <div style="display:flex">
                       <div class="pic">
                         <span v-if="!scope.row.productImagePath">暂无图片</span>
                         <img v-else :src="scope.row.productImagePath" alt="" width="100%">
                       </div>
                       <div class="suspend" style="width:590px;font-size:14px">
                         <p style="margin-bottom:10px">
                           <span>
                             <img style="width:24px;height:16px;margin:0 6px 0 0" :src="scope.row.productImagePath" alt=""/>
                           </span>
                         </p>
                         <p><span>SKU:{{ scope.row.commoditySku }}</span></p>
                       </div>
                     </div>
                     <div class="goodsInfo" slot="reference">
                       <div class="noPic">
                         <span v-if="!scope.row.productImagePath">暂无图片</span>
                         <img v-else :src="scope.row.productImagePath" alt="" width="100%">
                       </div>
                       <div style="text-align:left">
                         <p><img style="width:24px;height:16px;margin:0 6px 0 0" :src="scope.row.productImagePath" alt=""/>
                         <p>SKU:&nbsp;&nbsp;{{ scope.row.commoditySku }}</p>
                       </div>
                     </div>
                   </el-popover>
                 </template>
               </el-table-column>
               <el-table-column label="采购数量" prop="actualPurchaseNumber" align="center"></el-table-column>
               <el-table-column label="已入库" align="center">
                 <el-table-column label="良品" prop="goodNumber" align="center"></el-table-column>
                 <el-table-column label="次品" prop="defectiveNumber" align="center"></el-table-column>
               </el-table-column>
               <el-table-column label="本次入库" align="center">
                 <el-table-column label="良品" align="center">
                   <template slot-scope="scope">
                      <el-input type="number" v-model="scope.row.putInGoodNumber"></el-input>
                   </template>
                 </el-table-column>
                 <el-table-column label="次品" align="center">
                   <template slot-scope="scope">
                     <el-input type="number" v-model="scope.row.putInBadNumber"></el-input>
                   </template>
                 </el-table-column>
               </el-table-column>
             </el-table>
             <span slot="footer" class="dialog-footer-footer" style="margin-top: 30px">
                <el-button @click="putInStorageVisible = false">取 消</el-button>
                <el-button type="primary" @click="asyncPutInStorage">入 库</el-button>
             </span>
          </el-dialog>
          <!-- 申请付款 -->
          <el-dialog
             width="60%"
             title="申请付款"
             :visible.sync="toApplyPaymentVisible"
             :close-on-click-modal="false"
             :modal-append-to-body="false"
            >
              <el-table
                border
                :data="applyPaymentList"
                :header-cell-style="{background:'#EFEFEF'}"
                style="margin-top: 30px; width: 100%"
               >
                  <el-table-column label="采购单号" prop="purchaseNo" align="center" :show-overflow-tooltip="true"></el-table-column>
                  <el-table-column label="供应商" prop="supplierName" align="center" :show-overflow-tooltip="true"></el-table-column>
                  <el-table-column label="结算方式" prop="settlementName" align="center"></el-table-column>
                  <el-table-column label="应付总额" prop="purchaseTotal" align="center"></el-table-column>
                  <el-table-column label="已申请金额" prop="appliedPayAmount" align="center"></el-table-column>
                  <el-table-column label="可申请金额" prop="canApplyPayAmount" align="center"></el-table-column>
                  <el-table-column label="收款账户名称" align="center">
                     <template slot-scope="scope">
                       <el-select 
                        placeholder="收款账户名称" 
                        v-model="scope.row.accountName"
                       >
                       <el-option
                         v-for="item in CollectionAccounts"
                         :key="item.id"
                         :label="item.accountName"
                         :value="item.id"
                         @click.native="changeAccountName(item.id)"
                       ></el-option>
                     </el-select>
                     </template>
                  </el-table-column>
                  <el-table-column label="收款账户类型" align="center">
                     <template slot-scope="scope">
                        <!-- <span>{{scope.row.supplierAccount.accountTypeName}}</span> -->
                        <span>{{paymentAccountType}}</span>
                     </template>
                  </el-table-column>
                  <el-table-column label="收款账号" align="center">
                     <template slot-scope="scope">
                        <!-- <span>{{scope.row.supplierAccount.bankAccount}}</span> -->
                        <span>{{paymentNumber}}</span>
                     </template>
                  </el-table-column>
                  <el-table-column label="付款账户名称" align="center">
                     <template slot-scope="scope">
                        <el-select 
                          clearable
                          placeholder="付款账户名称"
                          v-model="scope.row.financeAccountName" 
                          @change="toggleAccountName"
                        >
                          <el-option
                            v-for="item in FinanceAccountList"
                            :key="item.id"
                            :label="item.accountName"
                            :value="item.id"
                          >
                          </el-option>
                        </el-select>
                     </template>
                  </el-table-column>
                  <el-table-column label="付款账号" align="center">
                     <template slot-scope="scope">
                        <span>{{accountOfPayment}}</span>
                     </template>
                  </el-table-column>
                  <el-table-column label="操作" align="center">
                     <template slot-scope="scope">
                        <el-link :underline="false" icon="el-icon-delete" type="danger" @click="PaymentOrderDele(scope.$index)">删除</el-link>
                     </template>
                  </el-table-column>
              </el-table>
              <el-row type="flex" justify="flex-start" style="margin: 30px 0">
                 <el-button type="primary" @click="SupplierInforSet">供应商信息设置</el-button>
                 <el-button type="primary" @click="addPaymentOrder">添加付款单</el-button>
              </el-row>
              <!-- 追加列表 -->
              <el-table border :data="replacementAddInitTwoReqList">
                 <el-table-column label="申请付款金额" align="center">
                    <template slot-scope="scope">
                     <el-input
                       type="number"
                       v-model.number="scope.row.payableAmount"
                       placeholder="申请付款金额"
                    />
                    </template>
                 </el-table-column>
                 <el-table-column label="预计付款时间" prop="estimatePayDate" align="center">
                    <template slot-scope="scope">
                       <el-date-picker
                         v-model="scope.row.estimatePayDate"
                         type="date"
                         placeholder="请选择日期"
                         format="yyyy 年 MM 月 dd 日"
                         value-format="yyyy-MM-dd"
                         :picker-options="pickerOptions">
                       </el-date-picker>
                    </template>
                 </el-table-column>
                 <el-table-column label="备注" align="center">
                    <template slot-scope="scope">
                       <el-input v-model="scope.row.tips" placeholder="备注" clearable></el-input>
                    </template>
                 </el-table-column>
                 <el-table-column label="操作" prop="" align="center">
                    <template slot-scope="scope">
                       <el-link :underline="false" icon="el-icon-delete" type="danger" @click="addPayOrderDele(scope.$index)">删除</el-link>
                    </template>
                 </el-table-column>
              </el-table>
              <span slot="footer" class="dialog-footer-footer" style="margin-top: 30px">
                <el-button @click="toApplyPaymentVisible = false">取 消</el-button>
                <el-button type="primary" @click="asyncSubmitAudit">提交审核</el-button>
              </span>
          </el-dialog>
          <!-- 批量申请付款 -->
          <el-dialog
             width="58%"
             title="批量申请付款"
             :visible.sync="toBatchApplyPaymentVisible"
             :close-on-click-modal="false"
            >
               <el-table
                  border
                  :data="batchApplyPaymentList"
                  :header-cell-style="{background:'#EFEFEF'}"
                  style="margin-top: 30px; width: 1400px"
                >  
                <el-table-column label="#" type="index" align="center" fixed></el-table-column>
                <el-table-column label="采购单号" prop="purchaseNo" align="center" :show-overflow-tooltip="true" fixed width="150px"></el-table-column>
                <el-table-column label="供应商" prop="supplierName" align="center" :show-overflow-tooltip="true" fixed width="150px"></el-table-column>
                <el-table-column label="结算方式" prop="settlementName" align="center" width="150px"></el-table-column>
                <el-table-column label="应付总额" prop="purchaseTotal" align="center" width="150px"></el-table-column>
                <el-table-column label="已申请金额(￥)" prop="appliedPayAmount" align="center" width="150px"></el-table-column>
                <el-table-column align="center" width="180px">
                   <template #header>
                      <span style="color: red">*</span>申请付款金额(￥)
                   </template>
                   <template slot-scope="scope">
                      <el-input type="number" v-model.number="scope.row.payableAmount" placeholder="申请付款金额"></el-input>
                   </template>
                </el-table-column>
                <el-table-column label="预计付款时间" prop="estimatePayDate" align="center" width="200px">
                  <template slot-scope="scope">
                    <el-date-picker
                       v-model="scope.row.estimatePayDate"
                       type="date"
                       placeholder="请选择日期"
                       format="yyyy 年 MM 月 dd 日"
                       value-format="yyyy-MM-dd"
                       :picker-options="pickerOptions">
                    </el-date-picker>
                  </template>
                </el-table-column>
                <el-table-column label="可申请金额(￥)" prop="canApplyPayAmount" align="center" width="150px"></el-table-column>
                <el-table-column label="" prop="accountName" align="center" width="150px">
                   <template #header>
                      <span style="color: red">*</span>收款账户名称
                   </template>
                   <template slot-scope="scope">
                     <el-select v-model="scope.row.accountName" placeholder="收款账户名称" filterable @focus="getFocuData(scope.row)">
                        <el-option
                          v-for="item in CollectionAccounts"
                          :key="item.id"
                          :label="item.accountName"
                          :value="item.accountName"
                        >
                        </el-option>
                     </el-select>
                   </template>
                </el-table-column>
                <el-table-column label="收款账户类型" align="center" width="150px">
                   <template slot-scope="scope">
                      <span>{{ scope.row.supplierAccount.accountTypeName }}</span>
                   </template>
                </el-table-column>
                <el-table-column label="收款账号" prop="bankAccount" align="center" :show-overflow-tooltip="true" width="150px">
                   <template slot-scope="scope">
                      <span>{{scope.row.supplierAccount.bankAccount}}</span>
                   </template>
                </el-table-column>
                <el-table-column align="center" width="150px">
                   <template #header>
                      <span style="color: red">*</span>付款账户名称
                   </template>
                   <template slot-scope="scope">
                     <el-select v-model="scope.row.financeAccountName" placeholder="付款账户名称" clearable>
                       <el-option
                         v-for="item in FinanceAccountList"
                         :key="item.id"
                         :label="item.accountName"
                         :value="item.id"
                       >
                       </el-option>
                     </el-select>
                   </template>
                </el-table-column>
                <el-table-column label="付款账号" prop="financeBankAccount" align="center" :show-overflow-tooltip="true" width="150px"></el-table-column>
                <el-table-column label="备注" align="center" width="150px">
                   <template slot-scope="scope">
                      <el-input v-model="scope.row.tips" placeholder="备注" clearable></el-input>
                   </template>
                </el-table-column>
                <el-table-column label="操作" align="center" fixed="right" width="150px">
                   <template slot-scope="scope">
                     <el-link :underline="false" icon="el-icon-delete" type="danger" @click="batchPaymentDelete(scope.$index)">删除</el-link>
                   </template>
                </el-table-column>
               </el-table>
              <span slot="footer" class="dialog-footer-footer" style="margin-top: 30px">
                 <el-button @click="toBatchApplyPaymentVisible = false">取 消</el-button>
                 <el-button type="primary" @click="asyncBatchSubmitAudit">提交审核</el-button>
              </span>
          </el-dialog>
          <!-- 申请退款 -->
          <el-dialog
             width="60%"
             title="申请退款"
             :visible.sync="toApplyRefundVisible"
             :close-on-click-modal="false"
            >
             <el-table
               border
               :data="applyRefundDataList"
               :header-cell-style="{background:'#EFEFEF'}"
               style="margin-top: 30px; width: 100%"
              >
                <el-table-column label="采购单号" prop="purchaseNo" align="center" :show-overflow-tooltip="true"></el-table-column>
                <el-table-column label="供应商" prop="supplierName" align="center"></el-table-column>
                <el-table-column label="结算方式" prop="settlementName" align="center"></el-table-column>
                <el-table-column label="付款账户名称" prop="accountName" align="center">
                   <template slot-scope="scope">
                      <el-select v-model="scope.row.accountName" placeholder="付款账户名称" clearable>
                        <el-option
                           v-for="item in CollectionAccounts"
                           :key="item.id"
                           :label="item.accountName"
                           :value="item.accountName"
                         >
                        </el-option>
                      </el-select>
                   </template>
                </el-table-column>
                <el-table-column label="账户类型" prop="" align="center">
                   <template slot-scope="scope">
                      <span>{{scope.row.supplierAccount.accountTypeName}}</span>
                   </template>
                </el-table-column>
                <el-table-column label="开户机构" align="center">
                   <template slot-scope="scope">
                     <span>{{scope.row.supplierAccount.bank}}</span>
                   </template>
                </el-table-column>
                <el-table-column label="付款账号" align="center">
                   <template slot-scope="scope">
                      <span>{{scope.row.supplierAccount.bankAccount}}</span>
                   </template>
                </el-table-column>
                <el-table-column label="采购已付款" prop="purchasePaidAmount" align="center"></el-table-column>
                <el-table-column label="已申请退款" prop="appliedReturnAmount" align="center"></el-table-column>
                <el-table-column label="可申请退款" prop="canApplyReturnAmount" align="center"></el-table-column>
                <el-table-column label="收款账户名称" align="center">
                   <template slot-scope="scope">
                     <el-select v-model="scope.row.financeAccountName" placeholder="收款账户名称" clearable>
                       <el-option
                          v-for="item in FinanceAccountList"
                          :key="item.id"
                          :label="item.accountName"
                          :value="item.id"
                         >
                       </el-option>
                     </el-select>
                   </template>
                </el-table-column>
                <el-table-column label="收款账号" prop="financeBankAccount" align="center"></el-table-column>
             </el-table>
             <el-row type="flex" justify="flex-start" style="margin: 30px 0">
               <el-button icon="el-icon-circle-plus" type="primary" @click="addVoucher">添加收款单</el-button>
             </el-row>
             <!-- 追加列表 -->
             <el-table border :data="CollectionBillingDataList">
               <el-table-column label="申请退款金额" prop="" align="center">
                  <template slot-scope="scope">
                     <el-input
                       type="number"
                       v-model.number="scope.row.canApplyReturnAmount"
                       placeholder="申请退款金额"
                      >
                     </el-input>
                  </template>
               </el-table-column>
               <el-table-column label="预计收款时间" prop="" align="center">
                  <template slot-scope="scope">
                     <el-date-picker
                        v-model="scope.row.estimateCollectionDate"
                        type="date"
                        placeholder="请选择日期"
                        format="yyyy 年 MM 月 dd 日"
                        value-format="yyyy-MM-dd"
                        :picker-options="pickerOptions">
                     </el-date-picker>
                  </template>
               </el-table-column>
               <el-table-column label="备注" align="center">
                  <template slot-scope="scope">
                     <el-input v-model="scope.row.tips" placeholder="备注" clearable></el-input>
                  </template>
               </el-table-column>
               <el-table-column label="操作" align="center">
                  <template slot-scope="scope">
                     <el-link :underline="false" icon="el-icon-delete" type="danger" @click="RefundDelete(scope.$index)">删除</el-link>
                  </template>
               </el-table-column>
             </el-table>
              <span slot="footer" class="dialog-footer-footer" style="margin-top: 30px">
                 <el-button @click="toApplyRefundVisible = false">取 消</el-button>
                 <el-button type="primary" @click="asyncApplyRefund">确 定</el-button>
              </span>
          </el-dialog>
          <!-- 作废弹框 -->
          <el-dialog
             width="22%"
             title="作废采购单"
             :visible.sync="invalidDialogVisible"
            >
              <el-form :rules="invalidRules" ref="invalidFormRef" :model="invalidForm" label-width="105px">
                <el-form-item label="作废原因" prop="reason">
                 <el-input type="textarea" v-model="invalidForm.reason"></el-input>
                </el-form-item>
              </el-form>
              <span slot="footer" class="dialog-footer">
                <el-button @click="invalidDialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="invalidBtn">确 定</el-button>
              </span>
          </el-dialog>
          <!-- 待到货详情 -->
          <el-dialog
              width="63%"
              title="待到货详情"
              :visible.sync="toArrivalOrderVisible"
              :close-on-click-modal="false"
             >
               <el-row style="margin-bottom: 40px; font-size: 16px'; font-weight: bold">
                 <span>采购单编号: {{toArrivalDetailObj.purchaseNo}}</span>
               </el-row>
               <el-row style="margin-bottom: 50px"> 
                 <el-steps :active="toArrivalActive" align-center finish-status="success">
                   <el-step title="创建" :description="toArrivalDescription"></el-step>
                   <el-step title="审核" :description="toArrivalCheckDescription"></el-step>
                   <el-step title="入库" :description="toArrivalInStorageDescription"></el-step>
                   <el-step title="完成" description="暂无"></el-step>
                 </el-steps>
               </el-row>

               <div>
                 <div>
                   <el-divider content-position="center">基本信息</el-divider>
                      <el-row type="flex" justify="flex-start" style="margin-top: 30px">
                        <div class="lable_div" style="margin-right: 60px">
                          采购仓库: {{ toArrivalDetailObj.storageName }}
                        </div>
                        <div class="lable_div" style="margin-right: 60px">
                          供应商: {{ toArrivalDetailObj.supplierName }}
                        </div>
                        <div class="lable_div" style="margin-right: 60px">
                           联系人/联系电话: {{ toArrivalDetailObj.contactAndContactTelephone }}
                        </div>
                        <div class="lable_div">
                           结算方式: {{ toArrivalDetailObj.settlementTypeName }}
                        </div>
                      </el-row>

                      <el-row type="flex" justify="flex-start" style="margin: 30px 0">
                         <div class="lable_div">
                           <p style="width: 65px; margin-top: 7px">运费: </p>
                           <el-input v-model="toArrivalDetailObj.freight" placeholder="请填写运费"></el-input>
                         </div>
                         <div class="lable_div">
                           <p style="width:80px; margin-top: 7px; margin-left: 25px">运费分摊:</p>
                           <el-select v-model="toArrivalDetailObj.freightShareType" placeholder="运费分摊" clearable>
                             <el-option
                               v-for="item in shareTypeList"
                               :key="item.id"
                               :label="item.value"
                               :value="item.id"
                             >
                             </el-option>
                           </el-select>
                         </div>
                         <div class="lable_div">
                           <p style="width: 115px; margin-top: 7px; margin-left: 20px;">其他费用:</p>
                           <el-input v-model="toArrivalDetailObj.otherFee" placeholder="请填写其他费用"></el-input>
                         </div>
                      </el-row>

                      <el-row type="flex" justify="flex-start" style="margin: 30px 0">
                         <div class="lable_div" style="margin-right: 100px">
                            采购总额： {{ toArrivalDetailObj.purchaseTotal }}
                         </div>
                         <div class="lable_div" style="margin-right: 100px">
                            贷款总计： {{ toArrivalDetailObj.loanTotal }}
                         </div>
                         <div class="lable_div" style="margin-right: 100px">
                            费用总计： {{ toArrivalDetailObj.costTotal }}
                         </div>
                      </el-row>

                      <el-row type="flex" justify="flex-start" style="margin: 30px 0">
                        <div class="lable_div">
                          <p style="width: 85px; margin-top: 7px;">物流: </p>
                          <el-select v-model="toArrivalDetailObj.logisticsId" placeholder="物流" clearable>
                            <el-option
                              v-for="item in transferTypeList"
                              :key="item.id"
                              :label="item.value"
                              :value="item.id"
                            >
                            </el-option>
                          </el-select>
                          <el-input style="margin-left: 15px" v-model="toArrivalDetailObj.logisticsNo" placeholder="请填写物流单号"></el-input>
                        </div>
                        <div class="lable_div">
                          <p style="width: 110px; margin-top: 7px; margin-left: 20px">预计到货时间: </p>
                           <el-date-picker
                            v-model="toArrivalDetailObj.expectArrivalTime"
                            type="date"
                            placeholder="请选择日期"
                            format="yyyy 年 MM 月 dd 日"
                            value-format="yyyy-MM-dd"
                            :picker-options="pickerOptions">
                           </el-date-picker>
                        </div>
                      </el-row>

                     <el-row type="flex" justify="flex-start">
                       <div class="lable_div">
                         <p style="width: 65px; margin-top: 7px">备注: </p>
                         <el-input v-model="toArrivalDetailObj.tips" placeholder="请输入信息进行备注" clearable></el-input>
                       </div>
                     </el-row>
                     <div>
                       <el-divider content-position="center">采购商品记录</el-divider>
                     </div>
                     <el-tabs v-model="activeArriveTabs" @tab-click="handleClickArrive">
                        <el-tab-pane label="采购商品" name="51">
                           <el-table
                             border
                             :data="purchaseGoodsArrivalData"
                             :header-cell-style="{background:'#EFEFEF'}"
                             style="margin-top: 30px; width: 100%"
                            >
                              <el-table-column label="图片" prop="" align="center" fixed="left">
                                 <template slot-scope="scope">
                                    <img :src="scope.row.productImagePath" alt="" style="width:80px; height:60px">
                                 </template>
                              </el-table-column>
                              <el-table-column label="SKU信息" prop="commoditySku" align="center" fixed="left"></el-table-column>
                              <el-table-column label="商品名称" prop="productName" align="center" fixed="left"></el-table-column>
                              <el-table-column label="已采购" prop="actualPurchaseNumber" align="center"></el-table-column>
                              <el-table-column label="已入库" align="center">
                                 <el-table-column label="良品量" prop="goodNumber" align="center"></el-table-column>
                                 <el-table-column label="次品量" prop="defectiveNumber" align="center"></el-table-column>
                              </el-table-column>
                              <el-table-column label="未入库" prop="toPutNumber" align="center"></el-table-column>
                              <el-table-column label="采购单价(￥)" prop="purchaseUnitPrice" align="center"></el-table-column>
                              <el-table-column label="单位费用(￥)" prop="unitCost" align="center"></el-table-column>
                              <el-table-column label="单位入库成本(￥)" prop="unitPutCost" align="center"></el-table-column>
                              <el-table-column label="贷款总计(￥)" prop="productPriceTotal" align="center"></el-table-column>
                              <el-table-column label="费用总计(￥)" prop="productCostTotal" align="center"></el-table-column>
                              <el-table-column label="采购总额(￥)" prop="purchaseTotal" align="center"></el-table-column>
                              <el-table-column label="关联计划编号" prop="purchasePlanNo" align="center" :show-overflow-tooltip="true"></el-table-column>
                           </el-table>
                        </el-tab-pane>

                        <el-tab-pane label="付款记录" name="52">
                           <el-table
                             border
                             :data="PaymentRecordArrivalList"
                             :header-cell-style="{background:'#EFEFEF'}"
                             style="margin-top: 30px; width: 100%"
                            >
                              <el-table-column label="付款单号" prop="payNo" align="center"></el-table-column>
                              <el-table-column label="申请付款金额(￥)" prop="payableAmount" align="center"></el-table-column>
                              <el-table-column label="预计付款时间" prop="estimatePayDate" align="center"></el-table-column>
                              <el-table-column label="付款单状态" prop="statusName" align="center"></el-table-column>
                              <el-table-column label="付款人" prop="payBy" align="center"></el-table-column>
                              <el-table-column label="付款时间" prop="payTime" align="center"></el-table-column>
                              <el-table-column label="备注" prop="tips" align="center"></el-table-column>
                           </el-table>
                        </el-tab-pane>
                        
                        <el-tab-pane label="退款记录" name="53">
                           <el-table
                             border
                             :data="RefundRecordArrivalList"
                             :header-cell-style="{background:'#EFEFEF'}"
                             style="margin-top: 30px; width: 100%"
                            >
                              <el-table-column label="收款单号" prop="collectionNo" align="center"></el-table-column>
                              <el-table-column label="申请收款金额(￥)" prop="receivableAmount" align="center"></el-table-column>
                              <el-table-column label="预计收款时间" prop="estimateCollectionDate" align="center"></el-table-column>
                              <el-table-column label="收款单状态" prop="statusName" align="center"></el-table-column>
                              <el-table-column label="收款人" prop="collectBy" align="center"></el-table-column>
                              <el-table-column label="收款时间" prop="collectTime" align="center"></el-table-column>
                              <el-table-column label="备注" prop="tips" align="center"></el-table-column>
                           </el-table>
                        </el-tab-pane>
                     </el-tabs>
                     <span slot="footer" class="dialog-footer-footer" style="margin-top: 30px">
                       <el-button @click="toArrivalOrderVisible = false">取 消</el-button>
                       <el-button type="primary" @click="PurchaseOrderSave">保 存</el-button>
                     </span>
                 </div>
               </div>
          </el-dialog>
        </el-tab-pane>

        <el-tab-pane label="已完成" name="64">
           <el-select v-model="pageDataDone.storageId" placeholder="采购仓库" class="el_select" clearable>
             <el-option
              v-for="item in storageList"
              :key="item.id"
              :label="item.value"
              :value="item.id"
             ></el-option>
           </el-select>
           <el-select v-model="pageDataDone.supplierId" placeholder="供应商" class="el_select" clearable>
             <el-option
               v-for="item in supplierList"
               :key="item.id"
               :label="item.value"
               :value="item.id"
             >
             </el-option>
           </el-select>
           <el-select v-model="pageDataDone.putInStatus" placeholder="入库状态" class="el_select" clearable>
             <el-option
               v-for="item in putInStatusTypeList"
               :key="item.id"
               :label="item.value"
               :value="item.id"
             ></el-option>
           </el-select>
           <el-select v-model="pageDataDone.payStatus" placeholder="付款状态" class="el_select" clearable>
             <el-option
                v-for="item in payStatusTypeList"
                :key="item.id"
                :label="item.value"
                :value="item.id"
             ></el-option>
           </el-select>
           <el-input v-model="pageDataDone.keywords" clearable placeholder="请输入搜索内容" style="width: 200px;margin-right:10px"/>
           <el-button type="primary" icon="el-icon-search" size="small" @click="tableDone5">查询</el-button>
           <!-- 列表区域 -->
           <el-table
             border
             :data="purchaseOrderList5"
             :header-cell-style="{background:'#EFEFEF'}"
             style="margin-top: 30px"
             cell-style="background:#EAF9FF"
             default-expand-all
            >
              <el-table-column type="selection" align="center" width="60px"></el-table-column>
              <el-table-column type="expand" align="center" width="60px">
                 <template slot-scope="props">
                   <div v-for="item in props.row.purchaseProductInfoResList" :key="item.id" class="outSide">
                     <div class="FirstOne"></div>
                     <!-- 图片 -->
                     <div class="SecondOne">
                        <img :src="item.productImagePath" alt="" style="width: 100px; height: 50px">
                     </div>
                     <!-- SKU -->
                     <div class="ThirdOne">
                        <span>{{ item.commoditySku }}</span>
                     </div>
                     <!-- 采购单价 -->
                     <div class="FourthOne">
                        <span>{{ item.purchaseUnitPrice }}</span>
                     </div>
                     <!-- 采购数量 -->
                     <div class="FifthOne">
                        <span>{{ item.actualPurchaseNumber }}</span>
                     </div>
                     <!-- 贷款总计 -->
                     <div class="SixthOne">
                        <span>{{ item.purchaseUnitPrice }}</span>
                     </div>
                     <!-- 已入库量 -->
                     <div class="SeventhOne">
                        <span>{{ item.putNumber }}</span>
                     </div>
                     <!-- 未入库量 -->
                     <div class="EighthOne" style="width: 200px">
                        <span>{{ item.toPutNumber }}</span>
                     </div>
                     <!-- 预计到货时间 -->
                     <div class="NinthOne" style="width: 200px">
                        <span>{{ item.expectArrivalTime }}</span>
                     </div>
                     <!-- 操作 -->
                     <div class="TenthOne"></div>
                   </div>
                 </template>
              </el-table-column>
              <el-table-column label="图片" prop="" align="center" width="200px"></el-table-column>
              <el-table-column label="SKU" align="center" width="260px">
                 <template slot-scope="scope">
                    <span>采购单号：{{scope.row.purchaseNo}}</span>
                 </template>
              </el-table-column>
              <el-table-column label="采购单价" align="center" width="260px">
                 <template slot-scope="scope">
                    <span>采购仓库：{{scope.row.storageName}}</span>
                 </template>
              </el-table-column>
              <el-table-column label="采购数量" align="center" width="300px">
                 <template slot-scope="scope">
                    <span>供应商：{{scope.row.supplierName}}</span>
                 </template>
              </el-table-column>
              <el-table-column label="贷款总计" prop="productPriceTotal" align="center" width="260px" :show-overflow-tooltip="true">
                  <template slot-scope="scope">
                     <span>费用总计：{{scope.row.costTotal}}--采购总额：{{scope.row.purchaseTotal}}</span>
                  </template>
              </el-table-column>
              <el-table-column label="已入库量" align="center" width="300px">
                 <template slot-scope="scope">
                    <span>入库状态：{{scope.row.warehousingStatusName}}</span>
                 </template>
              </el-table-column>
              <el-table-column label="未入库量" align="center" width="200px">
                 <template slot-scope="scope">
                    <span>付款状态：{{scope.row.paymentStatusName}}</span>
                 </template>
              </el-table-column>
              <el-table-column label="预计到货时间" prop="expectArrivalTime" align="center" width="200px">
                 <template slot-scope="scope">
                   <span>备注：{{scope.row.tips}}</span>
                 </template>
              </el-table-column>
              <el-table-column label="操作" align="center">
                 <template slot-scope="scope">
                    <el-link :underline="false" type="success" style="margin-right: 5px" @click="applyPaymentDone(scope.row)">申请付款</el-link>
                    <el-link :underline="false" type="primary" v-if="scope.row.purchasePayDetailsRes.canApplyRefundAmount > 0" @click="applyRefundDone(scope.row)">申请退款</el-link>
                    <el-link :underline="false" type="info" style="margin-left: 5px" @click="showDoneDetail(scope.row)">详情<i class="el-icon-view el-icon--right"></i></el-link>
                    <el-link :underline="false" type="primary" @click="printContract(scope.row.id)">打印</el-link>
                 </template>
              </el-table-column>
           </el-table>
           <!-- 分页区域 -->
           <el-pagination 
              @size-change="handleSizeChange5" 
              @current-change="handleCurrentChange5"
              layout="total, sizes, prev, pager, next, jumper" 
              :page-sizes="[10, 30, 50, 100]" 
              :total="totalDone"
              :page-size="pageDataDone.pageSize" 
              :current-page="pageDataDone.current" 
            >
           </el-pagination>
           <!-- 申请付款 -->
           <el-dialog
             width="60%"
             title="申请付款"
             :close-on-click-modal="false"
             :modal-append-to-body="false"
             :visible.sync="toApplyPaymentDoneVisible"
            >
              <el-table
                border
                :data="applyPaymentListDone"
                :header-cell-style="{background:'#EFEFEF'}"
                style="margin-top: 30px; width: 100%"
               >
                  <el-table-column label="采购单号" prop="purchaseNo" align="center" :show-overflow-tooltip="true"></el-table-column>
                  <el-table-column label="供应商" prop="supplierName" align="center"></el-table-column>
                  <el-table-column label="结算方式" prop="settlementName" align="center"></el-table-column>
                  <el-table-column label="应付总额" prop="purchaseTotal" align="center"></el-table-column>
                  <el-table-column label="已申请金额" prop="appliedPayAmount" align="center"></el-table-column>
                  <el-table-column label="可申请金额" prop="canApplyPayAmount" align="center"></el-table-column>
                  <el-table-column label="收款账户名称" prop="accountName" align="center">
                     <template slot-scope="scope">
                       <el-select v-model="scope.row.accountName" placeholder="收款账户名称" clearable>
                          <el-option
                           v-for="item in CollectionAccounts"
                           :key="item.id"
                           :label="item.accountName"
                           :value="item.accountName"
                          ></el-option>
                       </el-select>
                     </template>
                  </el-table-column>
                  <el-table-column label="收款账户类型" align="center">
                     <template slot-scope="scope">
                        <span>{{scope.row.supplierAccount.accountTypeName}}</span>
                     </template>
                  </el-table-column>
                  <el-table-column label="收款账号" prop="bankAccount" align="center">
                     <template slot-scope="scope">
                        <span>{{scope.row.supplierAccount.bankAccount}}</span>
                     </template>
                  </el-table-column>
                  <el-table-column label="付款账户名称" prop="financeAccountName" align="center">
                      <template slot-scope="scope">
                         <el-select v-model="scope.row.financeAccountName" placeholder="付款账户名称" clearable>
                           <el-option
                             v-for="item in FinanceAccountList"
                             :key="item.id"
                             :label="item.accountName"
                             :value="item.id"
                           >
                           </el-option>
                         </el-select>
                      </template>
                  </el-table-column>
                  <el-table-column label="付款账号" prop="financeBankAccount" align="center"></el-table-column>
                  <el-table-column label="操作" prop="" align="center">
                     <template slot-scope="scope">
                        <el-link :underline="false" icon="el-icon-delete" type="danger" @click="(scope.$index)">删除</el-link>
                     </template>
                  </el-table-column>
              </el-table>
               <el-row type="flex" justify="flex-start" style="margin: 30px 0">
                  <el-button type="primary" @click="SupplierInforDoneSet">供应商信息设置</el-button>
                  <el-button type="primary" @click="addPaymentOrderDone">添加付款单</el-button>
               </el-row>
               <!-- 追加列表 -->
               <el-table border :data="replacementAddInitTwoReqListDone">
                 <el-table-column align="center">
                    <template #header>
                       <span style="color: red">*</span>申请付款金额
                    </template>
                    <template slot-scope="scope">
                     <el-input
                       type="number"
                       v-model.number="scope.row.payableAmount"
                       placeholder="申请付款金额"
                    />
                    </template>
                 </el-table-column>
                 <el-table-column label="预计付款时间" prop="estimatePayDate" align="center">
                    <template slot-scope="scope">
                       <el-date-picker
                         v-model="scope.row.estimatePayDate"
                         type="date"
                         placeholder="请选择日期"
                         format="yyyy 年 MM 月 dd 日"
                         value-format="yyyy-MM-dd"
                         :picker-options="pickerOptions">
                       </el-date-picker>
                    </template>
                 </el-table-column>
                 <el-table-column label="备注" align="center">
                    <template slot-scope="scope">
                       <el-input v-model="scope.row.tips" placeholder="备注" clearable></el-input>
                    </template>
                 </el-table-column>
                 <el-table-column label="操作" prop="" align="center">
                    <template slot-scope="scope">
                       <el-link :underline="false" icon="el-icon-delete" type="danger" @click="addPayOrderDoneDele(scope.$index)">删除</el-link>
                    </template>
                 </el-table-column>
               </el-table>
               <span slot="footer" class="dialog-footer-footer" style="margin-top: 30px">
                  <el-button @click="toApplyPaymentDoneVisible = false">取 消</el-button>
                  <el-button type="primary" @click="asyncSubmitAuditDone">提交审核</el-button>
               </span>
           </el-dialog>
           <!-- 申请退款 -->
           <el-dialog
             title="申请退款"
             :visible.sync="toApplyRefundDoneVisible"
             width="60%"
             :close-on-click-modal="false"
            >
             <el-table
               border
               :data="applyRefundDataDoneList"
               :header-cell-style="{background:'#EFEFEF'}"
               style="margin-top: 30px; width: 100%"
              >
               <el-table-column label="采购单号" prop="purchaseNo" align="center" :show-overflow-tooltip="true"></el-table-column>
               <el-table-column label="供应商" prop="supplierName" align="center"></el-table-column>
               <el-table-column label="结算方式" prop="settlementName" align="center"></el-table-column>
               <el-table-column label="付款账户名称" prop="accountName" align="center">
                   <template slot-scope="scope">
                      <el-select v-model="scope.row.accountName" placeholder="付款账户名称" clearable>
                        <el-option
                           v-for="item in CollectionAccounts"
                           :key="item.id"
                           :label="item.accountName"
                           :value="item.accountName"
                         >
                        </el-option>
                      </el-select>
                   </template>
               </el-table-column>
               <el-table-column label="账户类型" prop="" align="center">
                    <template slot-scope="scope">
                       <span>{{scope.row.supplierAccount.accountTypeName}}</span>
                    </template>
               </el-table-column>
               <el-table-column label="开户机构" align="center">
                   <template slot-scope="scope">
                     <span>{{scope.row.supplierAccount.bank}}</span>
                   </template>
               </el-table-column>
               <el-table-column label="付款账号" align="center">
                   <template slot-scope="scope">
                      <span>{{scope.row.supplierAccount.bankAccount}}</span>
                   </template>
               </el-table-column>
               <el-table-column label="采购已付款" prop="purchasePaidAmount" align="center"></el-table-column>
               <el-table-column label="已申请退款" prop="appliedReturnAmount" align="center"></el-table-column>
               <el-table-column label="可申请退款" prop="canApplyReturnAmount" align="center"></el-table-column>
               <el-table-column label="收款账户名称" align="center">
                   <template slot-scope="scope">
                     <el-select v-model="scope.row.financeAccountName" placeholder="收款账户名称" clearable>
                       <el-option
                          v-for="item in FinanceAccountList"
                          :key="item.financeAccountId"
                          :label="item.accountName"
                          :value="item.financeAccountId"
                         >
                       </el-option>
                     </el-select>
                   </template>
               </el-table-column>
               <el-table-column label="收款账号" prop="financeBankAccount" align="center"></el-table-column>
             </el-table>
             <el-row type="flex" justify="flex-start" style="margin: 30px 0">
                <el-button type="primary" @click="addVoucherDone">添加收款单</el-button>
             </el-row>
             <!-- 追加列表 -->
             <el-table border :data="CollectionBillingDataListDone">
                <el-table-column prop="" align="center">
                  <template #header>
                     <span style="color: red">*</span>申请退款金额
                  </template>
                  <template slot-scope="scope">
                     <el-input
                       type="number"
                       v-model.number="scope.row.canApplyReturnAmount"
                       placeholder="申请退款金额"
                      >
                     </el-input>
                  </template>
                </el-table-column>
                <el-table-column label="预计收款时间" prop="" align="center">
                  <template slot-scope="scope">
                     <el-date-picker
                        v-model="scope.row.estimateCollectionDate"
                        type="date"
                        placeholder="请选择日期"
                        format="yyyy 年 MM 月 dd 日"
                        value-format="yyyy-MM-dd"
                        :picker-options="pickerOptions">
                     </el-date-picker>
                  </template>
                </el-table-column>
                <el-table-column label="备注" align="center">
                  <template slot-scope="scope">
                     <el-input v-model="scope.row.tips" placeholder="备注" clearable></el-input>
                  </template>
               </el-table-column>
               <el-table-column label="操作" align="center">
                  <template slot-scope="scope">
                     <el-link :underline="false" icon="el-icon-delete" type="danger" @click="addApplyRefundDoneDele(scope.$index)">删除</el-link>
                  </template>
               </el-table-column>
             </el-table>
              <span slot="footer" class="dialog-footer-footer" style="margin-top: 30px">
                 <el-button @click="toApplyRefundDoneVisible = false">取 消</el-button>
                 <el-button type="primary" @click="asyncApplyRefundDone">确 定</el-button>
              </span>
           </el-dialog>
           <!-- 已完成详情 -->
           <el-dialog
             width="63%"
             title="已完成详情"
             :visible.sync="toDoneOrderVisible"
             :close-on-click-modal="false"
            >
               <el-row style="margin-bottom: 40px; font-size: 16px'; font-weight: bold">
                 <span>采购单编号: {{ toDoneDetailObj.purchaseNo }}</span>
               </el-row>
               <el-row style="margin-bottom: 50px"> 
                 <el-steps :active="toDoneActive" align-center finish-status="success">
                   <el-step title="创建" :description="toCreateDescription"></el-step>
                   <el-step title="审核" :description="toReviewDescription"></el-step>
                   <el-step title="入库" :description="PutInStorageDescription"></el-step>
                   <el-step title="完成" :description="toCompleteDescription"></el-step>
                 </el-steps>
               </el-row>
               <div>
                  <div>
                    <el-divider content-position="center">基本信息</el-divider>
                      <el-row type="flex" justify="flex-start" style="margin-top: 30px">
                         <div class="lable_div" style="margin-right: 60px">
                           采购仓库: {{ toDoneDetailObj.storageName }}
                         </div>
                         <div class="lable_div" style="margin-right: 60px">
                           供应商: {{ toDoneDetailObj.supplierName }}
                         </div>
                         <div class="lable_div" style="margin-right: 60px">
                            联系人/联系电话: {{ toDoneDetailObj.contactAndContactTelephone }}
                         </div>
                         <div class="lable_div">
                            结算方式: {{ toDoneDetailObj.settlementTypeName }}
                         </div>
                      </el-row>

                      <el-row type="flex" justify="flex-start" style="margin: 30px 0">
                         <div class="lable_div">
                           <p style="width: 65px; margin-top: 7px">运费: </p>
                           <el-input v-model="toDoneDetailObj.freight" placeholder="请填写运费"></el-input>
                         </div>
                         <div class="lable_div">
                           <p style="width:80px; margin-top: 7px; margin-left: 25px">运费分摊:</p>
                           <el-select v-model="toDoneDetailObj.freightShareType" placeholder="运费分摊" clearable>
                             <el-option
                               v-for="item in shareTypeList"
                               :key="item.id"
                               :label="item.value"
                               :value="item.id"
                             >
                             </el-option>
                           </el-select>
                         </div>
                         <div class="lable_div">
                           <p style="width: 115px; margin-top: 7px; margin-left: 20px;">其他费用:</p>
                           <el-input v-model="toDoneDetailObj.otherFee" placeholder="请填写其他费用"></el-input>
                         </div>
                      </el-row>

                      <el-row type="flex" justify="flex-start" style="margin: 30px 0">
                         <div class="lable_div" style="margin-right: 100px">
                            采购总额： {{ toDoneDetailObj.purchaseTotal }}
                         </div>
                         <div class="lable_div" style="margin-right: 100px">
                            贷款总计： {{ toDoneDetailObj.loanTotal }}
                         </div>
                         <div class="lable_div" style="margin-right: 100px">
                            费用总计： {{ toDoneDetailObj.costTotal }}
                         </div>
                      </el-row>

                      <el-row type="flex" justify="flex-start" style="margin: 30px 0">
                        <div class="lable_div">
                          <p style="width: 85px; margin-top: 7px;">物流: </p>
                          <el-select v-model="toDoneDetailObj.logisticsId" placeholder="物流" clearable>
                            <el-option
                              v-for="item in transferTypeList"
                              :key="item.id"
                              :label="item.value"
                              :value="item.id"
                            >
                            </el-option>
                          </el-select>
                          <el-input style="margin-left: 15px" v-model="toDoneDetailObj.logisticsNo" placeholder="请填写物流单号"></el-input>
                        </div>
                        <div class="lable_div">
                          <p style="width: 110px; margin-top: 7px; margin-left: 20px">预计到货时间: </p>
                           <el-date-picker
                            v-model="toDoneDetailObj.expectArrivalTime"
                            type="date"
                            placeholder="请选择日期"
                            format="yyyy 年 MM 月 dd 日"
                            value-format="yyyy-MM-dd"
                            :picker-options="pickerOptions">
                           </el-date-picker>
                        </div>
                      </el-row>

                      <el-row type="flex" justify="flex-start">
                        <div class="lable_div">
                          <p style="width: 65px; margin-top: 7px">备注: </p>
                          <el-input v-model="toDoneDetailObj.tips" placeholder="请输入信息进行备注" clearable></el-input>
                        </div>
                      </el-row>
                      <div>
                        <el-divider content-position="center">采购商品记录</el-divider>
                      </div>
                      <el-tabs v-model="activeDoneTabs" @tab-click="handleClickDone">
                         <el-tab-pane label="采购商品" name="71">
                            <el-table
                              border
                              :data="purchaseGoodsDoneData"
                              :header-cell-style="{background:'#EFEFEF'}"
                              style="margin-top: 30px; width: 100%"
                             >
                              <el-table-column label="图片" align="center">
                                 <template slot-scope="scope">
                                    <img :src="scope.row.productImagePath" alt="" style="width:80px; height:60px">
                                 </template>
                              </el-table-column>
                              <el-table-column label="SKU信息" prop="commoditySku" align="center" :show-overflow-tooltip="true"></el-table-column>
                              <el-table-column label="商品名称" prop="productName" align="center"></el-table-column>
                              <el-table-column label="已采购" prop="actualPurchaseNumber" align="center"></el-table-column>
                              <el-table-column label="已入库" align="center">
                                 <el-table-column label="良品量" prop="goodNumber" align="center"></el-table-column>
                                 <el-table-column label="次品量" prop="defectiveNumber" align="center"></el-table-column>
                              </el-table-column>
                              <el-table-column label="未入库" prop="toPutNumber" align="center"></el-table-column>
                              <el-table-column label="采购单价(￥)" prop="purchaseUnitPrice" align="center"></el-table-column>
                              <el-table-column label="单位费用(￥)" prop="unitCost" align="center"></el-table-column>
                              <el-table-column label="单位入库成本(￥)" prop="unitPutCost" align="center"></el-table-column>
                              <el-table-column label="贷款总计(￥)" prop="productPriceTotal" align="center"></el-table-column>
                              <el-table-column label="费用总计(￥)" prop="productCostTotal" align="center"></el-table-column>
                              <el-table-column label="采购总额(￥)" prop="purchaseTotal" align="center"></el-table-column>
                              <el-table-column label="关联计划编号" prop="purchasePlanNo" align="center" :show-overflow-tooltip="true"></el-table-column>
                            </el-table>
                         </el-tab-pane>
                         <el-tab-pane label="付款记录" name="72">
                            <el-table
                               border
                               max-height="250"
                               :data="PaymentRecordDoneList"
                               :header-cell-style="{background:'#EFEFEF'}"
                               style="margin-top: 30px; width: 100%"
                             >
                               <el-table-column label="付款单号" prop="payNo" align="center"></el-table-column>
                               <el-table-column label="申请付款金额(￥)" prop="payableAmount" align="center"></el-table-column>
                               <el-table-column label="预计付款时间" prop="estimatePayDate" align="center"></el-table-column>
                               <el-table-column label="付款单状态" prop="statusName" align="center"></el-table-column>
                               <el-table-column label="付款人" prop="payBy" align="center"></el-table-column>
                               <el-table-column label="付款时间" prop="payTime" align="center"></el-table-column>
                               <el-table-column label="备注" prop="tips" align="center"></el-table-column>
                            </el-table>
                         </el-tab-pane>
                         <el-tab-pane label="退款记录" name="73">
                            <el-table
                               border
                               max-height="250"
                               :data="RefundRecordDoneList"
                               :header-cell-style="{background:'#EFEFEF'}"
                               style="margin-top: 30px; width: 100%"
                             >
                               <el-table-column label="收款单号" prop="collectionNo" align="center"></el-table-column>
                               <el-table-column label="申请收款金额(￥)" prop="receivableAmount" align="center"></el-table-column>
                               <el-table-column label="预计收款时间" prop="estimateCollectionDate" align="center"></el-table-column>
                               <el-table-column label="收款单状态" prop="statusName" align="center"></el-table-column>
                               <el-table-column label="收款人" prop="collectBy" align="center"></el-table-column>
                               <el-table-column label="付款时间" prop="collectTime" align="center"></el-table-column>
                               <el-table-column label="备注" prop="tips" align="center"></el-table-column>
                            </el-table>
                         </el-tab-pane>
                      </el-tabs>
                      <span slot="footer" class="dialog-footer-footer" style="margin-top: 30px">
                        <el-button @click="toDoneOrderVisible = false">取 消</el-button>
                        <el-button type="primary" @click="PurchaseOrderDoneSave">保 存</el-button>
                      </span>
                  </div>
               </div>
           </el-dialog>
        </el-tab-pane>

        <el-tab-pane label="已作废" name="65">
            <el-select v-model="pageDataInvalid.storageId" placeholder="采购仓库" class="el_select" clearable>
               <el-option
                  v-for="item in storageList"
                  :key="item.id"
                  :label="item.value"
                  :value="item.id"
                >
               </el-option>
            </el-select>
            <el-select v-model="pageDataInvalid.supplierId" placeholder="供应商" class="el_select" clearable>
              <el-option
                v-for="item in supplierList"
                :key="item.id"
                :label="item.value"
                :value="item.id"
              >
              </el-option>
            </el-select>
            <el-input v-model="pageDataInvalid.keywords" clearable placeholder="请输入搜索内容" style="width: 200px;margin-right:10px"/>
            <el-button type="primary" icon="el-icon-search" size="small" @click="tableInvalid6">查询</el-button>

            <!-- 列表区域 -->
            <el-table
              border
              :data="purchaseOrderList6"
              :header-cell-style="{background:'#EFEFEF'}"
              style="margin-top: 30px"
              cell-style="background:#EAF9FF"
              default-expand-all
             >
               <el-table-column type="selection" align="center" width="60px"></el-table-column>
               <el-table-column type="expand" align="center" width="60px">
                  <template slot-scope="props">
                     <div v-for="item in props.row.purchaseProductInfoResList" :key="item.id" class="outSide">
                        <div class="FirstOne"></div>
                        <!-- 图片 -->
                        <div class="productImagePath">
                          <img :src="item.productImagePath" alt="" style="width:100px; height: 50px">
                        </div>
                        <!-- sku 信息 -->
                        <div class="commoditySku">
                           <span>{{ item.commoditySku }}</span>
                        </div>
                        <!-- 采购单价 -->
                        <div class="purchaseUnitPrice">
                          <span>{{ item.purchaseUnitPrice }}</span>
                        </div>
                        <!-- 采购数量 -->
                        <div class="planPurchaseNumber">
                          <span>{{ item.actualPurchaseNumber }}</span>
                        </div>
                        <!-- 贷款总计 -->
                        <div class="productPriceTotal">
                           <span>{{ item.purchaseUnitPrice }}</span>
                        </div>
                        <!-- 预计到货时间 -->
                        <div class="expectArrivalTime">
                           <span>{{ item.expectArrivalTime }}</span>
                        </div>
                        <!-- 操作 -->
                        <div class="TenthOne"></div>
                     </div>
                  </template>
               </el-table-column>
               <el-table-column label="图片" prop="" align="center" width="200px"></el-table-column>
               <el-table-column label="SKU" align="center" width="400px">
                  <template slot-scope="scope">
                     <span>采购单号：{{scope.row.purchaseNo}}</span>
                  </template>
               </el-table-column>
               <el-table-column label="采购单价" align="center" width="400px">
                  <template slot-scope="scope">
                    <span>采购仓库：{{scope.row.storageName}}</span> 
                  </template>
               </el-table-column>
               <el-table-column label="采购数量" align="center" width="400px">
                  <template slot-scope="scope">
                    <span>供应商：{{scope.row.supplierName}}</span>
                  </template>
               </el-table-column>
               <el-table-column label="贷款总计" prop="productPriceTotal" align="center" width="300px">
                  <template slot-scope="scope">
                     <span>费用总计：{{scope.row.costTotal}}--采购总额：{{scope.row.purchaseTotal}}</span>
                  </template>
               </el-table-column>
               <el-table-column label="预计到货时间" prop="expectArrivalTime" align="center" width="300px">
                  <template slot-scope="scope">
                     <span>备注：{{scope.row.tips}}</span>
                  </template>
               </el-table-column>
               <el-table-column label="操作" align="center">
                  <template slot-scope="scope">
                    <el-link :underline="false" type="info" @click="showInvalidDetail(scope.row)">查看详情<i class="el-icon-view el-icon--right"></i></el-link>
                  </template>
               </el-table-column>
            </el-table>

            <!-- 分页区域 -->
            <el-pagination 
              @size-change="handleSizeChange6" 
              @current-change="handleCurrentChange6"
              layout="total, sizes, prev, pager, next, jumper" 
              :page-sizes="[10, 30, 50, 100]" 
              :total="totalInvalid"
              :page-size="pageDataInvalid.pageSize" 
              :current-page="pageDataInvalid.current" 
            >
           </el-pagination>
           <!-- 已作废详情 -->
           <el-dialog
              width="63%"
              title="已作废详情"
              :visible.sync="toInvalidOrderVisible"
              :close-on-click-modal="false"
            >
               <el-row style="margin-bottom: 40px; font-size: 16px'; font-weight: bold">
                 <span>采购单编号: {{ toInvalidDetailObj.purchaseNo }}</span>
               </el-row>
               <el-row style="margin-bottom: 50px"> 
                 <el-steps :active="toInvalidActive" align-center finish-status="success">
                   <el-step title="创建" :description="toInvalidCreateDescription"></el-step>
                   <el-step title="审核" :description="toInvalidReviewDescription"></el-step>
                   <el-step title="作废" :description="toInvalidDescription"></el-step>
                 </el-steps>
               </el-row>

               <div>
                  <div>
                    <el-divider content-position="center">基本信息</el-divider>
                    <el-row type="flex" justify="flex-start" style="margin-top: 30px">
                        <div class="lable_div" style="margin-right: 60px">
                          采购仓库: {{ toInvalidDetailObj.storageName }}
                        </div>
                        <div class="lable_div" style="margin-right: 60px">
                          供应商: {{ toInvalidDetailObj.supplierName }}
                        </div>
                        <div class="lable_div" style="margin-right: 60px">
                           联系人/联系电话: {{ toInvalidDetailObj.contactAndContactTelephone }}
                        </div>
                        <div class="lable_div">
                           结算方式: {{ toInvalidDetailObj.settlementTypeName }}
                        </div>
                    </el-row>

                    <el-row type="flex" justify="flex-start" style="margin: 30px 0">
                       <div class="lable_div">
                         <p style="width: 65px; margin-top: 7px">运费: </p>
                         <el-input v-model="toInvalidDetailObj.freight" placeholder="请填写运费"></el-input>
                       </div>
                       <div class="lable_div">
                         <p style="width:80px; margin-top: 7px; margin-left: 25px">运费分摊:</p>
                         <el-select v-model="toInvalidDetailObj.freightShareType" placeholder="运费分摊" clearable>
                           <el-option
                             v-for="item in shareTypeList"
                             :key="item.id"
                             :label="item.value"
                             :value="item.id"
                           >
                           </el-option>
                         </el-select>
                       </div>
                       <div class="lable_div">
                         <p style="width: 115px; margin-top: 7px; margin-left: 20px;">其他费用:</p>
                         <el-input v-model="toInvalidDetailObj.otherFee" placeholder="请填写其他费用"></el-input>
                       </div>
                     </el-row>

                     <el-row type="flex" justify="flex-start" style="margin: 30px 0">
                        <div class="lable_div" style="margin-right: 100px">
                           采购总额： {{ toInvalidDetailObj.purchaseTotal }}
                        </div>
                        <div class="lable_div" style="margin-right: 100px">
                           贷款总计： {{ toInvalidDetailObj.loanTotal }}
                        </div>
                        <div class="lable_div" style="margin-right: 100px">
                           费用总计： {{ toInvalidDetailObj.costTotal }}
                        </div>
                     </el-row>

                     <el-row type="flex" justify="flex-start" style="margin: 30px 0">
                        <div class="lable_div">
                          <p style="width: 85px; margin-top: 7px;">物流: </p>
                          <el-select v-model="toInvalidDetailObj.logisticsId" placeholder="物流" clearable>
                            <el-option
                              v-for="item in transferTypeList"
                              :key="item.id"
                              :label="item.value"
                              :value="item.id"
                            >
                            </el-option>
                          </el-select>
                          <el-input style="margin-left: 15px" v-model="toInvalidDetailObj.logisticsNo" placeholder="请填写物流单号"></el-input>
                        </div>
                        <div class="lable_div">
                          <p style="width: 110px; margin-top: 7px; margin-left: 20px">预计到货时间: </p>
                           <el-date-picker
                            v-model="toInvalidDetailObj.expectArrivalTime"
                            type="date"
                            placeholder="请选择日期"
                            format="yyyy 年 MM 月 dd 日"
                            value-format="yyyy-MM-dd"
                            :picker-options="pickerOptions">
                           </el-date-picker>
                        </div>
                     </el-row>

                     <el-row type="flex" justify="flex-start">
                        <div class="lable_div">
                          <p style="width: 65px; margin-top: 7px">备注: </p>
                          <el-input v-model="toInvalidDetailObj.tips" placeholder="请输入信息进行备注" clearable></el-input>
                        </div>
                      </el-row>

                      <div>
                        <el-divider content-position="center">采购商品记录</el-divider>
                      </div>
                      <el-tabs v-model="activeInvalidTabs" @tab-click="handleClickInvalid">
                        <el-tab-pane label="采购商品" name="81">
                            <el-table
                              border
                              :data="purchaseGoodsInvalidData"
                              :header-cell-style="{background:'#EFEFEF'}"
                              style="margin-top: 30px; width: 100%"
                             >
                               <el-table-column label="图片" align="center">
                                  <template slot-scope="scope">
                                     <img :src="scope.row.productImagePath" alt="" style="width: 80px; height: 60px">
                                  </template>
                               </el-table-column>
                               <el-table-column label="SKU信息" prop="commoditySku" align="center" :show-overflow-tooltip="true"></el-table-column>
                               <el-table-column label="商品名称" prop="productName" align="center"></el-table-column>
                               <el-table-column label="已采购" prop="actualPurchaseNumber" align="center"></el-table-column>
                               <el-table-column label="已入库" align="center">
                                  <el-table-column label="良品量" prop="goodNumber" align="center"></el-table-column>
                                  <el-table-column label="次品量" prop="defectiveNumber" align="center"></el-table-column>
                               </el-table-column>
                               <el-table-column label="未入库" prop="toPutNumber" align="center"></el-table-column>
                               <el-table-column label="采购单价(￥)" prop="purchaseUnitPrice" align="center"></el-table-column>
                               <el-table-column label="单位费用(￥)" prop="unitCost" align="center"></el-table-column>
                               <el-table-column label="单位入库成本(￥)" prop="unitPutCost" align="center"></el-table-column>
                               <el-table-column label="贷款总计(￥)" prop="productPriceTotal" align="center"></el-table-column>
                               <el-table-column label="费用总计(￥)" prop="productCostTotal" align="center"></el-table-column>
                               <el-table-column label="采购总额(￥)" prop="purchaseTotal" align="center"></el-table-column>
                               <el-table-column label="关联计划编号" prop="purchasePlanNo" align="center" :show-overflow-tooltip="true"></el-table-column>
                            </el-table>
                        </el-tab-pane>
                        <el-tab-pane label="付款记录" name="82">
                           <el-table
                              border
                              :data="PaymentRecordInvalidList"
                              :header-cell-style="{background:'#EFEFEF'}"
                              style="margin-top: 30px; width: 100%"
                            >
                               <el-table-column label="付款单号" prop="payNo" align="center"></el-table-column>
                               <el-table-column label="申请付款金额(￥)" prop="payableAmount" align="center"></el-table-column>
                               <el-table-column label="预计付款时间" prop="estimatePayDate" align="center"></el-table-column>
                               <el-table-column label="付款单状态" prop="statusName" align="center"></el-table-column>
                               <el-table-column label="付款人" prop="payBy" align="center"></el-table-column>
                               <el-table-column label="付款时间" prop="payTime" align="center"></el-table-column>
                               <el-table-column label="备注" prop="tips" align="center"></el-table-column>
                           </el-table>
                        </el-tab-pane>
                        <el-tab-pane label="退款记录" name="83">
                            <el-table
                               border
                               :data="RefundRecordInvalidList"
                               :header-cell-style="{background:'#EFEFEF'}"
                               style="margin-top: 30px; width: 100%"
                             >
                               <el-table-column label="收款单号" prop="collectionNo" align="center"></el-table-column>
                               <el-table-column label="申请收款金额(￥)" prop="receivableAmount" align="center"></el-table-column>
                               <el-table-column label="预计收款时间" prop="estimateCollectionDate" align="center"></el-table-column>
                               <el-table-column label="收款单状态" prop="statusName" align="center"></el-table-column>
                               <el-table-column label="收款人" prop="collectBy" align="center"></el-table-column>
                               <el-table-column label="收款时间" prop="collectTime" align="center"></el-table-column>
                               <el-table-column label="备注" prop="tips" align="center"></el-table-column>
                            </el-table>
                        </el-tab-pane>
                      </el-tabs>
                  </div>
               </div>
           </el-dialog>
        </el-tab-pane>

        <el-tab-pane label="已驳回" name="66">
         <el-select v-model="pageDataRejected.storageId" placeholder="采购仓库" class="el_select" clearable>
               <el-option
                  v-for="item in storageList"
                  :key="item.id"
                  :label="item.value"
                  :value="item.id"
                >
               </el-option>
         </el-select>
         <el-select v-model="pageDataRejected.supplierId" placeholder="供应商" class="el_select" clearable>
              <el-option
                v-for="item in supplierList"
                :key="item.id"
                :label="item.value"
                :value="item.id"
              >
              </el-option>
         </el-select>
         <el-input v-model="pageDataRejected.keywords" clearable placeholder="请输入搜索内容" style="width: 200px;margin-right:10px"/>
         <el-button type="primary" icon="el-icon-search" size="small" @click="tableRejected7">查询</el-button>  
         <!-- 列表区域 -->
         <el-table
              border
              :data="purchaseOrderList7"
              :header-cell-style="{background:'#EFEFEF'}"
              style="margin-top: 30px"
              cell-style="background:#EAF9FF"
              default-expand-all
             >
               <el-table-column type="selection" align="center" width="60px"></el-table-column>
               <el-table-column type="expand" align="center" width="60px">
                  <template slot-scope="props">
                     <div v-for="item in props.row.purchaseProductInfoResList" :key="item.id" class="outSide">
                        <div class="FirstOne"></div>
                        <!-- 图片 -->
                        <div class="productImagePath">
                          <img :src="item.productImagePath" alt="" style="width: 100px; height: 50px">
                        </div>
                        <!-- sku 信息 -->
                        <div class="commoditySku">
                           <span>{{ item.commoditySku }}</span>
                        </div>
                        <!-- 采购单价 -->
                        <div class="purchaseUnitPrice">
                          <span>{{ item.purchaseUnitPrice }}</span>
                        </div> 
                        <!-- 采购数量 -->
                        <div class="planPurchaseNumber">
                          <span>{{ item.actualPurchaseNumber }}</span>
                        </div> 
                        <!-- 贷款总计 -->
                        <div class="productPriceTotal">
                          <span>{{ item.productPriceTotal }}</span>
                        </div>
                        <!-- 预计到货时间 -->
                        <div class="expectArrivalTime">
                           <span>{{ item.expectArrivalTime }}</span>
                        </div>
                        <!-- 操作 -->
                        <div class="TenthOne"></div>
                     </div>
                  </template>
               </el-table-column>
               <el-table-column label="图片" prop="" align="center" width="200px"></el-table-column>
               <el-table-column label="SKU" align="center" width="400px">
                  <template slot-scope="scope">
                     <span>采购单号：{{ scope.row.purchaseNo }}</span>
                  </template>
               </el-table-column>
               <el-table-column label="采购单价" align="center" width="400px">
                  <template slot-scope="scope">
                    <span>采购仓库：{{ scope.row.storageName }}</span> 
                  </template>
               </el-table-column>
               <el-table-column label="采购数量" align="center" width="400px">
                  <template slot-scope="scope">
                    <span>供应商：{{ scope.row.supplierName }}</span>
                  </template>
               </el-table-column>
               <el-table-column label="贷款总计" prop="productPriceTotal" align="center" :show-overflow-tooltip="true" width="300px">
                  <template slot-scope="scope">
                     <span>费用总计：{{scope.row.costTotal}}--采购总额：{{scope.row.purchaseTotal}}</span>
                  </template>
               </el-table-column>
               <el-table-column label="预计到货时间" prop="expectArrivalTime" align="center" width="300px">
                  <template slot-scope="scope">
                     <span>备注：{{scope.row.tips}}</span>
                  </template>
               </el-table-column>
               <el-table-column label="操作" align="center">
                  <template slot-scope="scope">
                    <el-link :underline="false" type="info" @click="showRejectedDetail(scope.row)">查看详情<i class="el-icon-view el-icon--right"></i></el-link>
                  </template>
               </el-table-column>
         </el-table>
         <!-- 分页区域 -->
         <el-pagination 
              @size-change="handleSizeChange7" 
              @current-change="handleCurrentChange7"
              layout="total, sizes, prev, pager, next, jumper" 
              :page-sizes="[10, 30, 50, 100]" 
              :total="totalRejected"
              :page-size="pageDataRejected.pageSize" 
              :current-page="pageDataRejected.current" 
             >
         </el-pagination>
         <!-- 已驳回详情 -->
         <el-dialog
               width="63%"
               title="已驳回详情"
               :visible.sync="toRejectedOrderVisible"
               :close-on-click-modal="false"
             >
               <el-row style="margin-bottom: 40px; font-size: 16px'; font-weight: bold">
                 <span>采购单编号: {{ toRejectedDetailObj.purchaseNo }}</span>
               </el-row>
               <el-row style="margin-bottom: 50px"> 
                 <el-steps :active="toRejectedActive" align-center finish-status="success">
                   <el-step title="创建" :description="creatRejectedDescription"></el-step>
                   <el-step title="驳回" :description="RejectedDescription"></el-step>
                 </el-steps>
               </el-row>

               <div>
                  <div>
                    <el-divider content-position="center">基本信息</el-divider>
                    <el-row type="flex" justify="flex-start" style="margin-top: 30px">
                       <div class="lable_div" style="margin-right: 60px">
                         采购仓库: {{ toRejectedDetailObj.storageName }}
                       </div>
                       <div class="lable_div" style="margin-right: 60px">
                         供应商: {{ toRejectedDetailObj.supplierName }}
                       </div>
                       <div class="lable_div" style="margin-right: 60px">
                          联系人/联系电话: {{ toRejectedDetailObj.contactAndContactTelephone }}
                       </div>
                       <div class="lable_div">
                          结算方式: {{ toRejectedDetailObj.settlementTypeName }}
                       </div>
                    </el-row>

                    <el-row type="flex" justify="flex-start" style="margin: 30px 0">
                      <div class="lable_div">
                        <p style="width: 65px; margin-top: 7px">运费: </p>
                        <el-input v-model="toRejectedDetailObj.freight" placeholder="请填写运费"></el-input>
                      </div>
                      <div class="lable_div">
                        <p style="width:80px; margin-top: 7px; margin-left: 25px">运费分摊:</p>
                        <el-select v-model="toRejectedDetailObj.freightShareType" placeholder="运费分摊" clearable>
                          <el-option
                            v-for="item in shareTypeList"
                            :key="item.id"
                            :label="item.value"
                            :value="item.id"
                          >
                          </el-option>
                        </el-select>
                      </div>
                      <div class="lable_div">
                        <p style="width: 115px; margin-top: 7px; margin-left: 20px;">其他费用:</p>
                        <el-input v-model="toRejectedDetailObj.otherFee" placeholder="请填写其他费用"></el-input>
                      </div>
                    </el-row>

                    <el-row type="flex" justify="flex-start" style="margin: 30px 0">
                       <div class="lable_div" style="margin-right: 100px">
                          采购总额： {{ toRejectedDetailObj.purchaseTotal }}
                       </div>
                       <div class="lable_div" style="margin-right: 100px">
                          贷款总计： {{ toRejectedDetailObj.loanTotal }}
                       </div>
                       <div class="lable_div" style="margin-right: 100px">
                          费用总计： {{ toRejectedDetailObj.costTotal }}
                       </div>
                    </el-row>

                    <el-row type="flex" justify="flex-start" style="margin: 30px 0">
                        <div class="lable_div">
                          <p style="width: 85px; margin-top: 7px;">物流: </p>
                          <el-select v-model="toRejectedDetailObj.logisticsId" placeholder="物流" clearable>
                            <el-option
                              v-for="item in transferTypeList"
                              :key="item.id"
                              :label="item.value"
                              :value="item.id"
                            >
                            </el-option>
                          </el-select>
                          <el-input style="margin-left: 15px" v-model="toRejectedDetailObj.logisticsNo" placeholder="请填写物流单号"></el-input>
                        </div>
                        <div class="lable_div">
                          <p style="width: 110px; margin-top: 7px; margin-left: 20px">预计到货时间: </p>
                           <el-date-picker
                            v-model="toRejectedDetailObj.expectArrivalTime"
                            type="date"
                            placeholder="请选择日期"
                            format="yyyy 年 MM 月 dd 日"
                            value-format="yyyy-MM-dd"
                            :picker-options="pickerOptions">
                           </el-date-picker>
                        </div>
                    </el-row>

                    <el-row type="flex" justify="flex-start">
                       <div class="lable_div">
                         <p style="width: 65px; margin-top: 7px">备注: </p>
                         <el-input v-model="toRejectedDetailObj.tips" placeholder="请输入信息进行备注" clearable></el-input>
                       </div>
                    </el-row>

                     <div>
                        <el-divider content-position="center">采购商品记录</el-divider>
                     </div>
                     <el-tabs v-model="activeRejectedTabs" @tab-click="handleClickRejected">
                        <el-tab-pane label="采购商品" name="91">
                           <el-table
                             border
                             :data="purchaseGoodsRejectedData"
                             :header-cell-style="{background:'#EFEFEF'}"
                             style="margin-top: 30px; width: 100%"
                            >
                               <el-table-column label="图片" align="center">
                                  <template slot-scope="scope">
                                     <img :src="scope.row.productImagePath" alt="" style="width: 80px; height: 60px">
                                  </template>
                               </el-table-column>
                               <el-table-column label="SKU信息" prop="commoditySku" align="center" :show-overflow-tooltip="true"></el-table-column>
                               <el-table-column label="商品名称" prop="productName" align="center" :show-overflow-tooltip="true"></el-table-column>
                               <el-table-column label="已采购" prop="actualPurchaseNumber" align="center"></el-table-column>
                               <el-table-column label="已入库" align="center">
                                  <el-table-column label="良品量" prop="goodNumber" align="center"></el-table-column>
                                  <el-table-column label="次品量" prop="defectiveNumber" align="center"></el-table-column>
                               </el-table-column>
                               <el-table-column label="未入库" prop="toPutNumber" align="center"></el-table-column>
                               <el-table-column label="采购单价(￥)" prop="purchaseUnitPrice" align="center"></el-table-column>
                               <el-table-column label="单位费用(￥)" prop="unitCost" align="center"></el-table-column>
                               <el-table-column label="单位入库成本(￥)" prop="unitPutCost" align="center"></el-table-column>
                               <el-table-column label="贷款总计(￥)" prop="productPriceTotal" align="center"></el-table-column>
                               <el-table-column label="费用总计(￥)" prop="productCostTotal" align="center"></el-table-column>
                               <el-table-column label="采购总额(￥)" prop="purchaseTotal" align="center"></el-table-column>
                               <el-table-column label="关联计划编号" prop="purchasePlanNo" align="center" :show-overflow-tooltip="true"></el-table-column>
                           </el-table>
                        </el-tab-pane>
                        <el-tab-pane label="付款记录" name="92">
                           <el-table
                              border
                              :data="PaymentRecordRejectedList"
                              :header-cell-style="{background:'#EFEFEF'}"
                              style="margin-top: 30px; width: 100%"
                            >
                               <el-table-column label="付款单号" prop="payNo" align="center"></el-table-column>
                               <el-table-column label="申请付款金额(￥)" prop="payableAmount" align="center"></el-table-column>
                               <el-table-column label="预计付款时间" prop="estimatePayDate" align="center"></el-table-column>
                               <el-table-column label="付款单状态" prop="statusName" align="center"></el-table-column>
                               <el-table-column label="付款人" prop="payBy" align="center"></el-table-column>
                               <el-table-column label="付款时间" prop="payTime" align="center"></el-table-column>
                               <el-table-column label="备注" prop="tips" align="center"></el-table-column>
                           </el-table>
                        </el-tab-pane>
                        <el-tab-pane label="退款记录" name="93">
                           <el-table
                              border
                              :data="RefundRecordRejectedList"
                              :header-cell-style="{background:'#EFEFEF'}"
                              style="margin-top: 30px; width: 100%"
                             >
                               <el-table-column label="收款单号" prop="collectionNo" align="center"></el-table-column>
                               <el-table-column label="申请收款金额(￥)" prop="receivableAmount" align="center"></el-table-column>
                               <el-table-column label="预计收款时间" prop="estimateCollectionDate" align="center"></el-table-column>
                               <el-table-column label="收款单状态" prop="statusName" align="center"></el-table-column>
                               <el-table-column label="收款人" prop="collectBy" align="center"></el-table-column>
                               <el-table-column label="收款时间" prop="collectTime" align="center"></el-table-column>
                               <el-table-column label="备注" prop="tips" align="center"></el-table-column>
                           </el-table>
                        </el-tab-pane>
                     </el-tabs>
                  </div>
               </div>
         </el-dialog>
        </el-tab-pane>
      </el-tabs>
      <!-- 到货 -->
      <el-dialog
         :title="title"
         :visible.sync="toArrivalBox"
         width="1100px"
         :before-close="toArrivalBoxClose">
         <div v-loading='dialogLoading'>
            <el-table
               class="outTable"
               :data="arrivalList"
               style="width: 100%;border-top:1px solid #EBEEF5"
               :cell-style="{ fontSize: '13px', padding: '7px 0',textAlign:'center' }"
               :header-cell-style="{
                  fontSize: '13px',
                  padding: '9px 0',
                  marginTop: '10px',
                  textAlign:'center'
               }"
               :span-method="arrivalMerge"
               default-expand-all
               row-key="id"
               :tree-props="{children: 'purchaseProductInfoResList'}">
               <el-table-column
                  prop="prop"
                  label="计划编号"
                  width="200px">
                  <template slot-scope="scope">
                     <div v-if="scope.row.purchaseProductInfoResList!=undefined" class="arrivalInfo">
                        <div>
                           <span>采购单号：</span>
                           <span>{{scope.row.purchaseNo}}</span>
                        </div>
                        <div>
                           <span>供应商：</span>
                           <span>{{scope.row.supplierName}}</span>
                        </div>
                        <div>
                           <span>本次运费：</span>
                           <span>
                              <el-input style="width:90px" v-model="scope.row.freightNum" placeholder="">
                                 <span slot="prefix">¥</span>
                              </el-input>
                           </span>
                        </div>
                        <div>
                           <span>本次其他费用：</span>
                           <span>
                              <el-input style="width:90px" v-model="scope.row.otherFeeNum" placeholder="">
                                 <span slot="prefix">¥</span>
                              </el-input>
                           </span>
                        </div>
                        <div>
                           <span>采购单运费：</span>
                           <span>¥{{scope.row.freight}}</span>
                        </div>
                        <div>
                           <span>其他费用：</span>
                           <span>¥{{scope.row.otherFee}}</span>
                        </div>
                     </div>
                     <span v-else>{{scope.row.purchasePlanNo}}</span>
                  </template>
               </el-table-column>
               <el-table-column
                  prop="prop"
                  label="图片">
                  <template slot-scope="scope">
                     <Picture style="margin:auto" :url='scope.row.productImagePath' :width='40'></Picture>
                  </template>
               </el-table-column>
               <el-table-column
                    prop="sku"
                    label="品名/SKU"
                    width="width">
                    <template v-if="scope.row.itemList==undefined" slot-scope="scope">
                        <p :title="scope.row.commodityName" class="oneline">{{scope.row.commodityName}}</p>
                        <p :title="scope.row.commoditySku" class="oneline">{{scope.row.commoditySku}}</p>
                    </template>
               </el-table-column>
               <el-table-column
                  prop="prop"
                  label="店铺">
               </el-table-column>
               <el-table-column
                  prop="prop"
                  label="MSKU/FNSKU">
               </el-table-column>
               <el-table-column
                  prop="actualPurchaseNumber"
                  label="采购量">
               </el-table-column>
               <el-table-column
                  prop="toPutNumber"
                  label="待到货量">
               </el-table-column>
               <el-table-column
                  v-if="title=='到货'"
                  prop="prop"
                  label="本次到货量">
                  <template slot="header">
                     本次到货量<span style="color:#3B90FF" @click="inputAll">(全部)</span>
                  </template>
                  <template slot-scope="scope">
                     <el-input 
                     onkeyup="value=value.replace(/^\.+|[^\d.]/g,'')"
                     @input="calculate(scope.row)"
                     @change="scope.row.receiveQuantity=scope.row.receiveQuantity>scope.row.toPutNumber?scope.row.toPutNumber:scope.row.receiveQuantity"
                     v-model="scope.row.receiveQuantity" 
                     placeholder="本次到货量"
                     ></el-input>
                  </template>
               </el-table-column>
               <el-table-column
                  v-if="title=='快捷入库'"
                  prop="prop"
                  label="label" >
                  <template slot="header">
                     良品量<span @click="goodAll" style="color:#3B90FF">(全部)</span>
                  </template>
                  <template slot-scope="scope">
                     <el-input 
                     @blur="putStorage"
                     onkeyup="value=value.replace(/^\.+|[^\d.]/g,'')"
                     v-model="scope.row.goodQuantity"
                     @input="putStorage"
                     @change="scope.row.goodQuantity=Number(scope.row.goodQuantity)+Number(scope.row.badQuantity)>Number(scope.row.toPutNumber)?Number(scope.row.toPutNumber)-Number(scope.row.badQuantity):Number(scope.row.goodQuantity)"
                     placeholder="良品量"></el-input>
                  </template>
               </el-table-column>
               <el-table-column
                  v-if="title=='快捷入库'"
                  prop="prop"
                  label="label" >
                  <template slot="header">
                     次品量<span style="color:#3B90FF" @click="badAll">(全部)</span>
                  </template>
                  <template slot-scope="scope">
                     <el-input 
                     @blur="putStorage"
                     @input="putStorage"
                     @change="scope.row.badQuantity=Number(scope.row.goodQuantity)+Number(scope.row.badQuantity)>Number(scope.row.toPutNumber)?Number(scope.row.toPutNumber)-Number(scope.row.goodQuantity):Number(scope.row.badQuantity)"
                     v-model="scope.row.badQuantity"
                     onkeyup="value=value.replace(/^\.+|[^\d.]/g,'')"
                     placeholder="次品量"></el-input>
                  </template>
               </el-table-column>
            </el-table>
         </div>
         <div slot="footer">
            <el-button @click="toArrivalBoxClose">取 消</el-button>
            <el-button type="primary" :loading='dialogLoading' @click="batchPurchaseArrival">确 定</el-button>
         </div>
      </el-dialog>
      <el-dialog
         title="title"
         :visible.sync="singleHarvest"
         width="width"
         :before-close="singleHarvestClose">
         <div>

         </div>
         <div slot="footer">
            <el-button @click="dialogVisible = false">取 消</el-button>
            <el-button type="primary" @click="dialogVisible = false">确 定</el-button>
         </div>
      </el-dialog>
   </div>
</template>

<script>
   import {Digits} from '@/utils/Digits.js'
   import {
     PageInit,
     getListPage,
     PurchaseAddInit,
     addPurchase,
     batchUpdate,
     PurchaseDelete,
     updateInit,   //  编辑初始化
     getPurchasePayBill,
     getPurchaseCollectBill,
     editPurchaseOrder,
     showPurchaseDetail,
     batchCreatePayBill,  // 批量申请付款
     batchPurchasePaymentInit,
     getFinanceAccountType, 
     getSupplierAccount,
     batchPurchaseCollectInit,
     batchCreateCollectBill,    // 批量申请退款
     batchDeleteEvent,
     deletePurchaseProduct,  // 删除采购商品
     purchaseWarehousingInit,  // 入库初始化
     purchaseCreateReceiveInit,
     purchaseWarehouse,  // 入库
     getPurchaseProductInfoByPurchase,  // 采购商品的信息
     contractPrint,//合同打印
     batchPurchaseArrival,
     receiveQuickPutIn,
   } from '@/api/procurementManage/procurementOrder.js'
   import {
     getProductListPage,  // 商品查询 (分页)
   }from '@/api/procurementManage/procurementPlan.js'
   export default {
     data() {
       return {
         pickerOptions: {
          disabledDate(time) {
            return time.getTime() < (Date.now() - 3600 * 1000 * 24);
          }
         },

         toArrivalBox:false,
         arrivalList:[],
         dialogLoading:false,
         title:'',
         singleHarvest:false,

         activeFullTabs: "11",
         activeTabs: "21",
         activeAuditTabs: "31",
         activeArriveTabs: "51",
         activeDoneTabs: "71",
         activeInvalidTabs: "81",
         activeRejectedTabs: "91",
         activeSubmitTabs: "101",
         activeName: "60",
         editPurchaseId: "",
         FullOrderActive: "",
         submitAuditActive: "",
         toAuditActive: "",
         toArrivalActive: "",
         editSubmitActive: "",
         toDoneActive: "",
         toInvalidActive: "",
         toRejectedActive: "",
         submitOrderActive: "",
         creatRejectedDescription: "",
         RejectedDescription: "",
         FullOrderDescription: "",
         submitOrderDescription: "",
         editSubmitDescription: "",
         toArrivalDescription: "",
         toArrivalCheckDescription: "",
         toArrivalInStorageDescription: "",
         toCreateDescription: "",
         toReviewDescription: "",
         PutInStorageDescription: "",
         toCompleteDescription: "",
         toAuditDescription: "",
         toInvalidCreateDescription: "",
         toInvalidReviewDescription: "",
         fullCreateDescription: "",
         fullAuditDescription: "",
         fullInStorageDescription: "",
         fullCompleteDescription: "",
         toInvalidDescription: "",
         fullInvalidDescription: "",
         fullRejectedDescription: "",
         putInStatusTypeList: [],
         purchaseStatusList: [],
         payStatusTypeList: [],
         purchaseOrderList: [],
         purchaseOrderList2: [],
         storageList: [],
         supplierList: [],
         purchaseOrderData: [],
         newGoodsLists: [],
         currentSelectList: [],
         packageList: [],
         createPurchaseOrderList: [],
         shareTypeList: [],
         transferTypeList: [],
         purchaseOrderDataList: [],
         goodsDataList: [],
         PurchasePayList: [],
         PurchaseCollectList: [],
         purchaseOrderList3: [],
         purchaseOrderList4: [],
         purchaseOrderList5: [],
         purchaseGoodsDetail: [],
         PaymentRecordData: [],
         CollectionRecordData: [],
         FinanceAccountList: [],
         PaymentAccountlist: [],
         applyPaymentList: [],
         replacementAddInitTwoReqList: [],
         CollectionAccounts: [],
         applyRefundDataList: [],
         CollectionBillingDataList: [],
         purchaseGoodsArrivalData: [],
         PaymentRecordArrivalList: [],
         RefundRecordArrivalList: [],
         replacementAddInitTwoReqListDone: [],
         applyPaymentListDone: [],
         applyRefundDataDoneList: [],
         purchaseGoodsDoneData: [],
         PaymentRecordDoneList: [],
         RefundRecordDoneList: [],
         purchaseOrderList6: [],
         purchaseOrderList7: [],
         purchaseGoodsInvalidData: [],
         PaymentRecordInvalidList: [],
         RefundRecordInvalidList: [],
         purchaseGoodsRejectedData: [],
         PaymentRecordRejectedList: [],
         RefundRecordRejectedList: [],
         FullpurchaseOrderlist: [],
         fullPaymentRecord: [],
         fullRefundRecordList: [],
         submitPurchaseOrderlist: [],
         submitPaymentRecord: [],
         submitRefundRecordList: [],
         multipleSelection: [],
         batchSelectionList: [],
         multipleSelectionArrival: [],
         batchApplyPaymentList: [],
         putInStorageList: [],
         multipleSelection_List: [],
         addGoodsLists: [],
         totalData: 0,  // 总数据
         totalSubmit: 0,   // 待提交
         totalAudit: 0,    //  待审核总数 
         totalArrival: 0,  //  待到货
         totalDone: 0,   // 已完成
         totalInvalid: 0,   // 已作废
         totalRejected: 0,  // 已驳回
         totalAddGoods: 0,
         totalNewGoods: "",
         notThroughId: "", 
         purchaseDetailId: "",  // 待审核详情id
         purchaseArrivalDetailId: "",   // 待到货详情Id
         purchaseDoneDetailId: "",
         purchaseInvalidDetailId: "",
         purchaseRejectedDetailId: "",
         fullOrderDetailId: "",
         curPutInStorageId: "",
         invalidId: "",  // 作废Id值
         submitPurchaseId: "",
         PurchaseOrderVisible: false,
         newGoodsVisible: false,
         createPurchaseVisible: false,
         editPurchaseOrderVisible: false,
         failureDialogVisible: false,
         toAuditOrderVisible: false,
         toApplyPaymentVisible: false,
         invalidDialogVisible: false,  // 作废采购单 
         toApplyRefundVisible: false,
         toArrivalOrderVisible: false,   // 待到货详情
         toApplyPaymentDoneVisible: false,
         toApplyRefundDoneVisible: false,
         toDoneOrderVisible: false,
         toInvalidOrderVisible: false,
         toFullOrderVisible: false,
         toSubmitOrderVisible: false,
         batchFailureVisible: false,
         toBatchApplyPaymentVisible: false,
         toRejectedOrderVisible: false,
         showCreate: false,
         showInvalid: false,
         showRejected: false,
         putInStorageVisible: false,
         addGoodsVisible: false,
         purchaseOrderDetails: {},
         toAuditDetailObj: {},
         toArrivalDetailObj: {},
         toDoneDetailObj: {},  // 已完成详情
         toInvalidDetailObj: {},
         toRejectedDetailObj: {},
         fullPurchaseOrderObj: {},
         submitPurchaseOrderObj: {},
         InventoryDetail: {},  // 入库详情
         invalidForm: {
           reason: "",  // 作废原因
         },
         pageInfoAddGoods: {
           keywords: "",
           current: 1,
           pageSize: 10
         },
         invalidRules: {
           reason: [
             { required: true, message: "请填写作废原因", trigger: "blur" }
           ]
         },
         form: {
           reason: "",  // 未通过原因
         },
         batchForm: {
            reason: "",  // 未通过原因
         },
         rules: {
           reason: [
             { required: true, message: "请填写不通过原因", trigger: "blur" }
           ],
         },
         batchRules: {
            reason: [
              { required: true, message: "请填写不通过原因", trigger: "blur" }
            ]
         },
         // 全部
         pageDataAll: {
           putInStatus: "",
           purchaseStatus: "",
           payStatus: "",  // 付款状态
           keywords: "",
           storageId: "",  // 采购仓库
           supplierId: "", // 采购供应商
           current: 1,
           pageSize: 10
         },
         // 待提交
         pageDataSubmit: {
            storageId: "",
            supplierId: "",
            keywords: "",
            current: 1,
            pageSize: 10
         },
         // 待审核
         pageDataAudit: {
            storageId: "",
            supplierId: "",
            keywords: "",
            current: 1,
            pageSize: 10
         },
         // 待到货
         pageDataArrival: {
            storageId: "",
            putInStatus: "",
            payStatus: "",
            supplierId: "",
            keywords: "",
            current: 1,
            pageSize: 10
         },
         // 已完成
         pageDataDone: {
            storageId: "", 
            putInStatus: "",  // 入库状态
            payStatus: "",
            supplierId: "",   // 供应商
            keywords: "",   // 关键字
            current: 1,
            pageSize: 10
         },
         // 已作废
         pageDataInvalid: {
            storageId: "",
            supplierId: "",  // 供应商
            keywords: "",   
            current: 1,
            pageSize: 10
         },
         // 已驳回
         pageDataRejected: {
            storageId: "",
            supplierId: "",
            keywords: "",
            current: 1,
            pageSize: 10
         },
         pageInfoGoods: {
            current: 1,
            pageSize: 10,
            keywords: "",  // 关键字
         },
         paymentAccountType: "",
         paymentNumber: "",
         accountOfPayment: "",
         paymentAccountName: "",
         loading:'',
       }
     },
     created() {
        this.tableComplete1();  // 已进入页面就刷新数据
        this.getPageInit();
     },
     computed: {},
     methods: {
        purchaseCreateReceiveInit(id){
           this.$router.push({
              path:'/ReceivingOrder',
              query:{
                 order_id:id
              }
           })
        },
        putStorage(){
            this.arrivalList.forEach(item=>{
               let freightNum = 0
               let otherFeeNum = 0
               item.purchaseProductInfoResList.forEach(i=>{
                  // if(i.receiveQuantity=='') i.receiveQuantity=0
                  if(item.freightShareTypeName=='不分配') {
                     freightNum=0
                     otherFeeNum=0
                  }else if(item.otherFee==0 && item.freight ==0 ){
                     freightNum=0
                     otherFeeNum=0
                  }else{
                     freightNum += (item.freight/(item.otherFee+item.freight))*((Number(i.goodQuantity)+Number(i.badQuantity))*i.unitCost)
                     otherFeeNum += (item.otherFee/(item.otherFee+item.freight))*((Number(i.goodQuantity)+Number(i.badQuantity))*i.unitCost)
                  }
               })
               item.freightNum = Digits(freightNum,2)
               item.otherFeeNum = Digits(otherFeeNum,2)
            })
        },
        goodAll(){
           this.arrivalList.forEach(item=>{
              item.purchaseProductInfoResList.forEach(i=>{
                 i.goodQuantity=i.toPutNumber
                 i.badQuantity=0
              })
           })
           this.putStorage()
        },
        badAll(){
           this.arrivalList.forEach(item=>{
              item.purchaseProductInfoResList.forEach(i=>{
                 i.badQuantity=i.toPutNumber
                 i.goodQuantity=0
              })
           })
           this.putStorage()
        },
        batchPurchaseArrival(){
           if(this.title=='到货'){
               let list = this.arrivalList.map(item=>{
                  const arr = []
                  item.purchaseProductInfoResList.forEach(i=>{
                     if(Number(i.receiveQuantity)!=0){
                        arr.push({
                           productId:i.productId,
                           purchaseProductId:i.id,
                           receiveQuantity:i.receiveQuantity
                        })
                     }
                  })
                  return {
                     itemList:arr,
                     freight:item.freightNum,
                     freightShareType:item.freightShareType,
                     otherFee:item.otherFeeNum,
                     purchaseBy:item.purchaseBy,
                     purchaseNo:item.purchaseNo,
                     receiveType:item.receiveType,
                     storageId:item.storageId,
                     supplierId:item.supplierId,
                  }
               })
               list=list.filter(item=>item.itemList.length!=0)
               if(list.length==0) return this.$message.error('到货量不能为0')
               this.dialogLoading=true
               batchPurchaseArrival({list:list}).then(res=>{
                  this.dialogLoading=false
                  if(res.data.code==200){
                     this.$message.success(res.data.message)
                     this.toArrivalBoxClose()
                     this.SearchArrival()
                  }else{
                     this.$message.error(res.data.message)
                  }
               })
            }
            if(this.title=='快捷入库'){
               const list = this.arrivalList.map(item=>{
                  const arr = []
                  item.purchaseProductInfoResList.forEach(i=>{
                    if(Number(i.badQuantity)!=0||Number(i.goodQuantity!=0)){
                        arr.push({
                           badQuantity:i.badQuantity,
                           goodQuantity:i.goodQuantity,
                           productId:i.productId,
                           purchaseProductId:i.id
                        })
                     }
                  })

                 return {
                     itemList:arr,
                     freight:item.freightNum,
                     freightShareType:item.freightShareType,
                     otherFee:item.otherFeeNum,
                     purchaseBy:item.purchaseBy,
                     purchaseNo:item.purchaseNo,
                     receiveType:item.receiveType,
                     storageId:item.storageId,
                     supplierId:item.supplierId,
                  }
              })
              const submitList= []
               list.forEach(item=>{
                 if(item.itemList.length!=0){
                    submitList.push(item)
                 }
              })
              if(submitList.length==0) return this.$message.error('入库数量不能为0')
              this.dialogLoading=true
              receiveQuickPutIn({list:submitList}).then(res=>{
                 this.dialogLoading=false
                  if(res.data.code==200){
                     this.$message.success(res.data.message)
                     this.toArrivalBoxClose()
                     this.SearchArrival()
                  }else{
                     this.$message.error(res.data.message)
                  }
              })
           }
           
        },
        toArrivalBoxClose(){
           this.toArrivalBox=false
           this.arrivalList=[]
        },
        calculate(info){
           this.arrivalList.forEach(item=>{
               let freightNum = 0
               let otherFeeNum = 0
               item.purchaseProductInfoResList.forEach(i=>{
                  // if(i.receiveQuantity=='') i.receiveQuantity=0
                  if(item.freightShareTypeName=='不分配') {
                     freightNum=0
                     otherFeeNum=0
                  }else if(item.otherFee==0 && item.freight ==0 ){
                     freightNum=0
                     otherFeeNum=0
                  }else{
                     freightNum += (item.freight/(item.otherFee+item.freight))*(i.receiveQuantity*i.unitCost)
                     otherFeeNum += (item.otherFee/(item.otherFee+item.freight))*(i.receiveQuantity*i.unitCost)
                  }
               })
               item.freightNum = Digits(freightNum,2)
               item.otherFeeNum = Digits(otherFeeNum,2)
           })
        },
        arrivalMerge({row, column, rowIndex, columnIndex}){
            if (row.purchaseProductInfoResList!=undefined) {
               if (columnIndex == 0) {
                  if(this.title=='到货'){
                     return [1, 8];
                  }else {
                     return [1, 9];
                  }
               } else {
                  return [1, 1];
               }
            }else{
                  [0,1]
            }
       },
       handleClick(tab) {
         if(tab.name == "60") {
           this.tableComplete1();   // 查询全部 
         }else if(tab.name == "61") {
           this.tableSearch2();     // 待提交查询
         }else if(tab.name == "62") {
            this.tableAudit3();     // 待审核查询
         }else if(tab.name == "63") {
            this.tableArrival4();   // 待到货查询
         }else if(tab.name == '64') {
            this.tableDone5();      // 已完成查询
         }else if(tab.name == "65") {
            this.tableInvalid6();   // 已作废查询
         }else if(tab.name == "66") {
            this.tableRejected7()   // 已驳回查询
         }
       },   
       inputAll(){
          this.arrivalList.forEach(item=>{
             item.purchaseProductInfoResList.forEach(i=>{
                i.receiveQuantity=Number(i.toPutNumber)
             })
          })
          this.arrivalList.forEach(item=>{
               let freightNum = 0
               let otherFeeNum = 0
               item.purchaseProductInfoResList.forEach(i=>{
                  // if(i.receiveQuantity=='') i.receiveQuantity=0
                  freightNum += (item.freight/(item.otherFee+item.freight))*(i.receiveQuantity*i.unitCost)
                  otherFeeNum += (item.otherFee/(item.otherFee+item.freight))*(i.receiveQuantity*i.unitCost)
               })
               if(item.freight==0){
                  item.freightNum=0
               }else{
                  item.freightNum = Digits(freightNum,2)
               }
               if(item.otherFee==0){
                  item.otherFeeNum=0
               }else {
                  item.otherFeeNum = Digits(otherFeeNum,2)
               }
           })
       },
       toArrival(info,title){
          this.loading=true
          this.title=title
          purchaseCreateReceiveInit({ids:info}).then(res=>{
             console.log(res);
             this.loading=false
             if(res.data.code==200){
                this.arrivalList=res.data.data.map(item=>{
                   item.freightNum=''
                   item.otherFeeNum=''
                   item.purchaseProductInfoResList.forEach(i=>{
                      i.receiveQuantity=''
                      i.badQuantity=''
                      i.goodQuantity=''
                   })
                   return item
                })
                if(title!='添加收货单'){
                   this.toArrivalBox=true
                }
             }else{
                this.$message.error(res.data.message)
             }
          })
       },
       getPageInit() {      // 查询初始化  
         PageInit()
         .then(res=> {
            if(res.data.code == 200) {
              this.putInStatusTypeList = res.data.data.putInStatusTypeList;  // 入库状态
              this.purchaseStatusList = res.data.data.purchaseStatusList;  // 采购状态
              this.payStatusTypeList = res.data.data.payStatusTypeList;  // 付款状态
              this.storageList = res.data.data.storage;   // 采购仓库
              this.supplierList = res.data.data.supplier;  // 供应商
              this.shareTypeList = res.data.data.freightShareTypeList;  // 运费分摊
              this.transferTypeList = res.data.data.transferTypeList;  // 物流
            }
         })
       },
       tableComplete1() {
         this.pageDataAll.current = 1;
         this.pageDataAll.pageSize = 10;
         this.SearchComplete();
       },
       SearchComplete() {   // 查询全部  
         let params = {
           current: this.pageDataAll.current,
           pageSize: this.pageDataAll.pageSize,
           keyword: this.pageDataAll.keywords,
           storageId: this.pageDataAll.storageId,
           supplierId: this.pageDataAll.supplierId,
           warehousingStatus: this.pageDataAll.putInStatus,  // 入库状态
           paymentStatus: this.pageDataAll.payStatus,  // 支付状态
           status: this.pageDataAll.purchaseStatus   // 采购状态
         }
         getListPage(params)
         .then(res=> {
           if(res.status == 200) {
              this.purchaseOrderList = [];
              if(res.data.values&&Array.isArray(res.data.values)&&res.data.values.length) {
                 this.purchaseOrderList = res.data.values;
                 this.totalData  =res.data.pageInfo.total;
              }
           }
         })
       },
       handleSizeChange1(newSize) {
         this.pageDataAll.pageSize = newSize;
         this.SearchComplete();
       },
       handleCurrentChange1(newCurrent) {
         this.pageDataAll.current = newCurrent;
         this.SearchComplete();
       },
       ShowFullDetail(row) {     // 查看详情全部
         this.fullOrderDetailId = row.id;
         showPurchaseDetail(row.id)
           .then(res=> {
              this.fullPurchaseOrderObj = {};
              if(res.data.code == 200) {
                 this.fullPurchaseOrderObj = res.data.data;
                 this.FullpurchaseOrderlist = res.data.data.purchaseProductInfoResList;
                 if(res.data.data.status == 221 || res.data.data.status == 222) {   // 待提交  待审核
                  this.FullOrderActive = 1;
                  this.showCreate = true;
                  this.showInvalid = false;
                  this.showRejected = false;
                  this.fullCreateDescription = res.data.data.createByName + '--' + res.data.data.createTime;    
                 }else if(res.data.data.status == 223) {   // 待到货
                    this.FullOrderActive = 2; 
                    this.showCreate = true;
                    this.showInvalid = false;
                    this.showRejected = false;
                    this.fullCreateDescription = res.data.data.createByName + '--' + res.data.data.createTime;
                    this.fullAuditDescription = res.data.data.examineByName + '--' + res.data.data.examineTime;
                 }else if(res.data.data.status == 224) {   //  已完成
                    this.FullOrderActive = 4; 
                    this.showCreate = true;
                    this.showInvalid = false;
                    this.showRejected = false;
                    this.fullCreateDescription = res.data.data.createByName + '--' + res.data.data.createTime;
                    this.fullAuditDescription = res.data.data.examineByName + '--' + res.data.data.examineTime;
                    this.fullInStorageDescription = res.data.data.putInByName + '--'+ res.data.data.putInTime;
                    this.fullCompleteDescription = res.data.data.finishByName + '--' + res.data.data.finshTime;
                 }else if(res.data.data.status == 225) {   // 已作废
                    this.FullOrderActive = 3;
                    this.showInvalid = true;
                    this.showCreate= false;
                    this.showRejected = false;
                    this.fullCreateDescription = res.data.data.createByName + '--' + res.data.data.createTime;
                    this.fullAuditDescription = res.data.data.examineByName + '--' + res.data.data.examineTime;
                    this.fullInvalidDescription = res.data.data.cancelByName + '--' + res.data.data.cancelTime;
                 }else if(res.data.data.status == 226) {   // 已驳回
                    this.FullOrderActive = 2;
                    this.showRejected = true;
                    this.showCreate = false;
                    this.showInvalid = false;
                    this.fullCreateDescription = res.data.data.createByName + '--' + res.data.data.createTime;
                    this.fullRejectedDescription = res.data.data.rejectByName + '--' + res.data.data.rejectTime;
                 }
              }
           })
           this.toFullOrderVisible = true;
       },
       handleClickFull(tab) {
          if(tab.name == "12") {
             getPurchasePayBill(this.fullOrderDetailId)
             .then(res=> {
                if(res.data.code == 200) {
                  this.fullPaymentRecord = res.data.data.data;
                }
             })
          }else if(tab.name == "13") {
            getPurchaseCollectBill(this.fullOrderDetailId)
             .then(res=> {
               if(res.data.code == 200) {
                  this.fullRefundRecordList = res.data.data.data;
               }
             })
          }
       },
       tableSearch2() {
         this.pageDataSubmit.current = 1;
         this.pageDataSubmit.pageSize = 10;
         this.SearchSubmit();
       },
       SearchSubmit() {       // 待提交查询
         let params = {
            current: this.pageDataSubmit.current,
            pageSize: this.pageDataSubmit.pageSize,
            keyword: this.pageDataSubmit.keywords,
            storageId: this.pageDataSubmit.storageId,
            supplierId: this.pageDataSubmit.supplierId,
            status: 221
         }
         getListPage(params)
         .then(res=> {
            this.purchaseOrderList2 = [];
            if(res.status == 200) {
               if(res.data.values&&Array.isArray(res.data.values)&&res.data.values.length) {
                 this.purchaseOrderList2 = res.data.values;
                 this.totalSubmit = res.data.pageInfo.total;
               }
            }
         })
       },
       addPurchaseOrder() {     // 创建采购单
         this.purchaseOrderData = [];
         this.PurchaseOrderVisible = true;
       },
       async batchSubmitAudit() {     // 批量提交审核
         if(this.multipleSelection.length == 0) {
           this.$message.warning('请选中采购单');
           return false;
         }
         const confirmResult = await this.$confirm("确定批量提交该审核吗？", "提交审核", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning"
          }).catch(err => err);
          if (confirmResult !== "confirm") {
            return;
         }
         const ids = [];
         this.multipleSelection.forEach(item=> {
            ids.push(item.id)
         })
         let data = {
            ids: ids,
            status: 222
         }
         batchUpdate(data)
          .then(res=> {
             if(res.data.code == 200) {
               this.$message.success('批量审核成功');
               this.SearchSubmit();
             }else if(res.data.code == 500) {
               this.$message.error(res.data.message);
             } 
          })
       }, 
       async batchDelete() {      // 批量删除 
         if(this.multipleSelection.length == 0) {
            this.$message.warning('请选中采购单');
            return false;
         }
         const confirmResult = await this.$confirm("确定批量删除该数据吗？", "提示", {
           confirmButtonText: "确定",
           cancelButtonText: "取消",
           type: "warning"
         }).catch(err => err);
         if (confirmResult !== "confirm") {
           return;
         }
         const ids = [];
         this.multipleSelection.forEach(item=> {
            ids.push(item.id)
         })
          let data = {
             ids: ids
          }
          batchDeleteEvent(data)
           .then(res=> {
              if(res.data.code == 200) {
                this.$message.success(res.data.message);
                this.SearchSubmit();
              }else if(res.data.code == 500) {
                this.$message.error(res.data.message);
              }   
           })
       },
       handleSelectionChange(val) {
          this.multipleSelection = val;
       }, 
       async submitAudit(row) {     // 提交审核 
         const confirmResult = await this.$confirm("确定提交该审核吗？", "提交审核", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning"
          }).catch(err => err);
          if (confirmResult !== "confirm") {
            return;
          }
          const ids = [];
          ids.push(row.id);
          let data = {
            ids: ids,
            notPassReason: "",
            status: row.status + 1
          }
          batchUpdate(data)
          .then(res=> {
             if(res.data.code == 200) {
               this.$message.success('提交审核成功');
               this.SearchSubmit();
             }else if(res.data.code == 500){
               this.$message.error(res.data.message);
             }
          })
       },
       // 编辑
       submitEdit(item) {
         this.editPurchaseId = item.id;
         console.log(this.editPurchaseId);
         updateInit(item.id)
         .then(res=> {
            this.purchaseOrderDetails = {};
            if(res.data.code == 200) {
               this.purchaseOrderDetails = res.data.data;
               this.goodsDataList = res.data.data.purchaseProductInfoResList;
               if(res.data.data.status == 221) {
                 this.editSubmitActive = 1;
                 this.editSubmitDescription = res.data.data.createByName + '--' + res.data.data.createTime;
               }
            }
         })
         this.editPurchaseOrderVisible = true;
       },
       // 获取采购商品信息
       getProcureGoods() {
          getPurchaseProductInfoByPurchase(this.editPurchaseId)
          .then(res=> {
            this.goodsDataList = [];
            if(res.data.code == 200) {
              this.goodsDataList = res.data.data;
            }
          })
       },  
       // 保存采购单
       asyncEditPurchaseOrder() {
         var arrData = [];
         var type1 = [];
         var type2 = [];
         this.purchaseOrderDetails.purchaseProductInfoResList.forEach(item=> {
           if(item.isAddNew == 1) {
             let obj= {
               actualPurchaseNumber: parseInt(item.actualPurchaseNumber),
               actualSupplierId: item.actualSupplierId,
               productId: item.productId,
               purchaseUnitPrice: parseInt(item.purchaseUnitPrice)
             }
             type1.push(obj)
           }else {
             let obj= {
               actualPurchaseNumber: item.actualPurchaseNumber,
               actualSupplierId: item.actualSupplierId,
               id: item.id,
               purchaseUnitPrice: item.purchaseUnitPrice
             }
             type2.push(obj)
           }
           arrData = [...type1, ...type2] 
         })
         let data = {
            freight: this.purchaseOrderDetails.freight,  // 运费
            freightShareType: this.purchaseOrderDetails.freightShareType, // 分摊运费方式
            otherFee: this.purchaseOrderDetails.otherFee,   // 其他运费
            id: this.purchaseOrderDetails.id,
            logisticsId: this.purchaseOrderDetails.logisticsId,  // 物流
            logisticsNo: this.purchaseOrderDetails.logisticsNo,
            expectArrivalTime: this.purchaseOrderDetails.expectArrivalTime,  // 预计到货时间
            tips: this.purchaseOrderDetails.tips,
            purchaseProductInfoUpdateReqs: arrData
         }
         editPurchaseOrder(data)
         .then(res=> {
            if(res.data.code == 200) {
              this.$message.success(res.data.message);
              this.editPurchaseOrderVisible = false;
              this.SearchSubmit();  // 查询
            }else if(res.data.code == 500) {
               this.$message.error(res.data.message);
            } 
         })
       },
       // 删除
       async submitDele(row) {
         const confirmResult = await this.$confirm("确定删除该数据吗？", "提示", {
           confirmButtonText: "确定",
           cancelButtonText: "取消",
           type: "warning"
         }).catch(err => err);
         if (confirmResult !== "confirm") {
           return;
         }
         PurchaseDelete(row.id)
          .then(res=> {
             if(res.data.code == 200) {
               this.$message.success(res.data.message);
               this.SearchSubmit();
             }else if(res,data.code == 500) {
               this.$message.error(res.data.message);
             }
          })
       },
       // 待提交查看详情
       submitShowDetail(row) {
         this.submitPurchaseId = row.id;
         showPurchaseDetail(row.id)
           .then(res=> {
              this.submitPurchaseOrderObj = {};
              if(res.data.code == 200) {
                 this.submitPurchaseOrderObj = res.data.data;
                 this.submitPurchaseOrderlist = res.data.data.purchaseProductInfoResList;
                 if(res.data.data.status == 221) {
                  this.submitOrderActive = 1;
                  this.submitOrderDescription = res.data.data.createByName + '--' + res.data.data.createTime;
                 }
              }
           })
         this.toSubmitOrderVisible = true;
       },
       handleClickSubmit(tab) {
          if(tab.name == "102") {
             getPurchasePayBill(this.submitPurchaseId)
              .then(res=> {
                 if(res.data.code == 200) {
                  this.submitPaymentRecord = res.data.data.data;  
                 }
              })
          }else if(tab.name == "103") {
             getPurchaseCollectBill(this.submitPurchaseId)
              .then(res=> {
                 if(res.data.code == 200) {
                   this.submitRefundRecordList = res.data.data.data; 
                 }
              })
          }
       },
       tableAudit3() {
         this.pageDataAudit.current = 1;
         this.pageDataAudit.pageSize = 10;
         this.SearchAudit(); 
       },
       // 待审核查询  
       SearchAudit() {
         let params = {
           current: this.pageDataAudit.current,
           pageSize: this.pageDataAudit.pageSize,
           keyword: this.pageDataAudit.keywords,
           storageId: this.pageDataAudit.storageId,
           supplierId: this.pageDataAudit.supplierId,
           status: 222
         }
         getListPage(params)
         .then(res=> {
            if(res.status == 200) {
              this.purchaseOrderList3 = [];
              if(res.data.values&&Array.isArray(res.data.values)&&res.data.values.length) {
                this.purchaseOrderList3 = res.data.values;
                this.totalAudit = res.data.pageInfo.total;
              }
            }
         })
       },
       // 审核通过
       async auditApproved(row) {
         const confirmResult = await this.$confirm("是否确认审核通过？", "确认审核", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning"
         }).catch(err => err);
          if (confirmResult !== "confirm") {
            return;
         }
         const ids = [];
         ids.push(row.id)
         let data = {
            ids: ids,
            status: 223
         }
         batchUpdate(data)
          .then(res=> {
            if(res.data.code == 200) {
               this.$message.success(res.data.message);
               this.SearchAudit();  // 待审核查询
            }else if(res.data.code == 500) {
               this.$message.error(res.data.message);
            }
         })
       },
       // 审核不通过
       auditDisagree(row) {
         this.notThroughId = row.id;
         this.failureDialogVisible = true;
       },
       asyncFail() {
         this.$refs.formRef.validate(valid => {
           if(valid) {
             const ids = [];
             ids.push(this.notThroughId);
             let data = {
               ids: ids,
               notPassReason: this.form.reason,
               status: 226    // 审核不通过 == 已驳回
             }
             batchUpdate(data)
              .then(res=> {
                 if(res.data.code == 200) {
                   this.$message.success(res.data.message);
                   this.failureDialogVisible = false;
                   this.SearchAudit();  // 待审核查询
                   this.form.reason = "";
                 }else if(res.data.code == 500) {
                   this.$message.error(res.data.message);
                 }
              })
           }else {
              return false;
           }
         })
       },
       // 待审核详情
       auditShowDetail(row) {
         this.purchaseDetailId = row.id;
          showPurchaseDetail(row.id)
           .then(res=> {
              this.toAuditDetailObj = {};
              if(res.data.code == 200) {
                this.toAuditDetailObj = res.data.data;
                this.purchaseGoodsDetail = res.data.data.purchaseProductInfoResList;
                if(res.data.data.status == 222) {
                  this.toAuditActive = 1;
                  this.toAuditDescription = res.data.data.createByName + '--' + res.data.data.createTime;
                }
              }   
           })
         this.toAuditOrderVisible = true;
       },
       handleClickAudit(tab) {
          if(tab.name == "32") {
            getPurchasePayBill(this.purchaseDetailId)
             .then(res=> {
               if(res.data.code == 200) {
                  this.PaymentRecordData = res.data.data.data;
               }
             })
          }else if(tab.name == "33") {
             getPurchaseCollectBill(this.purchaseDetailId)
             .then(res=> {
               if(res.data.code == 200) {
                 this.CollectionRecordData = res.data.data.data;
               }
             })
          }
       },  
       // 批量审核通过  
       async batchBeApproved() {
         if(this.batchSelectionList.length == 0) {
            this.$message.warning('请选中采购单');
            return false;
         }
         const confirmResult = await this.$confirm("是否确认批量审核通过？", "确认审核", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning"
         }).catch(err => err);
          if (confirmResult !== "confirm") {
            return;
         }
         const ids = [];
         this.batchSelectionList.forEach(item=> {
            ids.push(item.id);
         })
         let data = {
            ids: ids,
            status: 223
         }
         batchUpdate(data)
          .then(res=> {
            if(res.data.code == 200) {
              this.$message.success('批量审核通过');
              this.SearchAudit();
            }else if(res.data.code == 500) {
              this.$message.error(res.data.message)
            }
          })
       },
       // 批量不通过
       batchAuditDisagree() {
         if(this.batchSelectionList.length == 0) {
            this.$message.warning('请选中采购单');
            return false;
         } 
         this.batchFailureVisible = true;
       },
       asyncBatchFail() {
          this.$refs.batchFormRef.validate(valid => {
             if(valid) {
               const ids = [];
               this.batchSelectionList.forEach(item=> {
                  ids.push(item.id)
               })
               let data = {
                  ids: ids,
                  notPassReason: this.batchForm.reason,
                  status: 226
               }
               batchUpdate(data)
                .then(res=> {
                   if(res.data.code == 200) {
                     this.$message.success('操作成功');
                     this.batchFailureVisible = false;
                     this.SearchAudit();
                     this.batchForm.reason = "";
                   }else if(res.data.code == 500) {
                      this.$message.error(res.data.message)
                   }
                })
             }else {
                return false;
             }
          })
       },
       handleSelectionChangeAudit(val) {
         this.batchSelectionList = val;
       },
       handleSizeChange3(newSize) {
          this.pageDataAudit.pageSize = newSize;
          this.SearchAudit();  // 待审核
       },
       handleCurrentChange3(newCurrent) {
          this.pageDataAudit.current = newCurrent;
          this.SearchAudit();  // 待审核
       },   
       handleClickCard(tab) {
          if(tab.name == "22") {
             getPurchasePayBill(this.editPurchaseId)
               .then(res=> {
                 if(res.data.code == 200) {
                   this.PurchasePayList = res.data.data.data;
                 }
             })
          }else if(tab.name == "23") {
            getPurchaseCollectBill(this.editPurchaseId)
              .then(res=> {
                 if(res.data.code == 200) {
                   this.PurchaseCollectList = res.data.data.data;
                 }
              })
           }
       },
       handleSizeChange2(newSize) {
         this.pageDataSubmit.pageSize = newSize;
         this.SearchSubmit();
       },
       handleCurrentChange2(newCurrent) {
          this.pageDataSubmit.current = newCurrent;
          this.SearchSubmit();
       },
       tableArrival4() {
         this.pageDataArrival.current = 1;
         this.pageDataArrival.pageSize = 10;
         this.SearchArrival();
       },
       // 待到货查询
       SearchArrival() {
          let params = {
             current: this.pageDataArrival.current,
             pageSize: this.pageDataArrival.pageSize,
             storageId: this.pageDataArrival.storageId,
             supplierId: this.pageDataArrival.supplierId,
             warehousingStatus: this.pageDataArrival.putInStatus,
             paymentStatus: this.pageDataArrival.payStatus,
             keyword: this.pageDataArrival.keywords,
             status: 223
          }
          getListPage(params)
           .then(res=> {
              if(res.status == 200) {
                 this.purchaseOrderList4 = [];
                 if(res.data.values&&Array.isArray(res.data.values)&&res.data.values.length) {
                   this.purchaseOrderList4 = res.data.values;
                   this.totalArrival = res.data.pageInfo.total;
                 }
              }
           })
       },  
       // 申请付款
       ApplyPaymentBtn(row) {
          const ids = [];
          ids.push(row.id)
          batchPurchasePaymentInit(ids)
           .then(res=> {
               this.applyPaymentList = [];
               var supplierId = 0;
               if(res.data.code == 200) {
                 supplierId = res.data.data.data[0].supplierId;
                 this.applyPaymentList = res.data.data.data;
                 this.toApplyPaymentVisible = true;
               }else if(res.data.code == "500") {
                 this.$message.error(res.data.message);
                 this.SearchArrival();
                 this.toApplyPaymentVisible = false;
               }  
               this.getFinanceAccount();
               this.getSupplierAccountList(supplierId); 
           })
           this.replacementAddInitTwoReqList = [];
           this.addPaymentOrder();  //  默认显示一条数据
           
       },
       // 获取付款账户列表
       getFinanceAccount() {
         getFinanceAccountType()
         .then(res=> {
            if(res.data.code == 200) {
               this.FinanceAccountList = res.data.data.data;
            }
         })  
       }, 
       // 根据供货商获取收款账户
       getSupplierAccountList(supplierId) {
         getSupplierAccount(supplierId)
         .then(res=> {
            this.CollectionAccounts = [];
            if(res.data.code == 200) {
             this.CollectionAccounts = res.data.data.data;
             console.log(this.CollectionAccounts);
            } 
         })
       },
       // 删除操作  
       PaymentOrderDele(index) {
         if(index == 0) {
            this.$message.warning('至少保留一条付款单');
            return false;
         }
         this.applyPaymentList.splice(index, 1);
       },
       addPayOrderDele(index) {
          if(index == 0) {
             this.$message.warning('至少保留一条付款单');
             return false;
          }
          this.replacementAddInitTwoReqList.splice(index, 1)
       },
       //  提交审核
       asyncSubmitAudit() {
          for(let i=0; i<this.replacementAddInitTwoReqList.length; i++) {
             if(!this.replacementAddInitTwoReqList[i].payableAmount) {
               this.$message.warning('请输入申请付款金额');
               return false;
             }
          }
          for(let j=0; j<this.replacementAddInitTwoReqList.length; j++) {
             if(!this.replacementAddInitTwoReqList[j].estimatePayDate) {
               this.$message.warning('请选择预计付款时间');
               return false;
             }
          }
          // 批量申请付款 
          let arrList = [];
          this.replacementAddInitTwoReqList.forEach(item2=> {
             this.applyPaymentList.forEach(item=> {
                let obj = {
                   collectionAccount: item.supplierAccount.bankAccount,  // 收款账号
                   collectionAccountName: this.paymentAccountName,   // 收款账户名称
                   collectionAccountType: item.supplierAccount.accountType,     // 收款账户类型
                   estimatePayDate: item2.estimatePayDate,     // 预计付款时间
                   financeAccountId: item.financeAccountId  ,    // 	付款账户id
                   payableAmount: item2.payableAmount,     // 应付金额(RMB)
                   relatedBillNo: item.purchaseNo ,     // 	关联单号
                   tips:item2.tips,     // 备注
                   settlement:item.settlement,//结算方式
                }
                arrList.push(obj)
             })
          })
          batchCreatePayBill(arrList)
           .then(res=> {
              if(res.data.code == 200) {
                 this.$message.success(res.data.message);
                 this.toApplyPaymentVisible = false;
                 this.SearchArrival();
              }else if(res.data.code == 500) {
                 this.$message.error(res.data.message)
              }
           }) 
       },
       // 供应商信息设置
       SupplierInforSet() { 
          this.$router.push({
             path:'/supplier',
          });
       },
       RefundDelete(index) {
          if(index == 0) {
             this.$message.warning('至少保留一条收款单');
             return false;
          }
          this.CollectionBillingDataList.splice(index, 1)
       },
       // 添加付款单
       addPaymentOrder() {
         this.replacementAddInitTwoReqList.push({
           payableAmount: "",
           estimatePayDate: "",
           tips: "",
         });
       },
       // 结束入库 
       async EndPutInStorage(row) {
         const confirmResult = await this.$confirm("是否确认结束入库？采购单结束入库后, 不能进行入库操作。", "提示", {
           confirmButtonText: "确定",
           cancelButtonText: "取消",
           type: "warning"
         }).catch(err => err);
         if (confirmResult !== "confirm") {
           return;
         }
         const ids = [];
         ids.push(row.id)
         let data = {
            ids: ids,
            status: 224   // 已完成
         }
         batchUpdate(data)
         .then(res=> {
            if(res.data.code == 200) {
               this.$message.success(res.data.message);
               this.SearchArrival();
            }else if(res.data.code == 500) {
               this.$message.error(res.data.message)
            }
         })
       },
       // 作废事件
       asyncInvalid(row) {
         this.invalidId = row.id;
         this.invalidDialogVisible = true;
       },  
       //  确认作废 
       invalidBtn() {
          this.$refs.invalidFormRef.validate(valid => {
             if(valid) {
               const ids = [];
               ids.push(this.invalidId);
               let data = {
                  ids: ids,
                  notPassReason: this.invalidForm.reason,
                  status: 225
               }
               batchUpdate(data)
                .then(res=> {
                   console.log(res, 'kkkkkkkkkkkkkkk');
                   if(res.data.code == 200) {
                      this.$message.success('作废成功');
                      this.invalidDialogVisible = false;
                      this.SearchArrival();   // 刷新
                   }else if(res.data.code == 500){
                      this.$message.success(res.data.message)
                   }
                })
             }else {
                return false;
             }
          })
       },
       // 申请退款
       applyRefund(row) {
          const ids = [];
          ids.push(row.id)
          batchPurchaseCollectInit(ids)
          .then(res=> {
             var supplierId = 0;
             if(res.data.code == 200) {
               supplierId = res.data.data.data[0].supplierId;
               this.applyRefundDataList = res.data.data.data;
               this.toApplyRefundVisible = true;
             }else if(res.data.code == 500) {
               this.$message.error(res.data.message);
               this.SearchArrival();
               this.toApplyRefundVisible = false;
             }
             this.getFinanceAccount();
             if(supplierId != 0) {
               this.getSupplierAccountList(supplierId); 
             }
          })
          this.CollectionBillingDataList = [];
          this.addVoucher();  // 默认显示一条
       },
       addApplyRefundDoneDele(index) {
         if(index == 0) {
            this.$message.warning('至少保留一条退款单');
            return false;
         }
         this.CollectionBillingDataListDone.splice(index, 1)
       },
       // 添加收款单
       addVoucher() {
         this.CollectionBillingDataList.push({
            canApplyReturnAmount: "",    //  申请退款金额
            estimateCollectionDate: "",  //  预计收款时间
            tips: ""   // 备注
         });
       },
       // 确定申请退款
       asyncApplyRefund() {
          for(let t=0; t<this.CollectionBillingDataList.length; t++) {
             if(!this.CollectionBillingDataList[t].canApplyReturnAmount) {
                this.$message.warning('请输入申请退款金额');
                return false;
             }
          }
          for(let j=0; j<this.CollectionBillingDataList.length; j++) {
             if(!this.CollectionBillingDataList[j].estimateCollectionDate) {
               this.$message.warning('请选择预计收款时间');
               return false;
             }
          }
         // 批量申请退款
         let arrList = [];
         this.CollectionBillingDataList.forEach(n=> {
            this.applyRefundDataList.forEach(m=> {
               let object = {
                  estimateCollectionDate: n.estimateCollectionDate,  // 预计收款时间 
                  financeAccountId: m.financeAccountName,   
                  payAccount: m.supplierAccount.bankAccount,   // 付款账号  
                  payAccountName: m.supplierAccount.accountName,  
                  payAccountType: m.supplierAccount.accountType,  
                  receivableAmount: n.canApplyReturnAmount,  
                  relatedBillNo: m.purchaseNo, 
                  tips: n.tips 
               }
               arrList.push(object)
            })
         })
         batchCreateCollectBill(arrList)
         .then(res=> {
            if(res.data.code == 200) {
              this.$message.success('申请退款成功');
              this.SearchArrival();
              this.toApplyRefundVisible = false;
            }else if(res.data.code == 500) {
              this.$message.success(res.data.message)
            }
         })
       },  
       getPurchaseAddInit() {
         this.packageList = [];
         this.purchaseOrderData.forEach(item=> {
           var list = {
             commoditySku: item.commoditySku,
             purchaseUnitPrice: item.purchaseUnitPrice,
             actualPurchaseNumber: item.actualPurchaseNumber,
             storageId: item.storageId,
             // supplierId: item.supplierId,
             purchaseTotal: item.purchaseUnitPrice * item.actualPurchaseNumber,
             actualSupplierId: item.supplierId,
             productId: item.id
           };
           this.packageList.push(list);
         })
         let data = {
            purchaseProductInfoRes: this.packageList
         }
         PurchaseAddInit(data)
          .then(res=> {
            this.createPurchaseOrderList = [];
            if(res.data.code == 200) {
               this.createPurchaseOrderList = res.data.data;
               console.log(this.createPurchaseOrderList, '123123');
               this.$message.success(res.data.message);
               this.PurchaseOrderVisible = false;  // 新增采购单隐藏
               this.createPurchaseVisible = true;  // 生成采购单显示
            }else if(res.data.code == 500) {
               this.$message.error(res.data.message)
            }
          })
       },
       // 待到货详情
       showArrivalDetail(row) {
          this.purchaseArrivalDetailId  = row.id;
          showPurchaseDetail(row.id)
           .then(res=> {
              this.toArrivalDetailObj = {};
             if(res.data.code == 200) {
                this.toArrivalDetailObj = res.data.data;
                this.purchaseGoodsArrivalData = res.data.data.purchaseProductInfoResList;
                if(res.data.data.warehousingStatus == 245) {
                   this.toArrivalActive = 2;
                   this.toArrivalDescription = res.data.data.createByName + '--' + res.data.data.createTime;
                   this.toArrivalCheckDescription = res.data.data.examineByName + '--' + res.data.data.examineTime;
                }else if(res.data.data.warehousingStatus == 246) {
                   this.toArrivalActive = 3;
                   this.toArrivalDescription = res.data.data.createByName + '--' + res.data.data.createTime;
                   this.toArrivalCheckDescription = res.data.data.examineByName + '--' + res.data.data.examineTime;
                   this.toArrivalInStorageDescription = res.data.data.putInByName + '--' + res.data.data.putInTime;
                }
             }
           })
          this.toArrivalOrderVisible = true;
       },
       //打印合同
       printContract(id){
         contractPrint(id).then((res) => {
            this.loading = false;
             if (res.data.code == 200) {
                    const link = document.createElement('a')
                    document.body.appendChild(link)
                    link.href = res.data.data
                    link.target='_blank'
                    link.click()
                    document.body.removeChild(link)
               } else {
                  this.dialogLoading = false;
                     this.$message.warning(res.data.message);
                  }
            });
       },
       handleClickArrive(tab) {
          if(tab.name == "52") {
             getPurchasePayBill(this.purchaseArrivalDetailId)
             .then(res=> {
                if(res.data.code == 200) {
                   this.PaymentRecordArrivalList = res.data.data.data;
                }
             })
          }else if(tab.name == "53") {
             getPurchaseCollectBill(this.purchaseArrivalDetailId)
             .then(res=> {
                if(res.data.code == 200) {
                  this.RefundRecordArrivalList = res.data.data.data;
                }
             })
          }
       },
       // 批量申请付款
       getApplyPayment() {
         if(this.multipleSelectionArrival.length == 0) {
            this.$message.warning('请选择采购单');
            return false;
         }
         const ids = [];
         this.multipleSelectionArrival.forEach(item=> {
            ids.push(item.id);
         })
         batchPurchasePaymentInit(ids)
         .then(res=> {
            this.batchApplyPaymentList = [];
            if(res.data.code == 200) {
               this.batchApplyPaymentList = res.data.data.data;
            }
            this.getFinanceAccount();
            
         })
         this.toBatchApplyPaymentVisible = true;
       }, 
       getFocuData(row) {
          this.getSupplierAccountList(row.supplierId);
       },
       batchPaymentDelete(index) {
          if(index == 0) {
             this.$message.warning('至少保留一条付款单');
             return false;
          }
          this.batchApplyPaymentList.splice(index, 1);
       },  
       // 批量申请付款提交审核  
       asyncBatchSubmitAudit() {
          for(let k=0; k<this.batchApplyPaymentList.length; k++) {
             if(!this.batchApplyPaymentList[k].payableAmount) {
                this.$message.warning('请输入申请付款金额');
                return false;
             }
          }
          for(let x=0; x<this.batchApplyPaymentList.length; x++) {
             if(!this.batchApplyPaymentList[x].accountName) {
                this.$message.warning('请选择收款账户名称');
                return false;
             }
          }
          for(let g=0; g<this.batchApplyPaymentList.length; g++) {
             if(!this.batchApplyPaymentList[g].financeAccountName) {
                this.$messge.warning('请选择付款账户名称');
                return false;
             }
          }
          let arrList = [];
          this.batchApplyPaymentList.forEach(item=> {
             let obj = {
                tips: item.tips,
                settlement: item.settlement,
                relatedBillNo: item.purchaseNo,
                payableAmount: item.payableAmount,
                financeAccountId: item.financeAccountId,
                estimatePayDate: item.estimatePayDate,
                collectionAccountType: item.supplierAccount.accountType,  // id值
                collectionAccountName: item.accountName,
                collectionAccount: item.supplierAccount.bankAccount
             }
             arrList.push(obj);
          })
          batchCreatePayBill(arrList)
           .then(res=> {
              if(res.data.code == 200) {
                 this.$message.success('批量申请付款成功');
                 this.toBatchApplyPaymentVisible = false;
                 this.SearchArrival();
              }else if(res.data.code == 500) {
                 this.$message.error(res.data.message);
              }   
          })
       },
       // 批量结束入库
       async getEndPutInStorage() {
          if(this.multipleSelectionArrival.length == 0) {
            this.$message.warning('请选择采购单');
            return false;
          }
          const confirmResult = await this.$confirm("是否批量结束入库？采购单结束入库后, 不能进行入库操作。", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning"
          }).catch(err => err);
          if (confirmResult !== "confirm") {
            return;
          }
          const ids = [];
          this.multipleSelectionArrival.forEach(item=> {
             ids.push(item.id)
          })
          let data = {
             ids: ids,
             status: 224   // 已完成
          }
          batchUpdate(data)
          .then(res=> {
             if(res.data.code == 200) {
                this.$message.success('操作成功');
                this.SearchArrival();
             }else if(res.data.code == 500) {
                this.$message.error(res.data.message)
             }
          })
       }, 
       handleSelectionChangeArrival(val) {
         this.multipleSelectionArrival = val;
       },
       // 待到货详情确认
       PurchaseOrderSave() {},  
       // 下一步 
       asyncNext() {
         for(let i=0; i<this.purchaseOrderData.length; i++) {
            if(!this.purchaseOrderData[i].purchaseUnitPrice || this.purchaseOrderData[i].purchaseUnitPrice<0) {
               this.$message.warning('采购单价必须大于0');
               return false;
            }
         }
         for(let j=0; j<this.purchaseOrderData.length; j++) {
            if(!this.purchaseOrderData[j].actualPurchaseNumber || this.purchaseOrderData[j].actualPurchaseNumber<0) {
               this.$message.warning('采购数量必须大于0');
               return false;
            }
         }
         for(let t=0; t<this.purchaseOrderData.length; t++) {
            if(!this.purchaseOrderData[t].storageId) {
               this.$message.warning('请选择采购仓库');
               return false;
            }
         }
         for(let x=0; x<this.purchaseOrderData.length; x++) {
            if(!this.purchaseOrderData[x].supplierId) {
               this.$message.warning('请选择供应商');
               return false;
            }
         }
         this.getPurchaseAddInit();
       },
       // 确定生成采购单  
       createPurchaseBtn() {
          this.purchaseOrderDataList = [];
          this.createPurchaseOrderList.forEach(item=> {
             var Array = [];
             item.purchaseProductInfoRes.forEach(item2=> {
                let object = {
                   productId: item2.productId,
                   actualPurchaseNumber: item2.actualPurchaseNumber,
                   purchaseUnitPrice: item2.purchaseUnitPrice,
                   id: item2.id
                }
                Array.push(object)
             })
             let obj= {
                expectArrivalTime: item.expectArrivalTime,
                freight: item.freight,
                freightShareType: item.freightShareType,
                logisticsId: item.logisticsId,
                logisticsNo: item.logisticsNo,
                otherFee: item.otherFee,
                tips: item.tips,
                storageId: item.storageId,
                supplierId: item.supplierId,
                purchaseProductInfoDos: Array
             }
             this.purchaseOrderDataList.push(obj);
          })
          addPurchase(this.purchaseOrderDataList)
          .then(res=> {
             if(res.data.code == 200) {
               this.$message.success(res.data.message);
               this.createPurchaseVisible = false;
               this.SearchSubmit();
             }else if(res.data.code == 500) {
               this.$message.error(res.data.message);
             }
          })
       },
       // 新增商品查询 
       newGoodsSearch() {
         let idSelects = [];
         this.purchaseOrderData.forEach(item=> {
            idSelects.push(item.id);
         })
         let params = {
           current: this.pageInfoGoods.current,
           keyword: this.pageInfoGoods.keywords,
           pageSize: this.pageInfoGoods.pageSize,
           selectedIds: idSelects
         }
         getProductListPage(params) 
          .then(res=> {
             if(res.status == 200) {
                if(res.data.values&&Array.isArray(res.data.values)&&res.data.values.length) {
                  this.newGoodsLists = res.data.values;
                  console.log(this.newGoodsLists, 'lllllllllllllll');
                  this.totalNewGoods = res.data.pageInfo.total;
                }
             }
          })
       },
       // 选择商品
       chooseGoods() {
         this.newGoodsSearch();
         this.newGoodsVisible = true;
       },  
       // 删除事件
       handleDelete(index) {
         this.purchaseOrderData.splice(index, 1)
       },
       checkBoxDisable(row) {
         let currentIds = this.purchaseOrderData.map(v=> v.id);
         if(currentIds.includes(row.id)) {
            return false;
         }else {
            return true;
         }
       },
       checkBoxDisable_t(row) {
         if(row.isSelected == 1) {
            return false;
         }else {
            return true;
         }
       },
       handleSelectionNewGoods(vals) {
         this.currentSelectList = vals;
       },
       handleSizeChangeNewGoods(newSize) {
          this.pageInfoGoods.pageSize = newSize;
          this.newGoodsSearch();
       },
       handleSizeChangeAddGoods(newSize) {
         this.pageInfoAddGoods.pageSize = newSize;
         this.addGoodsSearch();
       },
       handleCurrentChangeNewGoods(newCurrent) {
         this.pageInfoGoods.current = newCurrent;
         this.newGoodsSearch();
       },
       handleCurrentChangeAddGoods(newCurrent) {
         this.pageInfoAddGoods.current = newCurrent;
         this.addGoodsSearch();
       },
       // 添加事件  
       addNewGoods() {
         this.purchaseOrderData = [];
         this.currentSelectList.forEach(item=> {
           var SelectForm = {
             productImagePath: item.productImagePath,
             commoditySku: item.commoditySku,
             id: item.id,
             productImagePath: item.productImagePath,
             purchaseUnitPrice: item.purchasePrice,
             storageId: "",
             supplierId: ""
           }
           this.purchaseOrderData.push(SelectForm);
         })
         this.newGoodsVisible = false;         
       },
       add_NewGoods() {
         this.multipleSelection_List.forEach(data=> {
           let SelectList = {
             productImagePath: data.productImagePath,   // 图片
             commoditySku: data.commoditySku,  // SKU
             productName: data.productName,   // 商品名称
             actualPurchaseNumber: "",  // 已采购
             // id: data.id,
             productId: data.id,
             toPutNumber: "",   // 未入库
             unitCost: "",  // 单位费用
             unitPutCost: "",  // 单位入库成本
             productPriceTotal: "",  // 贷款总计
             productCostTotal: "",   // 费用总计
             purchaseTotal: "",    // 采购总额
             purchasePlanNo: "",  //  关联计划编号
             isAddNew: 1,
           }
           this.goodsDataList.push(SelectList);
         })
         this.addGoodsVisible = false;
       },
       tableDone5() {
         this.pageDataDone.current = 1;
         this.pageDataDone.pageSize = 10;
         this.SearchDone();
       },
       // 已完成查询
       SearchDone() {
          let params = {
            current: this.pageDataDone.current,
            pageSize: this.pageDataDone.pageSize,
            keyword: this.pageDataDone.keywords,
            storageId: this.pageDataDone.storageId,
            supplierId: this.pageDataDone.supplierId,
            warehousingStatus: this.pageDataDone.putInStatus,  // 入库状态
            paymentStatus: this.pageDataDone.payStatus,  // 支付状态
            status: 224
          }
          getListPage(params)
          .then(res=> {
             if(res.status == 200) {
                this.purchaseOrderList5 = [];
                if(res.data.values&&Array.isArray(res.data.values)&&res.data.values.length) {
                   this.purchaseOrderList5 = res.data.values;
                   this.totalDone = res.data.pageInfo.total;
                }
             }
          })
       },
       // 申请付款
       applyPaymentDone(row) {
         const ids = [];
         ids.push(row.id)
         batchPurchasePaymentInit(ids)
         .then(res=> {
            var supplierId = 0;
            this.applyPaymentListDone = [];
            if(res.data.code == 200) {
               supplierId = res.data.data.data[0].supplierId;
               this.applyPaymentListDone = res.data.data.data;
               this.toApplyPaymentDoneVisible = true;
            }else if(res.data.code == 500){
               this.$message.error(res.data.message);
               this.SearchDone();
               this.toApplyPaymentDoneVisible = false;
            }
            this.getFinanceAccount();
            this.getSupplierAccountList(supplierId);
         })
         this.replacementAddInitTwoReqListDone = [];
         this.addPaymentOrderDone();  //  默认显示一条数据
       },  
       // 已完成添加付款单
       addPaymentOrderDone() {
          this.replacementAddInitTwoReqListDone.push({
            payableAmount: "",
            estimatePayDate: "",
            tips: "",
          })
       },
       //  已完成供应商信息设置 
       SupplierInforDoneSet() { 
          this.$router.push({
             path:'/supplier',   //跳转的路径
          });
       },
       addPayOrderDoneDele(index) {
          if(index == 0) {
             this.$message.warning('至少保留一条付款单');
             return false;
          }
           this.replacementAddInitTwoReqListDone.splice(index, 1)
       },
       //  已完成提交审核
       asyncSubmitAuditDone() {
          for(let i=0; i<this.replacementAddInitTwoReqListDone.length; i++) {
             if(!this.replacementAddInitTwoReqListDone[i].payableAmount) {
                this.$message.warning('请输入申请付款金额');
                return false;
             }
          }
          for(let k=0; k<this.replacementAddInitTwoReqListDone.length; k++) {
             if(!this.replacementAddInitTwoReqListDone[k].estimatePayDate) {
                this.$message.warning('请选择预计付款时间');
                return false;
             }
          }
         // 批量申请付款 
         let arrList = [];
         this.replacementAddInitTwoReqListDone.forEach(item=> {
            this.applyPaymentListDone.forEach(item2=> {
               let Obj = {
                  collectionAccount: item2.supplierAccount.bankAccount,  // 收款账号
                  collectionAccountName: item2.accountName,   // 收款账户名称
                  collectionAccountType: item2.supplierAccount.accountType,   // 收款账户类型
                  estimatePayDate: item.estimatePayDate,     // 预计付款时间
                  financeAccountId: item2.financeAccountId  ,    // 	付款账户id
                  payableAmount: item.payableAmount,     // 应付金额(RMB)
                  relatedBillNo: item2.purchaseNo ,     // 	关联单号
                  tips:item.tips,     // 备注
               }
               arrList.push(Obj)
            })
         })
         batchCreatePayBill(arrList)
          .then(res=> {
             if(res.data.code == 200) {
                this.$message.success(res.data.message);
                this.toApplyPaymentDoneVisible = false;
                this.SearchDone();
             }else if(res.data.code == 500){
                this.$message.error(res.data.message)
             }
          })
       },
       //  申请退款
       applyRefundDone(row) {
         const ids = [];
         ids.push(row.id);
         batchPurchaseCollectInit(ids)
         .then(res=> {
            var supplierId = 0;
            if(res.data.code == 200) {
               var supplierId = 0;
               if(res.data.code == 200) {
                  supplierId = res.data.data.data[0].supplierId;
                  this.applyRefundDataDoneList = res.data.data.data;
                  this.toApplyRefundDoneVisible = true;
               }else if (res.data.code == 500) {
                  this.$message.error(res.data.message);
                  this.toApplyRefundDoneVisible = false;
                  this.SearchDone();
               }
               this.getFinanceAccount(); 
               this.getSupplierAccountList(supplierId);
            }
         })
         this.CollectionBillingDataListDone = [];
         this.addVoucherDone();   // 默认显示一条
       },
       //  申请退款添加付款单
       addVoucherDone() {
          this.CollectionBillingDataListDone.push({
            canApplyReturnAmount: "",    //  申请退款金额
            estimateCollectionDate: "",  //  预计收款时间
            tips: ""   // 备注
          })
       },
       // 确定申请退款
       asyncApplyRefundDone() {
          for(let r=0; r<this.CollectionBillingDataListDone.length; r++) {
             if(!this.CollectionBillingDataListDone[r].canApplyReturnAmount) {
               this.$message.warning("请输入申请退款金额");
               return false;
             } 
          }
          // 批量申请退款
          let arrList = [];
          this.CollectionBillingDataListDone.forEach(k=> {
             this.applyRefundDataDoneList.forEach(h=> {
                let object = {
                  estimateCollectionDate: k.estimateCollectionDate,  // 预计收款时间 
                  financeAccountId: h.financeAccountId,   
                  payAccount: h.supplierAccount.bankAccount,   // 付款账号  
                  payAccountName: h.supplierAccount.accountName,  
                  payAccountType: h.supplierAccount.accountType,  
                  receivableAmount: k.canApplyReturnAmount,  
                  relatedBillNo: h.purchaseNo, 
                  tips: k.tips 
                }
                arrList.push(object)
             })
          })
          batchCreateCollectBill(arrList)
          .then(res=> {
             if(res.data.code == 200) {
                this.$message.success('申请退款成功');
                this.SearchDone();
                this.toApplyRefundDoneVisible = false;
             }else if(res.data.code == 500){
                this.$message.error(res.data.message)
             }
          })
       },
       // 已完成详情
       showDoneDetail(row) {
          this.purchaseDoneDetailId = row.id;
          showPurchaseDetail(row.id)
           .then(res=> {
              this.toDoneDetailObj = {};
              if(res.data.code == 200) {
                 this.toDoneDetailObj = res.data.data;
                 this.purchaseGoodsDoneData = res.data.data.purchaseProductInfoResList;
                 if(res.data.data.status == 224) {
                    this.toDoneActive = 4;
                    this.toCreateDescription = res.data.data.createByName + '--' + res.data.data.createTime;
                    this.toReviewDescription = res.data.data.examineByName + '--' + res.data.data.examineTime;
                    this.PutInStorageDescription = res.data.data.putInByName + '--' + res.data.data.putInTime;
                    this.toCompleteDescription = res.data.data.finishByName + '--' + res.data.data.finshTime;
                 }
              }
           })
           this.toDoneOrderVisible = true;
       },
       handleSizeChange4(newSize) {
         this.pageDataArrival.pageSize = newSize;
         this.SearchArrival();
       },
       handleCurrentChange4(newCurrent) {
         this.pageDataArrival.current = newCurrent;
         this.SearchArrival();
       },
       handleSizeChange5(newSize) {
          this.pageDataDone.pageSize = newSize;
          this.SearchDone();
       },
       handleCurrentChange5(newCurrent) {
          this.pageDataDone.current = newCurrent;
          this.SearchDone();
       },
       // 详情保存  
       PurchaseOrderDoneSave() {},
       // 切换事件  
       handleClickDone(tab) {
         if(tab.name == "72") {
            getPurchasePayBill(this.purchaseDoneDetailId)
            .then(res=> {
               if(res.data.code == 200) {
                  this.PaymentRecordDoneList = res.data.data.data;
               }
            })
         }else if(tab.name == "73") {
            getPurchaseCollectBill(this.purchaseDoneDetailId)
             .then(res=> {
                if(res.data.code == 200) {
                   this.RefundRecordDoneList = res.data.data.data;
                }
             })
         }
       },
       tableInvalid6() {
         this.pageDataInvalid.current = 1;
         this.pageDataInvalid.keywords = 10;
         this.SearchInvalid();
       },
       //  已作废查询
       SearchInvalid() {
          let params = {
             current: this.pageDataInvalid.current,
             keyword: this.pageDataInvalid.keywords,
             storageId: this.pageDataInvalid.storageId,
             supplierId: this.pageDataInvalid.supplierId,
             pageSize: this.pageDataInvalid.pageSize,
             status: 225
          }
          getListPage(params)
          .then(res=> {
             this.purchaseOrderList6 = [];
             if(res.status == 200) {
               this.purchaseOrderList6 = res.data.values;
               this.totalInvalid = res.data.pageInfo.total;
             }
          })
       },
       //  查看作废详情
       showInvalidDetail(row) {
          this.purchaseInvalidDetailId = row.id;
          showPurchaseDetail(row.id)
          .then(res=> {
             this.toInvalidDetailObj = {};
             if(res.data.code == 200) {
               this.toInvalidDetailObj = res.data.data;
               this.purchaseGoodsInvalidData = res.data.data.purchaseProductInfoResList;
               if(res.data.data.status == 225) {
                  this.toInvalidActive = 3;
                  this.toInvalidCreateDescription = res.data.data.createByName + '--' + res.data.data.createTime;
                  this.toInvalidReviewDescription = rres.data.data.examineByName + '--' + res.data.data.examineTime;
                  this.toInvalidDescription = res.data.data.cancelByName + '--' + res.data.data.cancelTime;
               }
             }
          })
          this.toInvalidOrderVisible = true;
       },
       handleClickInvalid(tab) {
          if(tab.name == "82") {
              getPurchasePayBill(this.purchaseInvalidDetailId)
              .then(res=> {
                 if(res.data.code == 200 ) {
                  this.PaymentRecordInvalidList = res.data.data.data;
                 }
              })
          }else if(tab.name == "83") {
             getPurchaseCollectBill(this.purchaseInvalidDetailId)
             .then(res=> {
                if(res.data.code == 200) {
                   this.RefundRecordInvalidList = res.data.data.data;
                }
             })
          }
       },
       handleSizeChange6(newSize) {
          this.pageDataInvalid.pageSize = newSize;
          this.SearchInvalid();
       },
       handleCurrentChange6(newCurrent) {
          this.pageDataInvalid.current = newCurrent;
          this.SearchInvalid();
       },
       tableRejected7() {
          this.pageDataRejected.current = 1;
          this.pageDataRejected.pageSize = 10;
          this.SearchRejected();
       },
       // 已驳回查询
       SearchRejected() {
         let params = {
            current: this.pageDataRejected.current,
            pageSize: this.pageDataRejected.pageSize,
            keyword: this.pageDataRejected.keywords,
            storageId: this.pageDataRejected.storageId,
            supplierId: this.pageDataRejected.supplierId,
            status: 226
         }
         getListPage(params)
          .then(res=> {
             this.purchaseOrderList7 = [];
             if(res.status == 200 ) {
                this.purchaseOrderList7 = res.data.values;
                this.totalRejected = res.data.pageInfo.total;
             }
          })
       },
       // 已驳回详情
       showRejectedDetail(row) {
         this.purchaseRejectedDetailId = row.id;
         showPurchaseDetail(row.id)
          .then(res=> {
             this.toRejectedDetailObj = {};
             if(res.data.code == 200) {
               this.toRejectedDetailObj = res.data.data;
               this.purchaseGoodsRejectedData = res.data.data.purchaseProductInfoResList;
               if(res.data.data.status == 226) {
                  this.toRejectedActive = 2;
                  this.creatRejectedDescription = res.data.data.createByName + '--' + res.data.data.createTime;
                  this.RejectedDescription = res.data.data.rejectByName + '--' + res.data.data.rejectTime;
               }
             }
          })
          this.toRejectedOrderVisible = true;
       },
       handleSizeChange7(newSize) {
          this.pageDataRejected.pageSize = newSize;
          this.SearchRejected();
       },
       handleCurrentChange7(newCurrent) {
         this.pageDataRejected.current = newCurrent;
         this.SearchRejected();
       },
       handleClickRejected(tab) {
         if(tab.name == "92") {
            getPurchasePayBill(this.purchaseRejectedDetailId)
            .then(res=> {
               if(res.data.code == 200) {
                 this.PaymentRecordRejectedList = res.data.data.data;
               }
            })
         }else if(tab.name == "93") {
           getPurchaseCollectBill(this.purchaseRejectedDetailId)
           .then(res=> {
              if(res.data.code == 200) {
                 this.RefundRecordRejectedList = res.data.data.data;
              }
           })
         }
       },
       // 采购单入库弹窗(初始化数据)
       putInStorage(row) {
         this.curPutInStorageId = row.id;
         purchaseWarehousingInit(row.id)
          .then(res=> {
            if(res.data.code == 200) {
              this.InventoryDetail = res.data.data;
              this.putInStorageList = res.data.data.purchaseWarehousingResList;
              this.putInStorageVisible = true;
            }else if(res.data.code == 500) {
              this.$message.error(res.data.message);
            }
         })
       },
       // 确认入库
       asyncPutInStorage() {
          for (let index = 0; index<this.putInStorageList.length; index++) {
            let sum = parseInt(this.putInStorageList[index].putInBadNumber) + parseInt(this.putInStorageList[index].putInGoodNumber);
            let reducNumber = this.putInStorageList[index].actualPurchaseNumber - (this.putInStorageList[index].defectiveNumber + this.putInStorageList[index].goodNumber);
            if(sum > reducNumber || sum == reducNumber) {
             this.$message.error('入库总量不能超过已采购数量');
             return false;
           }
          }
          for (let _index = 0; _index<this.putInStorageList.length; _index++) {
            if(!this.putInStorageList[_index].putInGoodNumber) {
              this.$message.error("良品量不能为空");
              return false;
            }
            if(!this.putInStorageList[_index].putInBadNumber) {
              this.$message.error("次品量不能为空");
              return false;
            }
          }
         // 有问题待解决....
         var arrayList2 = [];
         this.putInStorageList.forEach(eq=> {
           let obj = {
             id: eq.id,
             productId: eq.productId,
             putInBadNumber: parseInt(eq.putInBadNumber),
             putInGoodNumber: parseInt(eq.putInGoodNumber),
             storageId: this.InventoryDetail.storageId
           }
           arrayList2.push(obj)
         })
         purchaseWarehouse(arrayList2, this.curPutInStorageId)
         .then(res=> {
            console.log(res);
            if(res.data.code == 200) {
              this.putInStorageVisible = false;
              this.$message.success(res.data.message);
              this.SearchArrival();
            }else if(res.data.code == 500) {
              console.log(1111111111);
              this.$message.error(res.data.message);
            }
         })
       },
       handleSelectionAddGoods(val) {
         this.multipleSelection_List = val;
       },
       // 新增采购商品 
       add_purchas_goods() {
         this.addGoodsSearch();
         this.addGoodsVisible = true;
       },
       // 删除采购商品
       del_procureGoods(row, index) {
         if(row.isAddNew == 1) {
            this.goodsDataList.splice(index, 1)
         }else {
           deletePurchaseProduct(row.id)
            .then(res=> {
              if(res.data.code == 200) {
                this.goodsDataList.splice(index, 1)
                this.$message.success(res.data.message);
                this.getProcureGoods();  // 获取采购商品信息
                this.SearchSubmit();
              }else if(res.data.code == 500) {
                this.$message.error(res.data.message);
              }
           })
         }

       },
       addGoodsSearch() {
         let idCurrentList = [];
         this.goodsDataList.forEach(item=> {
           idCurrentList.push(item.productId);
         })
         let params = {
           current: this.pageInfoAddGoods.current,
           keyword: this.pageInfoAddGoods.keywords,
           pageSize: this.pageInfoAddGoods.pageSize,
           selectedIds: idCurrentList
         }
         getProductListPage(params) 
         .then(res=> {
            this.addGoodsLists = [];
            if(res.data.values&&Array.isArray(res.data.values)&&res.data.values.length) {
               this.addGoodsLists = res.data.values;
               this.totalAddGoods =res.data.pageInfo.total;
            }
         })
       },
       changeAccountName(id) {
         this.CollectionAccounts.forEach(data=> {
           if(data.id == id) {
             this.paymentAccountType = data.accountTypeName;
             this.paymentNumber = data.bankAccount;
             this.paymentAccountName = data.accountName;
           }
         })
       },
       toggleAccountName(val) {
         this.FinanceAccountList.forEach(item=> {
           if(item.id == val) {
              this.accountOfPayment = item.bankAccount
           }
         })
       },
       changePurchStore(row, index) {
         let num = 0;
         this.purchaseOrderData.forEach(item=> {
           if(!item.storageId){
             num++
           }
         })
         if(num == this.purchaseOrderData.length - 1){
           this.purchaseOrderData = this.purchaseOrderData.map(data=> {
             data.storageId = Number(row.storageId);
             return data;
           })
         }
       },
       onClick_select(row) {
         let sum = 0;
         this.purchaseOrderData.forEach(eq=> {
           if(!eq.supplierId) {
             sum++
           }
         })
         if(sum == this.purchaseOrderData.length - 1) {
           this.purchaseOrderData = this.purchaseOrderData.map(Event=> {
             Event.supplierId = row.supplierId;
             return Event;
           })
         }
       }
     }
   }
</script>

<style lang="scss" scoped>
.el_select {
  margin-top: 15px;
  margin-right: 15px;
}
.el-pagination {
  padding-top: 50px;
  text-align: center;
  margin-bottom: 15px;
}
.searchStyle {
  display: flex;
  justify-content: flex-start;
}

.lable_div {
  display: flex;
  justify-content: flex-start;
}
.dialog-footer {
  display: block;
}
.dialog-footer-footer {
   display: flex;
   justify-content: flex-end;
}
.outSide {
   display: flex;
   justify-content: flex-end;
   width: 100%;
}
.FirstOne {
   width: 60px;
   height: 50px;
}
.SecondOne {
   width: 200px;
   height: 50px;
   text-align: center;
   line-height: 50px;
   margin: 5px 0;
}
.ThirdOne {
   width: 260px;
   height: 50px;
   text-align: center;
   line-height: 50px;
}
.FourthOne {
   width: 260px;
   height: 50px;
   text-align: center;
   line-height: 50px;
}
.FifthOne {   
   width: 300px;
   height: 50px;
   text-align: center;
   line-height: 50px;
}
.SixthOne {
   width: 260px;
   height: 50px;
   text-align: center;
   line-height: 50px;
}
.SeventhOne {
   width: 300px;
   height: 50px;
   text-align: center; 
   line-height: 50px;
}
.EighthOne {
  width: 300px;
  height: 50px;
  text-align: center;
  line-height: 50px;
}
.NinthOne {
   width: 300px;
   height: 50px;
   text-align: center;
   line-height: 50px;
}
.TenthOne {
   flex: 1;
   height: 50px;
}
.expectArrivalTime {   // 预计到货时间
   width: 300px;
   height: 50px;
   text-align: center;
   line-height: 50px;
}
.productPriceTotal {  
   width: 300px;
   height: 50px;
   text-align: center;
   line-height: 50px;
}
.planPurchaseNumber {  // 采购数量
    width: 400px;
    height: 50px;
    text-align: center;
    line-height: 50px;
}
.purchaseUnitPrice {
    width: 400px;
    height: 50px;
    text-align: center;
    line-height: 50px;
}
.commoditySku {
   width: 400px;
   height: 50px;
   text-align: center;
   line-height: 50px;
}
.productImagePath {
   width: 200px;
   height: 50px;
   text-align: center;
   line-height: 50px;
   margin: 5px 0;
}
::v-deep .el-table__expanded-cell {
   padding: 0;
   padding-left: 60px;
}
.add_purchasGoods {
   float: right;
   position: relative;
   z-index: 999;
}
.suspend{
   p{
     span{
       display: inline-block;
       width: 190px;
       margin-right: 10px;
       font-size: 13px;
     }
   }
}
.pic{
   width:160px;
   height: 120px;
   font-size:8px;
   color: #909399;
   text-align: center;
   line-height: 160px;
   margin-right: 10px;
   span{
     display: block;
     background: #EBEEF5;
     width: 100%;
     height: 100%;
   }
}
.goodsInfo{
  display: flex;
  >div:nth-of-type(2){
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
  }
  p{
    font-size: 12px;
    color: #909399;
  }
}
::v-deep .outTable .cell .el-table__expand-icon{
    display: none;
}

//到货
.arrivalInfo{
   display: flex;
   align-items: center;
   font-size: 12px;
   >div{
      min-width:12% ;
      margin-right:16px;
      >span:nth-of-type(1){
         color:#909399
      }
   }
}
::v-deep .outTable .el-table__row--level-0{
    background:#EBEEF5;
}
::v-deep .el-input__prefix{
   line-height: 32px;
}
</style>
